#lift {
  font-family: "Montserrat", sans-serif;
}

#lift button { outline: none; border: none;}

#lift .care-btn {
  background-color: #f39571;
  color: #fff;
  display: inline-block;
  width: auto;
  font-family: "Montserrat", sans-serif;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 45px;
  border: 0;
  border-radius: 4px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  z-index: 5;
}

#lift .care-btn:hover {
  filter: brightness(0.85);
}

/*   HEADER SECTION    */
.lift {
  height: 21.875vw;
  min-height: 420px;
  background-color: #e9e4e2;
  padding-top: 65px;
  position: relative;
}

#lift h1 {
  margin-bottom: 8px;
}

#lift h1 svg {
  width: 200px;
  margin-top: 23px;
}

/* #lift .background-video {
  width: auto;
  left: 50%;
  z-index: 5;
  transform: translate(-50%);
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: 0;
}
#lift .background-video video {
  width: auto;
  height: 100%;
  position: relative;
  min-height: 420px;
  max-width: none;
  background-color: #000;
} */

.liftInner {
  position: relative;
  text-align: center;
  z-index: 10;
}

.liftInnerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -45px;
}

.collection-option-text {
  width: 100%;
  text-align: center;
  padding-top: 40px;
}

.lift nav {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.lift nav a {
  position: absolute;
  bottom: 0;
  min-width: 325px;
  line-height: 1;
  font-size: 14px;
  color: #fff;
  text-align: left;
  padding: 2px 10px 14px 85px;
}
.lift nav a figure {
  width: 60px;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

.lift nav a figure img {
  width: auto;
  height: 100%;
  max-width: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.lift nav a.link-noFlaws {
  left: calc(50% - 20px);
  transform: translate(-100%);
  background-color: #f39571;
}

.lift nav a.link-glowskin {
  left: calc(50% + 20px);
  background-color: #b58874;
}

.lift nav a h2 {
  font-size: 22px;
  white-space: nowrap;
  color: #fff;
  margin: 0;
  line-height: 40px;
  font-family: "PT Serif", serif;
}

.liftInnerContent .underH1 {
  color: #323e48;
  max-width: 450px;
  font-size: 14px;
}

.liftInnerContent .underH1:after {
  content: "";
  width: 45px;
  height: 1px;
  background-color: #323e48;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%);
}

@media screen and (max-width: 768px) {
  .lift {
    height: auto;
    min-height: 525px;
    max-height: none;
    padding: 0;
    position: relative;
  }

  .lift h1 {
    margin: 233px auto 0 !important;
  }

  #lift h1 svg {
    margin-top: -15px;
    width: 174px;
  }

  .liftInnerContent {
    margin-top: 0;
  }

  .lift nav {
    position: static;
    transform: translate(0);
    display: inline-block;
    width: 100%;
    font-size: 0;
    padding-bottom: 70px;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
  }

  .lift nav span {
    font-size: 14px;
    margin-bottom: 14px;
    text-align: center;
  }

  .lift nav a figure {
    width: 50px;
    height: 100%;
    overflow: hidden;
  }

  .lift nav a figure img {
    float: right;
    width: auto;
    height: 100%;
    max-width: none;
  }

  .lift nav a.link-noFlaws {
    left: 50%;
    background-color: #f39571;
    height: 60px;
  }

  .lift nav a.link-glowskin {
    left: 50%;
    background-color: #b58874;
  }

  .lift nav a h2 {
    font-size: 14px;
    margin-bottom: 4px;
    white-space: normal;
    line-height: 1;
    max-width: 90%;
  }

  .lift nav a {
    left: 50%;
    vertical-align: top;
    width: 50%;
    min-width: auto;
    font-size: 8px;
    font-weight: 500;
    padding: 10px 0 10px 56px;
    margin: 0;
  }

  #lift figcaption {
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
    position: relative;
    z-index: 8;
    left: auto;
    right: auto;
    top: auto;
    line-height: 1;
    font-weight: 700;
    font-family: "PT Serif", serif;
  }

  #lift .brand-muse-noFlaws figcaption {
    color: #f39571;
  }

  #lift .brand-muse-glowskin figcaption {
    color: #b58874;
  }

  #lift .brand-muse-noFLaws {
    float: left;
    width: 50%;
    height: 200px;
    padding-top: 10px;
    margin-bottom: 20px;
    position: relative;
    right: auto;
    left: 0;
    overflow: hidden;
    top: 0;
    margin: 0;
    position: absolute;
  }

  #lift .brand-muse-glowskin {
    float: right;
    width: 50%;
    height: 200px;
    padding-top: 10px;
    margin-bottom: 20px;
    position: relative;
    right: auto;
    right: 0;
    overflow: hidden;
    top: 0;
    margin: 0;
    position: absolute;
  }

  .liftInnerContent .underH1 {
    font-size: 11px;
    max-width: 94%;
  }

  .liftInnerContent .underH1:after {
    bottom: -13px;
  }
}


/*  FLAWS BANNER */
#lift .no-flaws-banner { margin-bottom: 0; }

@media screen and (max-width: 768px) {
  #lift .no-flaws-banner { margin: 10px 0; }
  #lift .no-flaws-banner .care-btn {
    font-size: 15px!important;
    width: 86%!important;
    margin: 0 auto!important;
  }
  
}

/*   GLOW SKIN BANNER  */
#lift .glowskin-banner {
  background-color: #f0ece9;
  padding: 90px 0 80px;
  margin-bottom: 0;
  margin-top: 30px;
  color: #323e48;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 17px;
  text-align: center;
  position: relative;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

#lift .glowskin-banner .collection-poster {
  width: 240px;
  transform: rotate(6deg);
  position: absolute;
  top: 70px;
  left: calc(50% + 170px);
}

#lift .glowskin-banner .collection-eye-massager {
  width: 45px;
  transform: rotate(-96deg);
  position: absolute;
  top: -70px;
  left: calc(50% + 220px);
}

#lift .glowskin-banner .collection-brush {
  width: 125px;
  transform: rotate(-11deg);
  position: absolute;
  top: 100px;
  right: calc(50% + 250px);
}

#lift .glowskin-banner h2 {
  position: relative;
  color: #b58874;
  margin: 0 auto 15px;
  max-width: 450px;
  font-size: 45px;
  font-family: "PT Serif", serif;
  font-weight: 700;
  overflow-wrap: break-word;
  line-height: 1;
}

#lift .glowskin-banner p {
  margin-bottom: 25px;
}

#lift .glowskin-banner .care-btn {
  background-color: #b58874;
}

@media screen and (max-width: 768px) {
  #lift .glowskin-banner {
    padding: 65px 60px 40px;
    margin-bottom: 9px;
    margin-top: 0;
  }

  #lift .glowskin-banner .collection-poster {
    width: 170px;
    position: absolute;
    top: 45px;
    left: calc(50% + 50px);
  }

  #lift .glowskin-banner .collection-eye-massager {
    width: 30px;
    position: absolute;
    top: -52px;
    left: calc(50% + 60px);
  }

  #lift .glowskin-banner .collection-brush {
    width: 83px;
    position: absolute;
    top: 60px;
    right: calc(50% + 80px);
  }

  #lift .glowskin-banner h2 {
    max-width: 200px;
    margin-bottom: 10px;
    line-height: 1;
    font-size: 30px;
  }

  #lift .glowskin-banner figure img {
    z-index: 1;
  }

  #lift .glowskin-banner h2,
  #lift .glowskin-banner p,
  #lift .glowskin-banner .care-btn {
    z-index: 3;
    position: relative;
  }

  #lift .glowskin-banner p {
    font-size: 12px;
    margin-bottom: 14px;
  }

  #lift .glowskin-banner .care-btn {
    font-size: 15px!important;
    width: 86%!important;
    margin: 0 auto!important;
  }
}

@media screen and (max-width: 400px) {
  #lift .glowskin-banner {
    max-height: 260px;
  }
}