#glow .lcm-header {
  background-color: #b58874;
  color: #fff;
  padding-bottom: 8px;
  padding-top: 15px;
  text-align: center;
}

#glow .buyOnlineButton {
  background-color: #b58874;
  width: auto;
  line-height: 40px;
  height: 40px;
  padding: 0 45px;
}

#glow h2,
#glow h3 {
  font-family: "PT Serif", serif;
}

#glow article {
  position: relative;
}

#glow .lcm-header h2 {
  font-size: 45px;
  margin-top: 0;
  margin-bottom: 15px;
}

#glow .lcm-header p {
  font-weight: 100;
}

#glow .lcm-upper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0;
}

#glow article h3 {
  color: #b58874;
  font-size: 35px;
  grid-column: 2;
  text-align: center;
}

#glow .lcm-main {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

#glow .button-container {
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0px 50px;
}

#glow .lcm-image-container {
  text-align: right;
  position: relative;
  right: 10vw;
}
#glow .lcm-image-container img {
  width: 110px;
}

#glow .main-image-container {
  width: 100%;
  cursor: pointer;
  opacity: 0.8;
  max-width: 700px;
  margin: 0 auto;
}

#glow .main-image-container:hover {
  opacity: 1;
}

#glow .main-image-container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 40%;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 18px 0 18px 26px;
  z-index: 5;
  transform: translate(-35%, -50%);
}

#glow .main-image-container::after {
  background-color: #b58874;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 3;
  content: "";
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

#glow .main-image-container:hover::after {
  background-color: #b58874;
}

#glow .video-thumbnail {
  width: 100%;
}
.lcm-video-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0;
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
}

.lcm-video {
  border: 0;
  border-radius: 5px;
}

#glow .divider {
  width: 100%;
  height: 16px;
  background-color: #b58874;
}

#glow .liftComfort-image-container a {
  color: black;
}

@media screen and (max-width: 600px) {
  #glow .lcm-header h2 {
    font-size: 32px;
    line-height: 1;
  }
  #glow .lcm-header p {
    font-size: 14px;
  }
  #glow .lcm-main {
    grid-template-columns: 1fr !important;
    padding: 0 4%;
    display: block;
  }
  #glow .lcm-image-container img {
    width: 62px;
  }
  #glow article h3 {
    font-size: 30px;
    margin: 18px 0;
  }
  #glow .buyOnlineButton {
    width: 90%;
    font-size: 12px;
    margin: 12px auto;
  }

  #glow .main-image-container::before {
    top: 38%!important;
    border-width: 11px 0 11px 19px;
  }
  #glow .main-image-container::after {
    top: 38%!important;
    width: 50px;
    height: 50px;
  }

  #glow .button-container {
    margin: 10px 0 30px;
    width: 100%;
  }
  #glow .divider {
    height: 12px;
  }
  /* .lcm-video-container {
    top: -75px;
  } */
  #glow .liftComfort-image-container {
    height: 470px;
    /* width: calc(50% - 5px); */
    position: relative;
    overflow: hidden;
  }
  #glow .liftComfort-image-container img {
    height: 400px;
    max-width: none;
    position: absolute;
    left: 0%;
    overflow: hidden;
  }
  #glow .liftComfort-image-container figcaption {
    position: absolute;
    bottom: 27px;
    left: 10%;
  }
  #glow .caption-2 {
    bottom: 13px !important;
  }
  #glow .liftComfort-image-1 {
    width: 160% !important;
    left: -21% !important;
  }
  #glow .liftComfort-image-2 {
    width: 153% !important;
    left: -27% !important;
  }
}

@media screen and (max-width: 1200px) {
  #glow .lcm-main {
    grid-template-columns: 1fr 6fr 1fr;
    padding: 0 4%;
  }
  #glow .main-image-container::before {
    /* top: 53%; */
    border-width: 11px 0 11px 19px;
  }
  #glow .main-image-container::after {
    /* top: 53%; */
  }
  #glow .lcm-image-container {right: 3vw;  } 
 
}

@media screen and (max-width: 1000px) {
  #glow .lcm-image-container {right: 1vw;  } 
}
