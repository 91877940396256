@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&family=PT+Serif:wght@400;700&display=swap);
.Headerpage {
  border-bottom: 1px solid #dbdcdc;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 50;
}

.ToggleNav {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 12px 23px 38px 23px;
  transition: 0.25s;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  float: left;
  font-size: 12px;
  color: #fff;
  background-color: #009dc0;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
}

.skin-care-devices ::before {
  display: block;
  position: absolute;
  font-size: 30px;
  right: 15px;
  top: 38%;
  margin: -15px 0 0 0;
  content: "\203A";
  font-family: ico;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  z-index: 10;
}

.ToggleNav .burger-icon:before,
.ToggleNav .burger-icon:after {
  transition: 0.25s;
}

.ToggleNav:hover .burger-icon:before {
  top: 9px;
}

.ToggleNav:hover .burger-icon:after {
  top: -9px;
}

.ToggleNav.active:hover .burger-icon {
  -webkit-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
}

.ToggleNav.active .burger-icon {
  background: transparent;
  transition: all 0.3s;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.active:not(.steps) .burger-icon:before,
.active:not(.steps) .burger-icon:after {
  top: 0 !important;
}

.ToggleNav:hover .burger-icon:before {
  top: 9px;
}

.ToggleNav.active .burger-icon:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ToggleNav:hover .burger-icon:after {
  top: -9px;
}

.ToggleNav.active .burger-icon:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.active .burger-icon:before,
.active .burger-icon:after {
  transition: 0.5s;
}

.Logo {
  max-width: 80%;
}

.HeaderCenter {
  text-align: center;
}

.HeaderRight {
  padding: 7px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 0 !important;
}

.repair-logo {
  width: 60px;
  padding-right: 10px;
  border-right: 1px solid #e4e4e4;
  margin-right: 10px;
}

.back-button {
  position: absolute;
  left: -60px;
  top: 10px;
  display: none;
}

@media screen and (min-width: 440px) {
  .Headerpage {
    min-height: 60px;
  }
  .ToggleNav {
    font-size: 12px;
  }
  .HeaderRight {
    padding: 14px 20px;
  }
  .Logo {
    max-width: 100%;
  }
}

body.no-scroll {
  overflow: hidden;
}

.main-nav {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  padding-top: 80px;
  left: 0;
  text-align: left;
  z-index: 40;
  transition: all 0.4s;
  -webkit-transform: translateX(-320px);
  transform: translateX(-320px);
}

.main-nav a,
.main-nav h2 {
  color: #fff;
  font-family: "PT sans";
}

.main-nav a {
  text-transform: uppercase;
}

.main-nav h2,
.main-nav .h2 {
  margin: 0;
  padding: 13px 0 11px;
  line-height: 1.3em;
  color: #fff;
  font-weight: normal;
  font-family: "PT", sans-serif;
  text-align: center;
  font-size: 24px;
}

.main-nav h2 strong,
.main-nav .h2 strong {
  font-family: "uni", sans-serif, sans-serif;
  text-transform: uppercase;
  display: block;
  font-size: 32px;
}

.main-nav.show-nav {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.main-nav .niv1 {
  position: relative;
  background: #00add0;
  width: 320px;
  height: 100%;
}

.main-nav li img {
  max-width: 43px;
  position: relative;
  margin: 0 8px 0 19px;
  float: left;
  z-index: 10;
}

.main-nav li.secondary-nav img {
  margin: 0 8px 0 0;
}

.main-nav .navigation a,
.main-nav .navigation span {
  outline: none;
  display: block;
  text-decoration: none;
  padding: 12px 20px 12px;
  text-transform: uppercase;
  color: #fff;
  height: 100%;
  position: relative;
  font-size: 15px;
  transition: all 0.3s;
}

.main-nav .navigation a:before,
.main-nav .navigation span:before {
  display: block;
  position: absolute;
  font-size: 30px;
  right: 15px;
  top: 38%;
  margin: -15px 0 0 0;
  content: "\203A";
  font-family: ico;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  z-index: 10;
}

.main-nav .navigation a:after,
.main-nav .navigation span:after {
  height: 1px;
  background: #0093bb;
  left: 20px;
  right: 20px;
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  transition: all 0.3s;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.main-nav li.secondary-nav a,
.main-nav li.secondary-nav span {
  position: relative;
}

.main-nav li.secondary-nav a:after,
.main-nav li.secondary-nav span:after {
  left: 0;
  right: 0;
  background: #00add0;
}

.main-nav .navigation li a:after,
.main-nav .navigation li span:after {
  height: 1px;
  background: #0093bb;
  left: 20px;
  right: 20px;
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  transition: all 0.3s;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.main-nav .navigation li.direct-link a:before,
.main-nav .navigation li.direct-link span:before {
  display: none;
}

.main-nav li {
  list-style-type: none;
  margin-top: -1px;
  clear: both;
}

.main-nav li.secondary-nav ~ li.secondary-nav {
  margin-top: 0;
}

.main-nav li.secondary-nav {
  background: #009dc0;
  margin: 20px 20px 0 20px;
}
.main-nav .navigation .niv3 {
  position: absolute;
  z-index: -1;
  top: 0;
  min-width: 320px;
  left: 320px;
  height: 100%;
  background: #52c6de;
  color: #6d6e70;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: all 0.4s;
  -webkit-transform: translateX(-320px);
  transform: translateX(-320px);
  visibility: hidden;
  /* border-left: solid white 1px; */
}

.main-nav .navigation .niv3.open {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  right: 320px;
  visibility: visible;
}

.main-nav .navigation .niv3 .col {
  float: left;
  width: 320px;
  padding: 20px;
}

main-nav .navigation .niv3 h2,
.main-nav .navigation .niv3 .h2 {
  border-top: 1px solid #64cdeb;
  border-bottom: 1px solid #64cdeb;
  padding: 7px 0 9px;
  margin: 0 0 17px;
}

.main-nav .navigation .niv3 .col li {
  height: 44px;
  display: table;
  width: 100%;
}

.main-nav .navigation .niv3 .col li a {
  display: table-cell;
  vertical-align: middle;
  padding: 3px 16px 0 9px;
  line-height: 1.2em;
  height: 100%;
}

.main-nav .navigation .niv3 li a {
  color: #fff;
  min-height: 49px;
  border-bottom: 1px solid #99deec;
}

.main-nav .navigation .niv3 li a:before {
  display: none;
}

.main-nav .navigation .niv3 li a:after {
  display: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  background-color: #dbdfe4;
}

/* kedwjhfewoi */

.main-nav .navigation .niv2 {
  position: absolute;
  z-index: -1;
  top: 0;
  min-width: 320px;
  left: 320px;
  height: 100%;
  background: #33bdd9;
  color: #6d6e70;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: all 0.4s;
  -webkit-transform: translateX(-320px);
  transform: translateX(-320px);
  visibility: hidden;
}

.main-nav .navigation .niv2.open {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  right: 320px;
  visibility: visible;
}

.main-nav .navigation .niv2 .col {
  float: left;
  width: 320px;
  padding: 20px;
}

.main-nav .navigation .niv2 h2,
.main-nav .navigation .niv2 .h2 {
  border-top: 1px solid #64cdeb;
  border-bottom: 1px solid #64cdeb;
  padding: 7px 0 9px;
  margin: 0 0 17px;
}

.main-nav .navigation .niv2 .col li {
  height: 44px;
  display: table;
  width: 100%;
}

.main-nav .navigation .niv2 .col li a {
  display: table-cell;
  vertical-align: middle;
  padding: 3px 16px 0 9px;
  line-height: 1.2em;
  height: 100%;
}

.main-nav .navigation .niv2 li a {
  color: #fff;
  min-height: 49px;
  border-bottom: 1px solid #99deec;
}

.main-nav .navigation .niv2 li a:before {
  display: none;
}

#skin-care:before {
  display: block;
  position: absolute;
  font-size: 30px;
  right: 15px;
  top: 38%;
  margin: -15px 0 0 0;
  content: "\203A";
  font-family: ico;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  z-index: 10;
}

.main-nav .navigation .niv2 li a:after {
  display: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  background-color: #dbdfe4;
}

.main-nav .navigation a.all,
.main-nav .navigation span.all {
  background: #009dc0;
}

.main-nav .navigation .niv2 li a:hover {
  background-color: #fff;
  color: #009dc0;
}

.main-nav .navigation a:hover,
.main-nav .navigation span:hover {
  background: #33bdd9;
  color: #fff;
}

.overlay {
  position: fixed;
  z-index: 39;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  transition: all 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  visibility: hidden;
}

.overlay.open {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  visibility: visible;
  cursor: pointer;
}

.burger-icon {
    position: absolute;
    bottom: 20px;
    width: 30px;
    height: 4px;
    transition: .5s;
    background: #fff;
    float: left;
    z-index: 1;
    margin: 0;
}

.burger-icon:before {
    top: 7px;
}

.burger-icon:after {
    top: -7px;
}

.burger-icon:before, .burger-icon:after {
    display: block;
    content: "";
    width: 30px;
    height: 4px;
    position: absolute;
    z-index: -1;
    transition: .5s .25s;
    background: #fff;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.search-field {
  font-size: 16px;
  width: 0;
  opacity: 0;
  transition: all 0.5s;
  background: #009dc0;
  color: #fff;
  border: 0;
}

.search-field::-webkit-input-placeholder {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "PT sans";
}

.search-field::placeholder {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "PT sans";
}

.search-field:focus {
  box-shadow: unset;
  outline-color: unset;
  outline: 1px solid #019cc0;
}

.search-field.expand {
  padding: 15px 35px 15px 15px;
  margin-right: 15px;
  width: 250px;
  opacity: 1;
}

.search-submit {
  background: none;
  border: 0;
  cursor: pointer;
}

.search {
  display: block;
}

.search-results-container {
  display: block;
  position: relative;
  width: 100%;
}

.search-results {
  margin-top: 0;
  background: #056f88;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.result {
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  border-bottom: 1px solid #0185a3;
  text-align: center;
}

.result:hover {
  background: #0185a3;
}

.result a {
  padding: 10px 0;
  display: block;
  color: #fff;
}

@media (max-width: 400px) {
  .search-field.expand {
    width: 175px;
  }
}
.ConsumerServices {
	clear: both;
	background: #1E252B;
	font-family: 'PT Sans', sans-serif;
	padding-bottom: 50px;
	padding-top: 30px;
}

.Services {
	display: flex;
	justify-content: space-between;
}

.ServiceTitle {
	font-size: 16px;
	font-weight: 100;
}

.Service {
	text-align: center;
	color: #fff;
	max-width: 175px;
	margin: 25px auto;
}

.ServiceDescription {
	color: #B1BDC5;
	font-size: 12px;
}

.ServiceHr {
	margin: 10px 50px;
  border-color: #868686;
}

@media (max-width: 768px) {
	.ConsumerServices{
		padding-bottom: 0;
	}

	.ConsumerServices .section-title{
		margin-bottom: 0;
	}

	.Services {
		flex-direction: column;		
	}

	.Services .Service{
		margin: 15px auto;
	}
}
.section-title {
  line-height: 1.5em;
  position: relative;
  font-weight: 100;
  color: gray;
  margin: 0 0 25px;
  font-size: 35px;
  display: block;
  width: auto;
  text-align: center;
  clear: both;
}

.section-title .top-title {
  font-size: 40px;
  padding: 5px 10px;
  position: relative;
}

.section-title .top-title:before {
  position: absolute;
  content: "";
  height: 1px;
  background: #bfbfbf;
  top: 50%;
  left: -20px;
  width: 25px;
}

.section-title .top-title:after {
  position: absolute;
  content: "";
  height: 1px;
  background: #bfbfbf;
  top: 50%;
  right: -20px;
  width: 25px;
}

.section-title .bottom-title {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
	width: 100%;
	font-family: 'PT sans';
}
.Footer {
	margin-top: 34px;
}
.Footer:after {
	content: '';
    position: relative;
    z-index: -1;
    display: block;
    width: 100%;
    height: 9px;
    background: #B1BDC5;
}
.FooterLogo {
	float: left;
	width: 216px;
    margin-top: -6px;
    padding: 0 16px;
    background-color: #fff;
}
.FooterLogo img {
	max-width: 95%;
    vertical-align: middle;
}
.FooterLinks {
	float: right;
}
.FooterLinks li{
	display: inline-block;

}
.FooterLinks li a{
	padding: 0 12px;
	color: #6d6e70;
    text-transform: uppercase;
    font-size: 12px;

}
.TopBanner {
	background-size: cover;
	background-position: center;
	color: #000;
	display: flex;
  justify-content: center;
	align-items: center;
	padding: 25px 0;
}

.TopBanner h1 {
	font-size: 45px;
	position: relative;
	font-family: 'PT sans', sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1.1em;
	text-align: center;
	margin-bottom: 15px;
	margin-top: 0;
}

.TopBannerContent {
	text-align: center;
}

.TopBannerDesc {
	font-family: 'PT italics', sans-serif;
  font-size: 26px;
  text-align: center;
  width: 250px;
	margin: 0 auto;
	margin-bottom: 25px;
}

@media (min-width: 768px) {
	.TopBanner {
		min-height: 450px;
	}
}
.Nav {
  position: relative;
  z-index: 20;
  background-color: rgba(0, 157, 192, 0.8);
  color: #fff;
}

.active {
  background: #0185a3;
}

.Nav nav li a.active:hover {
  color: #fff;
}

.main-nav .navigation .niv2 h2,
.main-nav .navigation .niv2 .h2 {
  font-family: "PT italics", sans-serif;
  font-weight: 100;
  text-transform: uppercase;
}

.Nav ul {
  text-align: left;
  width: 100%;
  height: 100%;
  min-height: 70px;
  padding: 0;
  margin: 0;
  font-size: 0;
  background-color: #00a7ca;
  background-color: rgba(0, 167, 202, 0.8);
}

.Nav nav li {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.Nav nav li:hover {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.Nav nav li a {
  display: block;
  height: 100%;
  padding: 18px;
  line-height: 18px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Uni Sans";
  min-width: 130px;
  text-align: center;
  font-weight: 100;
}

.Nav nav li a:hover {
  color: rgba(0, 157, 192, 0.8);
}

.Nav nav li a:active {
  background: #1ab5d5;
  border-right: 1px solid #4ac5dd;
  border-left: 1px solid #4ac5dd;
}

.Nav nav li:nth-child(5) {
  float: right;
  background: #039fbf;
  border-left: 1px solid #4ac5dd;
}

.Nav nav li:nth-child(5):hover {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.Nav.nav-subcat nav li:nth-child(5) {
  float: none;
  background: none;
  border-left: 0;
}

.Nav.nav-subcat nav li:nth-child(5):hover {
  background-color: none;
  background-color: none;
}

@media (max-width: 768px) {
  .Nav {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .main-nav .navigation .niv2.open {
    left: 20px;
    box-shadow: 0 0 40px #444;
    z-index: 20;
  }
  .main-nav .navigation .niv3.open {
    left: 15px;
    box-shadow: 0 0 40px #444;
    z-index: 20;
  }
}

.FeaturedProducts {
	padding-top: 35px;
	padding-bottom: 50px;
}

.Products {
	margin: 25px 0;
	display: block;
	overflow: hidden;
}

@media (max-width: 768px) {
	.products {
		flex-direction: column;
	}
}
.SlideItem {
	padding: 25px 0;
	width: 25%;
	float: left;
	border: 1px solid #e3e6e8;
	position: relative;
	text-align: center;
}

.SlideItem-LinenCare {
	min-height: 390px;
}

.SlideItem-HomeComfort {
	min-height: 536px;
}

.SlideItem:hover {
	border-color: #20a9ca;
}

.SlideItem_Img {
	height: auto;
	padding: 10px 0;
}

.SlideItem_Caption {
	width: 100%;
	padding: 0 25px 20px 25px;
}

.SlideItem_Title {
	font-size: 21px;
	margin: 0 0 4px;
	padding: 0 0 10px;
	color: #009DC0;
	line-height: 1em;
	text-transform: uppercase;
	position: relative;
	min-height: 52px;
	width: 80%;
  margin: 0 auto;
}

.SlideItem_Title:after {
	content: "";
	width: 25px;
	position: absolute;
	bottom: 0;
	display: block;
	left: 50%;
	margin-left: -12px;
	background: #A4B0B8;
	height: 1px;
}

.SliderItem_Description {
	font-size: 16px;
	color: #6d6e70;
	margin: 15px auto;
	min-height: 0;
	line-height: 1.2em;
	letter-spacing: -0.009em;
	width: 80%;
}

@media screen and ( max-width: 460px) {
	.SlideItem {
		width: 100%;
	}
	.SlideItem_Img img {
		max-height: 280px;
	}
}

@media screen and ( min-width: 460px) and ( max-width: 768px) {
	.SlideItem {
		width: 50%;
	}
	
}
.banner {
  background: #1F272F;
}

.banner-content {
  display: flex;
  justify-content: space-between;
}

.banner-desc {
  padding: 25px 0;
  margin-top: 15px;
}

.banner-title {
  font-family: "PT italics", sans-serif;
  color: #fff;
  font-size: 40px;
  font-weight: lighter;
  margin: 0 0 15px 0;
}

.banner-excerpt {
  color: #B0BCC4;
  margin: 0 0 5px;
  line-height: 1.2em;
  font-size: 16px;
  width: 500px;
}

.banner-cta {
  background-size: cover;
  width: 500px;
  height: 350px;
  text-align: right;
  padding-right: 50px;
}

.banner-featured-title {
  text-transform: uppercase;
  color: #fff;
  font-family: 'PT sans';
  margin: 0;
  font-size: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  width: 225px;
  margin-left: auto;
  margin-top: 65px;
}

.banner-featured-desc {
  color: #fff;
  font-family: "PT italics", sans-serif;
  font-size: 22px;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .banner-content {
    flex-direction: column;
  }
  .banner-cta, .banner-excerpt, .banner-title {
    width: 100%;
    text-align: center;
  }
  .banner-cta {
    background-position: center;
    height: 300px;
  }
  .banner-desc, .banner-cta {
    padding: 25px;
  }
  .banner-featured-title {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}
.products {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}
.Hero {
	clear: both;
	max-height: 500px;
	overflow: hidden;
}

.HeroInner {
	background: linear-gradient(
		rgba(0, 0, 0, 0.25),
		rgba(0, 0, 0, 0.25)
	), url(/static/media/femme.80129ac8.jpg) no-repeat;
	background-size: cover;
	height: 200px;
	position: relative;
	width: 100%;
	margin: 0 auto;
	pointer-events: none;
	-webkit-animation: videoout 2.5s linear forwards;
	        animation: videoout 2.5s linear forwards;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	background: black;
	z-index: -1;
}

@-webkit-keyframes videoout {
  from {
		background: linear-gradient(
			rgba(0, 0, 0, 0.25),
			rgba(0, 0, 0, 0.25)
		), url(/static/media/femme.80129ac8.jpg) no-repeat;
	}
  to {
		background: rgba(0, 0, 0, 0.25);
	}
}

@keyframes videoout {
  from {
		background: linear-gradient(
			rgba(0, 0, 0, 0.25),
			rgba(0, 0, 0, 0.25)
		), url(/static/media/femme.80129ac8.jpg) no-repeat;
	}
  to {
		background: rgba(0, 0, 0, 0.25);
	}
}

.video-wrapper iframe {
	border: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.8;
}

.Hero .h1 {
  position: absolute;
  top: 50%;
  z-index: 1;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px #44bdd8;
  text-align: center;
  line-height: 1em;
  font-weight: 300;
  color: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0 auto;
  width: 100%;
}

.Hero h2 {
	position: absolute;
	text-shadow: 1px 1px 3px #44bdd8;
  bottom: 25px;
	margin: auto;
	z-index: 1;
	display: none;
	font-weight: 300;
	font-family: 'Merriweather', serif;
	text-align: center;
	font-size: 30px;
	margin: 0 auto;
  width: 100%;
}

.videoWrapper {
	display: NONE;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 460px) {
	.Hero .h1 {
		font-size: 30px;
	}
}

@media screen and (min-width: 768px) {
	.HeroInner {
		height: 300px;
	}
}

@media screen and ( min-width: 992px) {
	.HeroInner {
		height: 500px;
	}
	.Hero .h1 {
		font-size: 60px;
	}
	.Hero h2 {
		display: block;
		color: #fff;
	}
}
.SubNav {
	margin-top: 0;
	position: relative;
	z-index: 20;
	background-color: rgba(0, 157, 192, 0.8);
	color: #fff;
}

.SubNav ul {
	display: block;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	font-size: 0;
	background-color: #00A7CA;
	background-color: rgba(0, 167, 202, 0.8);
}

.SubNav nav li {
	display: block;
	white-space: nowrap;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	height: 50px;
}

.SubNav nav li:hover {
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.9);
}

.SubNav nav li a {
	display: block;
	height: 100%;
	padding: 0 18px;
	line-height: 50px;
	color: #fff;
	text-transform: uppercase;
	font-size: 16px;
}

.SubNav nav li a:hover {
	color: rgba(0, 157, 192, 0.8);
}

@media screen and (min-width: 440px) {
	.Subnav {
		margin-top: -70px;
	}
}

@media screen and ( min-width: 768px) {
	.SubNav ul {
		text-align: left;
		min-height: 70px;
		display: flex;
		justify-content: space-between;
	}
	.SubNav nav li {
		border-bottom: none;
		height: 70px;
		margin: 0% 3%;
	}
	.SubNav nav li a {
		line-height: 70px;
	}
}

.AboutUsBanner {
	background: url(/static/media/about-us-bg.9edacdbf.jpg);
	text-align: center;
    padding: 44px 0;
    background-size: cover;
    background-position: center center;
    margin-bottom: 30px;
}
.AboutUsBanner h1 {
    line-height: 1.5em;
    font-size: 45px;
    font-size: 3.0rem;
    color: #6d6e70;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Uni Sans', sans-serif;
    font-weight: bold;
}
.AboutRowenta {
	padding: 35px 60px;
    background: #F6F7F7;
    margin-bottom: 50px;
}
.AboutRowenta h2 {
	line-height: 1.5em;
    text-align: center;
    font-family: 'PT italics';
    font-weight: 100;
    color: #A4B0B8;
    margin: 0 0 55px;
    font-size: 35px;
    font-size: 2.2rem;
    position: relative;
    display: block;
}
.AboutRowenta h2:after {
	width: 60px;
    height: 1px;
    background: #A4B0B8;
    content: "";
    position: absolute;
    bottom: -22px;
    display: block;
    left: 50%;
    margin-left: -30px;
}
.AboutRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
    font-size: 21px;
    font-size: 1.31rem;
    margin: 0 0 10px;
    font-weight: 100;
    line-height: 1.2em;
}
.AboutRowentaContent {
	margin-top: 40px;
}
.AboutRowentaContent h3 {
	font-family: 'PT italics';
	font-size: 26px;
	font-size: 1.6rem;
    color: #009DC0;
    font-weight: 100;
    padding: 20px 23px;
    margin: 0;
}
.AboutRowentaContent img {
	width: 500px;
}
.AboutRowentaContent a {
	color: #6d6e70;
	font-size: 18px;
}
.AboutRowentaInnovators,
.AboutRowentaSebgroup {
	width: 500px;
	margin-bottom: 35px;
    background: #EDEEF0;
    display: inline-block;
    padding-bottom: 40px;
}
.AboutRowentaInnovators {
	margin-right: 80px;
}
.AboutRowentaConentTxt {
	padding: 0 30px;
}
.AboutRowentaContentLead {
	font-size: 1.2rem;
    font-weight: 100;
    margin: 0 0 10px;
    line-height: 1.2em;
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
	.AboutRowenta {
		margin: 0 15px;
		padding: 15px;
	}
	.AboutRowenta h2 {
	    margin: 0 0 45px;
	}
	.AboutRowentaIntroLead {
	    font-size: 19px;
	    font-size: 1.2rem;
	}
	.AboutRowentaInnovators {
		margin: 0 auto 30px;
	}
	.AboutRowentaInnovators,
	.AboutRowentaSebgroup {
		width: 100%;
	}
	.AboutRowentaContent h3 {
		font-size: 1.3rem;
		padding: 20px 15px;
	}
	.AboutRowentaConentTxt {
	    padding: 0 15px;
	}
}


.TopBannerSimpleContent img{
    width: 100%;
}
.TopBannerSimpleContent p{
    padding-left: 20px;
    text-align: left;
}



.GuaranteeBlock{
    padding: 18px 20px;
    background: #EDEEF0;
    margin: 0 0 15px;
}

.GuaranteeBlock .item h2{
    font-weight: 100;
    margin: 0 0 12px;
    padding: 12px 0;
    border-bottom: 1px solid #DCDEE2;
    border-top: 1px solid #DCDEE2;
    color: #009DC0;    
    line-height: 24px;    
    font-size: 24px;
}

.GuaranteeBlock .item p{
    font-size: 14.5px;
}

.GuaranteeBlock .item ul{
    padding: 0;
    margin: 0 0 10px 25px;
}
.GuaranteeBlock .item ul li{
    margin-bottom: 5px;
    text-align: left;
}
.GuaranteeBlock a{
    text-decoration: underline;
    font-size: 14px;
}
.GuaranteeBlock .GuaranteeBottom, .GuaranteeBlock .GuaranteeBottom a{
    font-size: 12px;
}

@media only screen and (max-width: 600px) {

}


.Benefits {
	clear: both;
	padding-top: 40px;
}

.Benefit {
	width: 20%;
	display: inline-block;
	padding: 0 20px;
	line-height: 20px;
	vertical-align: text-top;
	text-align: center;
}

.BenefitCaption {
	margin-top: 20px;
	min-height: 200px;
}

.BenefitCaption ul {
	padding-left: 10px;
	list-style: black;
	text-align: left;
}

.Benefit p {
	max-width: 160px;
	line-height: 1.2em;
	color: #6d6e70;
	margin: 0 auto;
	font-size: 14px;
}

.Benefit p:first-child {
	text-transform: uppercase;
	font-weight: 100;
	font-size: 15px;
	color: #00ACCF;
	line-height: 1.2em;
	margin-bottom: 15px;
	min-height: 54px;
}

.Benefit-img-wrapper {
	min-height: 170px;
}

.Benefit-img-wrapper img {
	width: 140px;
}

.DetailsNav {
	margin-bottom: 30px;
	margin-top: 50px;
}

.DetailsNav li {
	display: inline-block;
	vertical-align: middle;
	border-bottom: 3px solid #dbdcdc;
}

.DetailsNav li a {
	color: #000;
	display: block;
	height: 100%;
	padding: 18px;
	line-height: 18px;
	text-transform: uppercase;
	font-size: 16px;
}

.DetailsNav li a:hover {
	color: rgba(0, 157, 192, 0.8);
}

.DetailsNav li a:active {
	border-bottom: 3px solid rgba(0, 157, 192, 0.8);
}

.DetailsSection-1 .disclaimer {
	color: #a4b0b7;
}

.DetailsSection-1-inner {
	padding: 90px 60px 75px;
	margin: 0 auto;
	background-color: #000;
	color: #fff;
	text-align: center;
}

.DetailsSection-1 h2 {
	margin: 0 0 20px;
	font-weight: 700;
	font-size: 44px;
	line-height: 1em;
	text-transform: uppercase;
}

.DetailsSection-1 h3 {
	margin: 0 auto;
	width: 85%;
	padding: 13px;
	font-size: 28px;
	font-weight: 100;
	font-style: italic;
}

.DetailsSection-1 hr {
	width: 75px;
	margin: 0 auto;
}

.DetailsSection-1 p {
	line-height: 1.1em;
	font-size: 16px;
	padding: 0 150px;
}

.DetailsSection-1 {
	margin: 50px 0;
}

.DetailsSection-2 {
	margin-top: 30px;
	margin-bottom: 50px;
}

.FixedPartTable .picture img {
	max-height: 120px;
	max-width: 100px;
}

.FixedPartTable .name {
	font-weight: 100;
	margin: 0 auto;
	padding: 10px 5px;
	font-size: 18px;
	line-height: 1.1em;
	width: 65%;
}

.FixedPartTable .name a {
	color: #00ACCF;
	text-transform: uppercase;
	height: 120p;
}

.FixedPartTable .picture>a {
	min-height: 110px;
	display: block;
}

.FixedPartTable table tr {
	vertical-align: top;
}

.FixedPartTable table tr th {
	width: 20%;
	height: 150px;
}

.ScrollableTable td {
	padding: 10px 60px;
	border-top: 1px solid #EDEEF0;
}

.ScrollableTable td.info {
	text-align: left;
	padding: 10px 62px 10px 0;
}

.DetailsSection-3 {
	padding: 60px 0;
	text-align: center;
	background: #a4b0b7;
}

.Doc {
	background: #FFFFFF;
	height: 175px;
	border-radius: 25px;
	border: 2px solid #A4B0B8;
	display: block;
	padding: 15px;
	width: 12%;
	margin: 0 auto;
}

.Doc .DocIcon {
	margin: 0 auto;
}

.Doc .DocTitle {
	text-transform: uppercase;
	color: #000;
	line-height: 1em;
	margin: 10px auto;
}

.Doc .DocTitle p {
	margin: 10px auto;
}

.Doc hr {
	margin: 0 auto;
	color: #EDEEF0;
	width: 60%;
}

.DetailsSection-4 {
	padding-top: 30px;
}

.ProductBlock {
	margin-top: 10px;
	overflow: hidden;
	margin-bottom: 50px;
}

.ProductBlock-left {
	width: 50%;
	float: left;
}

.ProductBlock-left-main {
	border: solid 1px #dfe1e2;
	padding: 50px;
}

.ProductBlock-left-thumbnail {
	width: 25%;
	margin-top: 25px;
}

.ProductBlock-right {
	width: 50%;
	float: left;
	text-align: left;
	padding: 0 70px 25px 66px;
	margin: 0;
	color: #6d6e70;
	font-weight: 100;
	line-height: 24px;
	font-size: 16px;
}

.ProductBlock-right h2 {
	color: #1E252B;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1em;
	font-size: 45px;
	margin: 0 0 9px;
	padding: 0 0 12px;
	letter-spacing: -0.009em;
	position: relative;
}

.ProductBlock-right h3 {
	font-size: 25px;
	line-height: 22px;
	font-weight: 100;
}

.ProductBlock-right a {
	display: inline-block;
	margin-right: 10px;
	margin-top: 50px;
}

.ProductBlock-right p {
	color: #6d6e70;
}

.ProductBlock-right p.ref {
	border-top: solid 1px #dfe1e2;
	border-bottom: solid 1px #dfe1e2;
	width: 30%;
	padding: 10px 0;
}

.ProductBlock-left-main .product-image.hide {
	display: none;
}

.ProductBlock-left-thumbnail {
	width: 100%;
	box-sizing: border-box;
}

.ProductBlock-left-thumbnail img {
	display: inline-block;
	width: 25%;
	padding: 15px;
	box-sizing: border-box;
	border: solid 1px #dfe1e2;
}

.ProductBlock-left-thumbnail img:hover {
	cursor: pointer;
}


@media (max-width: 768px) {
	.Benefits {
		padding-top: 15px;
	}
	.ProductBlock-right, .ProductBlock-left {
		width: 100%;
	}
	.Product {
		width: 100% !important;
		padding: 0 15px;
	}
	.ProductBlock-right {
		padding: 0;
		margin-top: 15px;
	}
	.Product .btn.btn-primary {
		width: 100%;
		margin: 10px 0;
	}
	.Benefit {
		width: 100%;
		margin-bottom: 50px;
	}
	.Benefit-img-wrapper {
		min-height: 0;
	}
	.Benefit p:first-child {
		margin-bottom: 0;
	}
	.BenefitCaption {
		min-height: 0;
	}
	.DetailsSection-1 {
		margin-top: 0;
		margin-bottom: 30px;
	}
	.DetailsSection-1-inner {
		padding: 15px;
	}
	.DetailsSection-1-inner h2 {
		font-size: 35px;
	}
	.DetailsSection-1-inner h3 {
		width: 100%;
		padding: 13px 0;
		font-size: 26px;
	}
	.DetailsSection-1 p {
		padding: 0;
	}
}

@media (max-width: 768px) {
	.DetailsSection-3 {
		background: none;
		padding: 0;
	}
	.Doc {
		width: 100%;
	}
}

.ProductBlock {
	margin-bottom: 0;
}

.ProductBlock-left {
	width: 50%;
	float: left;
}

.ProductBlock-left-main {
	border: solid 1px #dfe1e2;
	padding: 50px;
}

.ProductBlock-left-thumbnail {
	width: 25%;
	margin-top: 25px;
}

.ProductBlock-right {
	width: 50%;
	float: left;
	text-align: left;
	padding: 0 70px 25px 66px;
	margin: 0;
	color: #6d6e70;
	font-size: 14.5px;
	line-height: 1.2em;
}

.ProductBlock-right h2 {
	color: #1E252B;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1em;
	font-size: 45px;
	margin: 10px 0 9px;
	padding: 0 0 12px;
	letter-spacing: -0.009em;
	position: relative;
}

.ProductBlock-right h3 {
	font-size: 25px;
	line-height: 22px;
	font-weight: 100;
}

.ProductBlock-right a {
	display: inline-block;
	margin-right: 10px;
	margin-top: 50px;
}

.ProductBlock-right p {
	color: #6d6e70;
}

.ProductBlock-right p.ref {
	border-top: solid 1px #dfe1e2;
	border-bottom: solid 1px #dfe1e2;
	width: 50%;
	padding: 10px 0;
}

.ProductBlock-left-main .product-image.hide {
	display: none;
}

.ProductBlock-left-thumbnail {
	width: 100%;
	box-sizing: border-box;
}

.ProductBlock-left-thumbnail img {
	display: inline-block;
	width: 25%;
	padding: 15px;
	box-sizing: border-box;
	border: solid 1px #dfe1e2;
}

.ProductBlock-left-thumbnail img:hover {
	cursor: pointer;
}

@media (max-width: 768px) {
	.ProductBlock-right, .ProductBlock-left {
		width: 100%;
	}
	.Product {
		width: 100% !important;
		padding: 0 15px;
	}
	.ProductBlock-right {
		padding: 0;
		margin-top: 15px;
	}
	.Product .btn.btn-primary {
		width: 100%;
		margin: 10px 0;
	}
	.Benefit {
		width: 100%;
		margin-bottom: 50px;
	}
	.Benefit-img-wrapper {
		min-height: 0;
	}
	.Benefit p:first-child {
		margin-bottom: 0;
	}
	.BenefitCaption {
		min-height: 0;
	}
	.DetailsSection-1 p {
		padding: 0;
	}
}

@media (max-width: 768px) {
	.DetailsSection-3 {
		background: none;
		padding: 0;
	}
	.Doc {
		width: 100%;
	}
	.FixedPartTable table tr th {
		display: none;
	}
	.FixedPartTable table tr th:first-child {
		display: block;
	}
}

.ProductBoxesThree {
	padding-top: 35px;
	padding-bottom: 80px;
}

.RowItem {
	width: 25%;
	display: inline-block;
	border: 1px solid #e3e6e8;
	min-height: 370px;
	position: relative;
}

.RowItem_Img {
	height: 200px;
	padding: 10px 0;
}

.RowItem_Caption {
	position: absolute;
	z-index: 2;
	left: 0;
	top: 220px;
	width: 100%;
	padding: 0 25px 20px 25px;
}

.RowItem_Title {
	font-size: 21px;
	margin: 0 0 4px;
	padding: 0 0 10px;
	color: #009DC0;
	line-height: 1em;
	text-transform: uppercase;
	position: relative;
	min-height: 52px;
}

.RowItem_Title:after {
	content: "";
	width: 25px;
	position: absolute;
	bottom: 0;
	display: block;
	left: 50%;
	margin-left: -12px;
	background: #A4B0B8;
	height: 1px;
}

.RowrItem_Description {
	font-size: 16px;
	color: #6d6e70;
	margin: 0 auto;
	min-height: 0;
	line-height: 1.2em;
	letter-spacing: -0.009em;
	width: 80%;
}

@media (max-width: 768px) {
	.RowItem {
		width: 100%;
	}
}
.whatis-row {
	margin: 10px 0;
}
.whatis-row-inner {
	display: flex;
}

.whatis-row-inner > div {
	flex: 1 1;
}

.whatis-row-info {
	padding: 22px 80px 22px 0px;
}

.whatis-row-info img {
	margin-left: 100px;
}

.whatis-row-info p {
	font-size: 1.1em;
	width: 75%;
}

.repair .section-title {
	color: #fff;
}

.onYourSide-row {
	padding: 40px 80px;
	background: #bcc5cc;
}

.onYourSide-row p {
	font-size: 1em;
	font-style: italic;
	color: #fff;
}

.onYourSide-row .title {
	font-family: 'PT sans-serif';
}

.onYourSide-row .top-content {
	display: flex;
}

.onYourSide-row .top-content > p {
	flex: 0.80 1;
	margin: 15px 30px;

}

.onYourSide-row .top-content > img {
	flex: 0.13 1;
}

.onYourSide-row .top-content > img:first-child {
	flex: 0.17 1;
}

.onYourSide-row hr {
	width: 25%;
	margin: 25px auto;
}

.onYourSide-row hr.hr {
	margin: 0 auto 40px;;
}

.onYourSide-row .bottom-content h2 {
	text-align: center;
	color: #fff;
}

.few-numbers {
	text-align: left;
	display: flex;
}

.few-numbers img {
	align-self: center;
	margin-right: 10px;
}

.few-numbers > .fex-numbers-right {
	margin-left: 30px;
}

.nearYou-row {
	display: flex;
	padding: 30px 0;
}

.nearYou-row > *{
	flex: 1 1;
}

.nearYou-row-info .section-title {
	color: #00b5d5;
}

.nearYou-row-info .section-title .top-title:before,
.nearYou-row-info .section-title .top-title:after {
	background: #00b5d5;
}

.nearYou-row-info .btn {
	margin-right: 15px;
}

.nearYou-row-location {
	background: url('https://www.rowenta.fr/medias/pinpoint.png?context=bWFzdGVyfHJvb3R8MTMxMDB8aW1hZ2UvcG5nfGg5Yi9oM2EvOTQwMzYxMTE4NTE4Mi5wbmd8MDI1ZjI1MmMxMTkyMjVkY2U4N2VlNjFjZWZlNjQ3MDgzYTdkZDQ2MDFiZjQyMDEyNDNkNjc4NzE2MDRiNDhhMg') no-repeat;
	background-size: contain;
	background-position: top center;
}

.nearYou-row-location p {
	position: relative;
	top: 38px;
	left: 0;
	color: white;
	font-size: 1.5em;
	text-align: center;
}

.nearYou-row-location p span {
	font-size: 4em;
}

.last-row {
	display: flex;
	margin-bottom: 25px;
}

.last-row h2 {
	text-align: center;
}

.last-row .img {
	margin: 0 auto;
	display: block;
	margin-bottom: 25px;
}

.oneClick,
._3d {
	background: #d6dce0;
	flex: 1 1;
	padding: 25px;
	border-radius: 5px;
}

.oneClick {
	margin-right: 10px;
}

._3d {
	margin-left: 10px;
}

.testimonial {
	display: flex;
}

.testimonial img {
	margin-bottom: 5px;
	margin-right: 15px;
	align-self: center;
}

.iframe-container-bot {
	width: 450px;
	margin: 0 auto;
	height: 250px;
}

.iframe-container-bot iframe {
	width: 100%;
	height: 100%;
}

@media (max-width: 768px) {
	.whatis-row-inner {
		flex-direction: column;
	}

	.whatis-row-video iframe {
		width: 280px;
		height: 160px;
		margin: 0 auto;
  	display: block;
	}

	.whatis-row-info {
		text-align: center;
		padding: 0;
	}

	.whatis-row-info img {
		margin: 0 auto;
	}

	.whatis-row-info p {
		margin: 0 auto;
	}

	.whatis-row-video {
		margin: 25px auto ;
	}

	.onYourSide-row {
		padding: 10px 25px;
		background: #bcc5cc;
	}

	.onYourSide-row .section-title .bottom-title {
		font-size: 42px;
	}

	.onYourSide-row .top-content {
		flex-direction: column;
	}

	.onYourSide-row .top-content img {
		flex: 1 1;
		width: 100px;
		margin: 10px auto;
	}

	.nearYou-row {
		flex-direction: column;
		padding: 30px 15px;
	}

	.nearYou-row-location {
		margin-bottom: 15px;
	}

	.nearYou-row-location p {
		color: black;
	}

	.last-row {
		flex-direction: column;
	}

	.last-row .lead {
		text-align: center;
	}

	.last-row iframe {
		width: 280px;
		height: 160px;
		display: block;
		margin: 0 auto;
	}

	.oneClick,
	._3d {
		margin: 10px 0;
		border-radius: 0;
	}

	.testimonial {
		flex-direction: column;
	}

	.iframe-container-bot {
		padding: 0;
		width: auto;
		height: auto;
	}
}

.repair-centre-section {
	background: #e6e9ea;
	padding: 15px;
	margin: 25px 0;
}

.repair-centre-section h3 {
	margin: 10px 0;
}

.repair-centre {
	padding: 25px;
  border-bottom: 2px solid #797e82;
}

.repair-centre strong {
	font-size: 20px;
}
.CookiesRowenta {
	margin-top: 30px;
	padding: 35px 60px;
	background: #F6F7F7;
	margin-bottom: 50px;
}

.CookiesRowenta a,
.CookiesRowenta a:hover,
.CookiesRowenta a:focus,
.CookiesRowenta a:active {
	color: #029dc0;
}

.CookiesRowentatitle {
	line-height: 1.5em;
	text-align: center;
	font-family: 'PT italics';
	font-weight: 100;
	color: #A4B0B8;
	margin: 0 0 55px;
	font-size: 35px;
	font-size: 2.2rem;
	position: relative;
	display: block;
}

.CookiesRowentatitle:after {
	width: 60px;
	height: 1px;
	background: #A4B0B8;
	content: "";
	position: absolute;
	bottom: -22px;
	display: block;
	left: 50%;
	margin-left: -30px;
}

.CookiesRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
	font-size: 21px;
	font-size: 1.31rem;
	margin: 0 0 10px;
	font-weight: 100;
	line-height: 1.2em;
	margin-top: 40px;
}

.CookiesRowenta ol li,
.CookiesRowenta ul li {
	margin-bottom: 10px;
	padding-left: 10px;
}

.CookiesRowenta ul {
	padding-left: 15px;
	list-style-type: disc;
}
.TermsRowenta {
	margin-top: 30px;
	padding: 35px 60px;
	background: #F6F7F7;
	margin-bottom: 50px;
}

.TermsRowenta a,
.TermsRowenta a:hover,
.TermsRowenta a:focus,
.TermsRowenta a:active {
	color: #029dc0;
}

.TermsRowentatitle {
	line-height: 1.5em;
	text-align: center;
	font-family: 'PT italics';
	font-weight: 100;
	color: #A4B0B8;
	margin: 0 0 55px;
	font-size: 35px;
	font-size: 2.2rem;
	position: relative;
	display: block;
}

.TermsRowentatitle:after {
	width: 60px;
	height: 1px;
	background: #A4B0B8;
	content: "";
	position: absolute;
	bottom: -22px;
	display: block;
	left: 50%;
	margin-left: -30px;
}

.TermsRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
	font-size: 21px;
	font-size: 1.31rem;
	margin: 0 0 10px;
	font-weight: 100;
	line-height: 1.2em;
}

.TermsRowenta ol li {
	margin-bottom: 20px;
	padding-left: 10px;
}
.PrivacyRowenta {
	margin-top: 30px;
	padding: 35px 60px;
	background: #F6F7F7;
	margin-bottom: 50px;
}

.PrivacyRowenta a,
.PrivacyRowenta a:hover,
.PrivacyRowenta a:focus,
.PrivacyRowenta a:active {
	color: #029dc0;
}

.PrivacyRowentatitle {
	line-height: 1.5em;
	text-align: center;
	font-family: 'PT italics';
	font-weight: 100;
	color: #A4B0B8;
	margin: 0 0 55px;
	font-size: 35px;
	font-size: 2.2rem;
	position: relative;
	display: block;
}

.PrivacyRowentatitle:after {
	width: 60px;
	height: 1px;
	background: #A4B0B8;
	content: "";
	position: absolute;
	bottom: -22px;
	display: block;
	left: 50%;
	margin-left: -30px;
}

.PrivacyRowentaIntroLead {
	font-family: 'PT italics';
	color: #6d6e70;
	font-size: 21px;
	font-size: 1.31rem;
	margin: 0 0 10px;
	font-weight: 100;
	line-height: 1.2em;
	margin-top: 40px;
}

.PrivacyRowenta ol li,
.PrivacyRowenta ul li {
	margin-bottom: 10px;
	padding-left: 10px;
}

.PrivacyRowenta ul {
	padding-left: 15px;
	list-style-type: disc;
}
.ContactUsBanner {
	background: url(/static/media/about-us-bg.9edacdbf.jpg);
	text-align: center;
    padding: 44px 0;
    background-size: cover;
    background-position: center center;
    margin-bottom: 30px;
}
.ContactUsBanner h1 {
    line-height: 1.5em;
    font-size: 45px;
    font-size: 3.0rem;
    color: #6d6e70;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Uni Sans', sans-serif;
    font-weight: bold;
}



.cnt-p {
    max-width: 800px;
    margin: 25px 0;
}

.cnt-p .btn {
    margin: 35px 0;
    text-transform: uppercase;
    padding: 8px 30px;
    display: block;
    width: 100%;
}

.cnt-p-details {
    display: flex;
    justify-content: space-between;
}

.cnt-p-address {
    text-align: right;
}

@media screen and (max-width: 580px) {
    .cnt-p-details {
        flex-direction: column;
    }

    .cnt-p-address {
        text-align: left;
    }
}
.Where-to-buy .main-content img{
	width: 80%;
	display: block;
	margin: 25px auto;
}

.Where-to-buy .main-content .WtbText {
	margin: 0 15px;
}

.Where-to-buy .main-content .WtbProds {
	margin: 15px 15px 0 15px;
}


.Where-to-buy .main-content hr{
	opacity: 0.2;
}

.Where-to-buy h2 {
	font-size: 1.2em;
}

.Where-to-buy .store-link {
	text-align: center;
}

.Where-to-buy .store-link a {
	display: inline-block;
	width: 18%;
	text-align: center;
	background-color: #f6f6f6;
	border: 1px solid #d8d7d7;
	border-radius: 16px 16px 0;
	box-sizing: border-box;
	text-decoration: none;
}

.Where-to-buy .store-link a img {
	vertical-align: bottom;
	margin: 5px 0;
}

.Where-to-buy .store-link a p {
	margin: 0;
	color: black;
}

.Where-to-buy .main-content .btn.shopnow {
	font-size: 20px;
	border-radius: 5px;
	padding: 12px 75px;
	margin: 0 0 40px;
	display: inline-block;
}

.Where-to-buy .retailers-row{
	display: flex;
	justify-content: space-between;
}
.Where-to-buy .retailers-row .retailer{
	flex: 0 1 30%;
	text-align: center;
}

.Where-to-buy .retailers-row .retailer img{
	max-width: 217px;
}

.retailer {
	border: 2px solid #e6e6e6;
  border-radius: 3px;
}
.retailer:hover {
	border-color: rgba(2, 157, 192, 0.3);
}
/* Desktop */

@media screen and (min-width: 620px) {
	.Where-to-buy .main-content {
		min-height: 450px;
	}
	.Where-to-buy .main-content .WtbText {
		margin: 0;
	}
	.Where-to-buy .main-content {
		margin-bottom: 50px;
		margin-top: -16px;
	}
	.Where-to-buy .main-content hr{
		margin-bottom: 45px;
		margin-top: 40px;
		opacity: 0.2;
	}
	.Where-to-buy h2 {
		margin-top: 50px;
		font-size: 1.5em;
	}
	.Where-to-buy .main-content .btn.shopnow {
		margin: 50px 0;
	}

}

@media screen and (max-width: 768px) {
	.retailers-row {
		flex-direction: column;
		padding: 10px;
	}

	.Where-to-buy .main-content img {
		width: 100%;
	}
}
#flaws .lcm-header {
  background-color: #f39571;
  color: #fff;
  padding-bottom: 8px;
  padding-top: 15px;
  text-align: center;
}

#flaws .buyOnlineButton {
  background-color: #f39571;
  width: auto;
  line-height: 40px;
  height: 40px;
  padding: 0 45px;
}

#flaws h2,
#flaws h3 {
  font-family: "PT Serif", serif;
}

#flaws article {
  position: relative;
}

#flaws .lcm-header h2 {
  font-size: 45px;
  margin-top: 0;
  margin-bottom: 15px;
}

#flaws .lcm-header p {
  font-weight: 100;
}

#flaws .lcm-upper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0;
}

#flaws article h3 {
  color: #f39571;
  font-size: 35px;
  grid-column: 2;
  text-align: center;
}

#flaws .lcm-main {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

#flaws .button-container {
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0px 50px;
}

#flaws .lcm-image-container {
  text-align: right;
  position: relative;
  right: 10vw;
}
#flaws .lcm-image-container img {
  width: 110px;
}

#flaws .main-image-container {
  width: 100%;
  cursor: pointer;
  opacity: 0.8;
  max-width: 700px;
  margin: 0 auto;
}

#flaws .main-image-container:hover {
  opacity: 1;
}

#flaws .main-image-container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 39%;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 18px 0 18px 26px;
  z-index: 5;
  -webkit-transform: translate(-35%, -50%);
          transform: translate(-35%, -50%);
}

#flaws .main-image-container::after {
  background-color: #f39571;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 3;
  content: "";
  position: absolute;
  left: 50%;
  top: 39%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#flaws .main-image-container:hover::after {
  background-color: #f39571;
}

#flaws .video-thumbnail {
  width: 100%;
}
.lcm-video-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0;
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
}

.lcm-video {
  border: 0;
  border-radius: 5px;
}

#flaws .divider {
  width: 100%;
  height: 16px;
  background-color: #f39571;
}

#flaws .liftComfort-image-container a {
  color: black;
}

@media screen and (max-width: 600px) {
  #flaws .lcm-header h2 {
    font-size: 32px;
    line-height: 1;
  }
  #flaws .lcm-header p {
    font-size: 14px;
  }
  #flaws .lcm-main {
    grid-template-columns: 1fr !important;
    padding: 0 4%;
    display: block;
  }
  #flaws .lcm-image-container img {
    width: 62px;
  }
  #flaws article h3 {
    font-size: 30px;
    margin: 18px 0;
  }
  #flaws .buyOnlineButton {
    width: 90%;
    font-size: 12px;
    margin: 12px auto;
  }

  #flaws .main-image-container::before {
    top: 38%!important;
    border-width: 11px 0 11px 19px;
  }
  #flaws .main-image-container::after {
    top: 38%!important;
    width: 50px;
    height: 50px;
  }

  #flaws .button-container {
    margin: 10px 0 30px;
    width: 100%;
  }
  #flaws .divider {
    height: 12px;
  }
  /* .lcm-video-container {
    top: -75px;
  } */
  #flaws .liftComfort-image-container {
    height: 470px;
    /* width: calc(50% - 5px); */
    position: relative;
    overflow: hidden;
  }
  #flaws .liftComfort-image-container img {
    height: 400px;
    max-width: none;
    position: absolute;
    left: 0%;
    overflow: hidden;
  }
  #flaws .liftComfort-image-container figcaption {
    position: absolute;
    bottom: 27px;
    left: 10%;
  }
  #flaws .caption-2 {
    bottom: 13px !important;
  }
  #flaws .liftComfort-image-1 {
    width: 160% !important;
    left: -21% !important;
  }
  #flaws .liftComfort-image-2 {
    width: 153% !important;
    left: -27% !important;
  }
}

@media screen and (max-width: 1200px) {
  #flaws .lcm-main {
    grid-template-columns: 1fr 6fr 1fr;
    padding: 0 4%;
  }
  #flaws .main-image-container::before {
    /* top: 53%; */
    border-width: 11px 0 11px 19px;
  }
  #flaws .main-image-container::after {
    /* top: 53%; */
  }
  #flaws .lcm-image-container {right: 3vw;  } 
 
}

@media screen and (max-width: 1000px) {
  #flaws .lcm-image-container {right: 1vw;  } 
}

.buyOnlineButton {
  background-color: #dbb68c;
  color: #fff;
  height: 35px;
  width: 180px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border: 0;
  border-radius: 4px;
  transition: 0.2s ease;
  transition-property: background-color, color;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  display: flex;
  justify-content: center;
  align-items: center;
}

.buyOnlineButton:hover {-webkit-filter: brightness(1.1);filter: brightness(1.1);}

@media screen and (max-width: 1200px) {
  .buyOnlineButton {
    height: 50px;
    width: 280px;
    font-size: 15px;
  }
}

.buttonContainer {
    display: flex;
    justify-content: center;
}
.buttonContainer .retailers {
    padding: 1em;
    text-align: center;
}
.buttonContainer .retailers:first-child {
    border-right: 1px solid #dbb68c;
}
.buttonContainer .retailers img {
    height: 60px;
}
.buttonContainer .buyOnlineButton {
    margin: 10px;
    white-space: nowrap;
}

@media screen and (max-width: 768px) {
    .buttonContainer .retailers {
        flex: 1 1;
    }
    .buttonContainer .buyOnlineButton {
        height: 35px;
        width: auto;
        padding: 0 1em;
        margin: 10px 0;
    }
    .flawsMobileBorder.buttonContainer {
        margin: 10px 10px 20px!important;
    }
    .flawsMobileBorder.buttonContainer .retailers {
        padding: 5px;
    }
}
#faqSection {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#faqSection h2 {
    margin-bottom: 15px;
    font-size: 45px;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    overflow-wrap: break-word;
    margin: 47px 0 56px;
}

#flaws #faqSection h2 {
    color: #f39571;
}

.faqSection-content  {
    width: 920px;
}

.faqSection-content .toggle {
    display: flex;
}

.faqSection-content .toggle div {
    font-size: 21px;
    font-weight: 700;
    font-family: 'PT Serif', serif;   
    cursor: pointer;
    padding: 0px 40px 0 0;
    margin-right: 1px;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faqSection-content .toggle a div {
    background-color: #f7f4f2;
    color: #f39571;
}

.faqSection-content .toggle a div:hover,
.faqSection-content .toggle a.activetab div {
    background-color: #f39571;
    color: #f7f4f2;
}

.faqSection-content .toggle div img {
    margin-right: 20px;
}

.facialTab,
.eyeTab {
    width: 100%;
    text-align: left;
    border: 3px solid #f39571;
    padding: 0 40px 10px;
    margin: 0;
}

#keyvalue {
    width: 100%;
}

.keyvalue_accordion_inner {
    border-bottom: 1px solid #f39571;
    cursor: pointer;
    padding: 15px 0;
}

.keyvalue_accordion_inner:last-of-type {
    border-bottom: none!important;
}

.keyvalue_accordion_item button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.keyvalue_accordion_header h4 {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #f39571;
    transition: font-weight .3s ease;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
}

.keyvalue_accordion_content {
    display: flex;
    color: #323e48;
    font-size: 15px;
}

.keyvalue_accordion_header h4 span,
.keyvalue_accordion_content_left h4 span{
    line-height: 1;
    font-size: 30px;
    font-weight: 700;
    padding-right: 5px;
}

.keyvalue_accordion_content_left h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #f39571;
    font-size: 18px;
}

.keyvalue_accordion_content_left p {
    padding-left: 22px;
}

.keyvalue_accordion_content_left ul {
    list-style: disc;
    padding-left: 38px;
}

.keyvalue_accordion_content_left p.footnote {
    font-size: 11px;
    font-weight: 700;
}

.keyvalue_accordion_content p {
    line-height: 1.5;
}

.keyvalue_accordion_content a {
    color: #f39571;
}

.keyvalue_accordion_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.keyvalue_accordion_content_right {
    position: relative;
}

.arrowButton {
    width: 22px;
    height: 22px;
}

.keyvalue_accordion_item .close {   
	background-color: #fff;
    color: #f39571;
	cursor: pointer;
	border-radius: 50%;
    border: 1px solid #f39571;
    min-width: 50px;
	width: 50px;
	height: 50px;
	box-sizing: content-box;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
}

.keyvalue_accordion_item.true .close{
    background-color: #f39571;
    margin-right: 4px;
    margin-top: 15px;
    width: 50px;
	height: 50px;
	box-sizing: content-box;
}

.keyvalue_accordion_item .close span svg{
	vertical-align: middle;
    stroke: #f39571;
}

.keyvalue_accordion_item.true .close svg{
    stroke: #fff;
}

.keyvalue_accordion_item .keyvalue_accordion_header .close {
	font-weight: bold;
}

.open_cross {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: 500ms;
	-webkit-transform-origin: center center;
	        transform-origin: center center;
}

.close_cross {
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: 500ms;
	-webkit-transform-origin: center center;
	        transform-origin: center center;
}


@media screen and (max-width: 1100px) {
    .faqSection-content {
        width: 700px;
    }
}

@media screen and (max-width: 768px) {
    .toggle>a {
        flex: 1 1;
    }

	.faqSection-content {
        width: 100%;
    }

    #faqSection h2 {
        line-height: 1;
        font-size: 30px;
        text-align: center;
        width: 80%;
        margin: 16px 0;
    }

    .faqSection-content .toggle div {
        padding: 0 20px 0 0;
        justify-content: flex-start!important;
    }

    .faqSection-content .toggle div img{
        height: 48px;
    }

    .faqSection-content .toggle a div {
        font-size: 15px;
    }

    .facialTab,
    .eyeTab {
        border: 7px solid #f39571;
        border-bottom: 22px solid #f39571;
        padding: 0;
    }

    .keyvalue_accordion_header h4 {
        font-size: 14px;
    }

    .keyvalue_accordion_header h4 span, 
    .keyvalue_accordion_content_left h4 span {
        font-size: 19px;
    }

    .keyvalue_accordion_content_left h4 {
        font-size: 14px;
    }

    .keyvalue_accordion_content_left p {
        padding: 0;
        font-size: 12px;
    }

    .keyvalue_accordion_item .close {
        width: 38px;
        height: 38px;
        min-width: 38px;
        margin-left: 10px;
    }

    .keyvalue_accordion_item.true .close {
        width: 38px;
        height: 38px;
        min-width: 38px;
        margin-left: 10px;
    }

    .keyvalue_accordion_inner {
        border-bottom: 7px solid #f39571;
        padding: 3px 28px;
    }    

    .arrowButton {
        width: 16px;
        height: 16px;
    }
    
	
	
	
}

#antiblemish .slider-control-centerleft {
  left: -70px !important;
  top: 54% !important;
  -webkit-transform: translateY(-54%) !important;
          transform: translateY(-54%) !important;
}

#antiblemish .slider-control-centerright {
  right: -70px !important;
  top: 54% !important;
  -webkit-transform: translateY(-54%) !important;
          transform: translateY(-54%) !important;
}

#antiblemish .slider-control-centerleft button,
#antiblemish .slider-control-centerright button {
  border: none !important;
  background: transparent !important;
}

.leftArrow,
.rightArrow {
  width: 30px;
  cursor: pointer;
}

.sliderArrows:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.85);
}

.slider-list {
  cursor: initial !important;
}

#antiblemish .slider-slide {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  height: 100% !important;
}

.blemish-slide1-inner {
  display: flex;
  align-items: center;
}

#antiblemish .slider-slide {
  padding: 5px 42px;
}

#antiblemish {
  text-align: center;
}

#antiblemish.antiblemish-page .carouselWrapper {
  position: relative;
  top: -75px;
  width: 920px;
  margin: 0 auto -25px;
}

.antiblemish_header {
  background-color: #f39571;
  color: #fff;
  padding: 22px calc(50% - 460px) 20px;
  padding-bottom: 102px;
}

.antiblemish_header p {
  font-size: 14px;
}

.antiblemish_h2 {
  font-size: 45px;
  margin: 0 0 15px;
  font-family: "PT Serif", serif;
}

.blemish-slide-header {
  height: 120px;
  padding-top: 16px;
}

.blemish-slide-header p {
  font-size: 15px;
  text-transform: uppercase;
  color: #f39571;
  margin-top: 9px;
}

.blemish-slide h3 {
  font-size: 35px;
  margin: 5px 0 0;
  color: #f39571;
  font-family: "PT Serif", serif;
  font-weight: 700;
}

.blemish-slide {
  width: 920px;
  max-width: 920px;
  background-color: #fff;
  color: #323e48;
}

.blemish-slide1-list {
  text-align: left;
  padding-left: 57px;
  padding-top: 50px;
}

.blemish-slide1-list ul {
  margin-bottom: 65px;
}

.blemish-slide1-list li {
  list-style: none;
  margin-bottom: 16px;
  position: relative;
  font-size: 15px;
  max-height: 26px;
}

.blemish-slide1 li.brush-about-x7 svg {
  width: 26px;
  height: 27px;
  margin-right: 26px;
}

.blemish-slide1 li.brush-about-skin svg {
  width: 49px;
  height: 35px;
  margin-left: -10px;
  margin-right: 13px;
}

.blemish-slide1 li.brush-about-complexion svg {
  width: 50px;
  height: 47px;
  margin-left: -5px;
  margin-right: 6px;
}

.blemish-slide1 li.brush-about-waterproof svg {
  width: 38px;
  height: 42px;
  margin-left: 1px;
  margin-right: 14px;
}

#antiblemish .blemish-slide1 ul li svg {
  fill: #f39571;
  vertical-align: middle;
}

#antiblemish .blemish-slide1 ul li strong {
  color: #f39571;
  font-size: 15px;
}

#antiblemish .blemish-slide1 .footnote p {
  width: 285px;
  font-size: 11px;
  font-weight: 500;
  color: #323e48;
  font-family: "Montserrat", sans-serif;
}

.blemish-slide .care-btn {
  margin-top: 37px;
}

.circles {
  display: flex;
}

.circle {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: none;
  border-radius: 50%;
  margin: 5px;
  background: #fff;
  border: 1px solid #f39571;
}

#flaws .circle {
  border: 1px solid #f39571;
}

#flaws .circle.current {
  background: #f39571;
}

#flaws .slider-control-bottomcenter {
  bottom: -25px !important;
}

.blemish-slide2-inner {
  display: flex;
}

.blemish-slide2-inner > div {
  display: flex;
  flex-direction: column;
  padding-right: 6px;
  position: relative;
  flex: 1 1;
  align-items: center;
}

.blemish-slide2-inner > div:last-of-type {
  padding-right: 0;
}

.blemish-slide2-inner figcaption {
  display: flex;
  text-align: left;
  margin-top: 30px;
  font-size: 15px;
}

.blemish-slide2-inner figcaption span {
  line-height: 1;
  font-size: 30px;
  font-weight: 700;
  color: #f39571;
  padding-right: 10px;
  font-family: "Montserrat", sans-serif;
}

.corner-icon {
  position: absolute;
  top: 6px;
  left: 5px;
  width: 64px;
  height: 64px;
}

.blemish-slide3 .before-after {
  position: relative;
  width: 920px;
  height: 360px;
}

.blemish-slide.blemish-slide3 h3 {
  padding: 0;
}

.blemish-slide3 p {
  font-size: 15px;
  text-transform: uppercase;
  color: #f39571;
  margin-bottom: 28px;
}

.blemish-slide3 .before-after svg {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 10;
  width: 66px;
  height: 66px;
}

.styles_handle__33IZp {
  width: 72px !important;
  height: 100%;
  cursor: pointer;
  background: url("https://www.rowenta.com/medias/picto-before-after.png?context=bWFzdGVyfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2lufDEwODF8aW1hZ2UvcG5nfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2luL2gyZC9oMjQvMTM3OTM0NzY2NzM1NjYucG5nfDExMTA2NmFlNTk2YWY2NjRmOGRjZWM2YTkxOGI1YmNhYmRhMDViNDQ1ZjFkOTNkMmFhM2Q4NjMzOTlmZGM2Njk")
    no-repeat !important;
  background-size: 100% auto !important;
  background-position: bottom 10px left 0 !important;
  position: absolute !important;
  z-index: 8;
  left: calc(50% - 36px);
  top: 0 !important;
}

.styles_handle__33IZp:after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 0;
}

@media screen and ( max-width: 1100px ) {
  .sliderArrows {
    display: none;
  }
  .blemish-slide {
    max-width: 100%;
  }
  #antiblemish .slider-slide {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  #antiblemish {
    padding-bottom: 25px;
  }

  .sliderArrows {
    display: block;
  }

  .leftArrow,
  .rightArrow {
    width: 10px;
  }

  #antiblemish.antiblemish-page .carouselWrapper {
    width: 100%;
    top: 0;
  }

  .antiblemish_header {
    padding: 24px 0 1px;
  }

  .antiblemish_header p {
    font-size: 12px;
    margin-top: 4px;
  }

  .antiblemish_h2 {
    font-size: 28px;
    margin: 0 0 8px;
  }

  .blemish-non-slide {
    width: 100%;
    height: 100%;
    max-width: 100%;
    border: 7px solid #f39571;
    border-bottom: 3.5px solid #f39571;
    box-sizing: border-box;
  }

  .blemish-slide1-inner {
    flex-direction: column;
  }

  .blemish-slide3 .before-after {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .blemish-slide3 .before-after svg {
    overflow: hidden;
  }

  .blemish-slide3 .before-after svg {
    top: 9px;
    left: 6px;
    width: 40px;
    height: 40px;
  }

  .blemish-slide-header {
    height: auto;
    padding: 13px 0;
  }

  .blemish-slide-header p {
    font-size: 11px;
  }

  .blemish-non-slide h3 {
    font-size: 25px;
    margin: 5px 0 0;
    color: #f39571;
    font-family: "PT Serif", serif;
    font-weight: 700;
  }

  .blemish-slide3 p {
    font-size: 11px;
    margin-bottom: 10px;
  }

  .styles_wrapper__3KXDn {
    top: 0;
    left: 0;
  }

  .blemish-slide1-list {
    padding-left: 0;
    padding-top: 2px;
  }

  .blemish-slide4-content {
    flex-direction: column;
  }

  .blemish-slide1 li.brush-about-x7 svg {
    width: 19px;
    margin-right: 20px;
  }

  .blemish-slide1 li.brush-about-skin svg {
    height: 28px;
    margin-left: -12px;
    margin-right: 3px;
  }

  .blemish-slide1 li.brush-about-complexion svg {
    height: 34px;
    margin-left: -12px;
    margin-right: 2px;
  }

  .blemish-slide1 li.brush-about-waterproof svg {
    height: 27px;
    margin-left: -5px;
    margin-right: 9px;
  }

  .blemish-slide1-list ul {
    margin-bottom: 0;
  }

  .blemish-slide1-list li {
    font-size: 12px;
    max-height: 20px;
  }

  #antiblemish .blemish-slide1 .footnote p {
    text-align: left;
    width: 86%;
    margin: 12px auto;
  }

  .blemish-non-slide .care-btn {
    font-size: 12px;
    margin-top: 37px;
  }

  .blemish-non-slide.blemish-slide1 .care-btn {
    margin-top: 20px;
    width: 86%;
    margin: 12px auto;
  }

  #antiblemish .blemish-slide1 ul li strong {
    font-size: 12px;
  }

  .corner-icon {
    top: 10px;
    left: 9px;
    width: 41px;
    height: 41px;
  }

  #antiblemish .slider-control-centerleft {
    left: 12px !important;
    top: 41% !important;
  }

  #antiblemish .slider-control-centerright {
    right: 12px !important;
    top: 41% !important;
  }

  .blemish-slide2 .leftArrow,
  .rightArrow {
    width: 18px;
  }

  .blemish-slide2-inner figcaption {
    display: block;
    text-align: center;
    margin-top: 21px;
    font-size: 13px;
    font-family: "PT Serif", serif;
    font-weight: 100;
    line-height: 1.4;
  }

  #flaws .slider-control-bottomcenter {
    bottom: -33px !important;
  }

  .circle {
    width: 8px;
    height: 8px;
  }

  .blemish-non-slide.blemish-slide2 .care-btn {
    margin: 49px auto 20px;
    width: 86%;
  }

  .blemish-slide2-inner figcaption span {
    font-size: 20px;
  }

  .blemish-non-slide.blemish-slide3 .care-btn {
    width: 86%;
    margin: 20px auto;
  }
}

.blemish-slide4-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blemish-slide4-content>div {
    flex: 1 1;   
    position: relative; 
}

.blemish-slide4-content-left img {
    width: 100%;
}

#antiblemish .circle-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 1px solid #f39571;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .3s ease;
}

#antiblemish .circle-button svg {
    fill: #f39571;
}

#antiblemish .circle-button:hover svg,
#antiblemish .circle-button.active svg {
    fill: #fff;
}

#antiblemish .circle-button:hover,
#antiblemish .circle-button.active {    
    background-color: #f39571;
}

.blemish-slide4-content-right {
    text-align: left;
}

.blemish-slide4-content-right .feature .featureDesc {
    display: none;
    opacity: 0;
    width: 77%;
    padding-left: 50px;
}

.blemish-slide4-content-right .feature .featureDesc p {
    font-size: 15px;
    line-height: 1.4;
}

.blemish-slide4-content-right .feature.active {
    background-color: transparent;
}

.blemish-slide4-content-right .feature.active .featureDesc {
    display: block;
    opacity: 1;
}

.blemish-slide4-content-right strong {
    color: #f39571;
}


@media screen and (max-width: 768px ) {
    #antiblemish .circle-button {
        width: 40px;
        height: 40px;
    }

    .blemish-slide4-content-left {
        margin-top: 10px;
    }

    .blemish-slide4-content-left img {
        padding-right: 30px;
    }
    
    .blemish-slide4-content-right {
        text-align: center;
        margin-top: 20px;
    }
    
    .blemish-slide4-content-right .feature .featureDesc {       
        width: 86%;
        padding-left: 0;
        margin: 0 auto;
        min-height: 58px;
    }
    
    .blemish-slide4-content-right .feature .featureDesc p {
        font-size: 12px;
        line-height: 1.2;
    }

    .blemish-non-slide.blemish-slide4 .care-btn {
        width: 86%;
        margin: 20px auto;
    }
    
 
}
.mainWrapper {    width: calc(100vw - 10px);  }
.contentWrapper {    width: 100%;  }

  
#antifatigue .slider-control-centerleft {
	left: -70px !important;
    top: 54% !important;
    -webkit-transform: translateY(-54%)!important;
            transform: translateY(-54%)!important;
}

#antifatigue .slider-control-centerright {
	right: -70px !important;
    top: 54% !important;
    -webkit-transform: translateY(-54%)!important;
            transform: translateY(-54%)!important;
}

#antifatigue .slider-control-centerleft button,
#antifatigue .slider-control-centerright button {
	border: none !important;
	background: transparent !important;
    
}

.leftArrow, .rightArrow {
	width: 30px;
    cursor: pointer;
}

.sliderArrows:focus {
	outline: none;
	border-color: rgba(255, 255, 255, 0.85);
}

.slider-list {
    cursor: initial!important;
}

#antifatigue .slider-slide {
	display: flex!important;
	flex-direction: column;
	align-items: center;
	height: 100%!important;
}

.fatigue-slide1-inner {
    display: flex;
    align-items: center;
}

#antifatigue .slider-slide {
    padding:5px 42px;
}

#antifatigue {
    text-align: center;
}

.antifatigue_header {
    background-color: #f39571;
    color: #fff;
    padding: 22px calc(50% - 460px) 20px;
    padding-bottom: 102px;
}

.antifatigue_header p {
    font-size: 14px;
}

.antifatigue_h2 {
    font-size: 45px;
    margin: 0 0 15px;
    font-family: 'PT Serif', serif;
}

#antifatigue .carouselWrapper {
    position: relative;
    top: -75px;
    width: 920px;
    margin: 0 auto -25px;
}

.fatigue-slide-header {
    height: 120px;
    padding-top: 16px;
}

.fatigue-slide-header p {
    font-size: 15px;
    text-transform: uppercase;
    color: #f39571;
    margin-top: 9px;
}

.fatigue-slide h3 {
    font-size: 35px;
    margin: 5px 0 0;
    color: #f39571;
    font-family: 'PT Serif', serif;
    font-weight: 700;
}

.fatigue-slide {
    width: 920px;
    max-width: 920px; 
    background-color: #fff;
    color: #323e48;
}    

.fatigue-slide1-list {
    text-align: left;
    padding-left: 57px;
    padding-top: 50px;
}

.fatigue-slide1-list ul {
    margin-bottom: 65px;
}

.fatigue-slide1-list li {
    list-style: none;
    margin-bottom: 16px;
    position: relative;
    font-size: 15px;
    max-height: 26px;
}

.fatigue-slide1 li.brush-about-x7 svg {
    width: 26px;
    height: 27px;
    margin-right: 26px;
}

.fatigue-slide1 li.brush-about-skin svg {
    width: 49px;
    height: 35px;
    margin-left: -10px;
    margin-right: 13px;
}

.fatigue-slide1 li.brush-about-complexion svg {
    width: 50px;
    height: 47px;
    margin-left: -5px;
    margin-right: 6px;
}

.fatigue-slide1 li.brush-about-waterproof svg {
    width: 38px;
    height: 42px;
    margin-left: 1px;
    margin-right: 14px;
}

#antifatigue .fatigue-slide1 ul li svg {
    fill: #f39571;
    vertical-align: middle;
}

#antifatigue .fatigue-slide1 ul li strong {
    color: #f39571;
    font-size: 15px;
}

#antifatigue .fatigue-slide1 .footnote p {
    width: 285px;   
    font-size: 11px;
    font-weight: 500;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
}

.fatigue-slide .care-btn {
    margin-top: 37px;
}

.circles {
	display: flex;
}

.circle {
	cursor: pointer;
	width: 14px;
	height: 14px;
	background: none;
	border-radius: 50%;
	margin: 5px;
	background: #fff;
    border: 1px solid #f39571;
}

.circle.current {
	background: #f39571;
}

#flaws .slider-control-bottomcenter {
	bottom: -25px!important;
}

.fatigue-slide2-inner {
    display: flex;
    max-width: 700px;
    margin: 0 auto;
}

.fatigue-slide2-inner>div{
    display: flex;
    flex-direction: column;
    padding-right: 6px;
    position: relative;
    flex: 1 1;
    align-items: center;
}

.fatigue-slide2-inner>div:last-of-type {
    padding-right: 0;
}

.fatigue-slide2-inner figcaption {
    display: flex;
    text-align: left;
    margin-top: 30px;
    font-size: 15px;
}

.fatigue-slide2-inner figcaption span {
    line-height: 1;
    font-size: 30px;
    font-weight: 700;
    color: #f39571;
    padding-right: 10px;
    font-family: 'Montserrat', sans-serif;
}

.corner-icon {
    position: absolute;
    top: 6px;
    left: 5px;
    width: 64px;
    height: 64px;
}

.fatigue-slide3 .before-after {
    position: relative;
    width: 920px;
    height: 360px;
}

.fatigue-slide.fatigue-slide3 h3 {
    padding: 0;
}

.fatigue-slide3 p {
    font-size: 15px;
    text-transform: uppercase;
    color: #f39571;
    margin-bottom: 28px;
}   

.fatigue-slide3 .before-after svg {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 10;
    width: 66px;
    height: 66px;
}

.fatigue-slide5 img {
    margin-top: 55px;
    max-height: 470px;
}

.styles_handle__33IZp {
    width: 72px !important;
    height: 100%;
    cursor: pointer;
    background: url('https://www.rowenta.com/medias/picto-before-after.png?context=bWFzdGVyfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2lufDEwODF8aW1hZ2UvcG5nfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2luL2gyZC9oMjQvMTM3OTM0NzY2NzM1NjYucG5nfDExMTA2NmFlNTk2YWY2NjRmOGRjZWM2YTkxOGI1YmNhYmRhMDViNDQ1ZjFkOTNkMmFhM2Q4NjMzOTlmZGM2Njk') no-repeat !important;
    background-size: 100% auto !important;
    background-position: bottom 10px left 0 !important;
    position: absolute !important;
    z-index: 8;
    left: calc(50% - 36px);
    top: 0 !important;
}

.styles_handle__33IZp:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 0;
}

@media screen and (max-width: 1100px) {
    #antifatigue .carouselWrapper {
        width: 100%;
    }
    .sliderArrows {
        display: none;
      }
}




@media screen and (max-width: 768px) {

    #antifatigue {
        padding-bottom: 25px;
    }

    .sliderArrows {
        display: block;
      }
	
	.leftArrow, .rightArrow {
		width: 10px;
	}

    #antifatigue .carouselWrapper {
        width: 100%;
    }

    .antifatigue_header {
        padding: 24px 0 1px;
    }

    .antifatigue_header p {
        font-size: 12px;
        margin-top: 4px;
        padding: 0 10px;
    }

    #antifatigue .carouselWrapper {
        top: 0;
    }

    .antifatigue_h2 {
        font-size: 28px;
        margin: 0 0 8px;
    }

    .fatigue-non-slide {
        width: 100%;
        height: 100%;
        max-width: 100%;
        border: 7px solid #f39571;
        box-sizing: border-box;     
    }

    .fatigue-slide1-inner {
        flex-direction: column;
    }

    .fatigue-slide3 .before-after {
        width: 100%;
        height: auto;
    }

    .fatigue-slide3 .before-after svg {
        overflow: hidden;
    }
   
    .fatigue-slide3 .before-after svg {
        top: 9px;
        left: 6px;
        width: 40px;
        height: 40px;
    }

    .fatigue-slide-header {
        height: auto;
        padding: 13px 0;
    }

    .fatigue-slide-header p {
        font-size: 11px;
    }

    .fatigue-non-slide h3 {
        font-size: 25px;
        margin: 5px 0 0;
        color: #f39571;
        font-family: 'PT Serif', serif;
        font-weight: 700;
    }

    .fatigue-slide3 p {
        font-size: 11px;
        margin-bottom: 10px;
    }

    .styles_wrapper__3KXDn {
        top: 0;
        left: 0;
    }

    .fatigue-slide1-list {
        padding-left: 0;
        padding-top: 2px;
    }

    .fatigue-slide4-content {
        flex-direction: column;
    }

    .fatigue-slide5 img {
        margin: 0 0 -4px;
        max-height: none;
    }

    .fatigue-slide1 li.brush-about-x7 svg {
        width: 19px;margin-right: 20px;
    }

    .fatigue-slide1 li.brush-about-skin svg {
        height: 28px;margin-left: -12px;margin-right: 3px;
    }

    .fatigue-slide1 li.brush-about-complexion svg {
        height: 34px;margin-left: -12px;margin-right: 2px;
    }

    .fatigue-slide1 li.brush-about-waterproof svg {
        width: 29px;height: 27px;margin-left: -1px;margin-right: 11px;
    }

    .fatigue-slide1-list ul {
        margin-bottom: 0;
    }

    .fatigue-slide1-list li {
        font-size: 12px;
        max-height: 20px;
    }

    #antifatigue .fatigue-slide1 .footnote p {
        text-align: left;
        width: 86%;
        margin: 12px auto;
    }

    .fatigue-non-slide .care-btn {
       font-size: 12px;
       margin-top: 37px;
    }

    .fatigue-non-slide.fatigue-slide1 .care-btn {
        margin-top: 20px;
        width: 86%;
        margin: 12px auto;
    }

    #antifatigue .fatigue-slide1 ul li strong {
        font-size: 12px;
    }

    #antifatigue .slider-slide {
        padding: 0;
    }

    .corner-icon {
        top: 10px;
        left: 9px;
        width: 41px;
        height: 41px;
    }

	#antifatigue .slider-control-centerleft {
		left: 12px !important;
		top: 41% !important;
	}
	
	#antifatigue .slider-control-centerright {
		right: 12px !important;
		top: 41% !important;
	}	
   
    .fatigue-slide2 .leftArrow, .rightArrow {
		width: 18px;
	}

    .fatigue-slide2-inner figcaption {
        display: block;
        text-align: center;
        font-size: 13px;
        font-family: 'PT Serif', serif;
        font-weight: 100;
        line-height: 1.4;
        width: 67%;
        margin: 21px auto 0;
    }

    #flaws .slider-control-bottomcenter {
        bottom: -33px!important;
    }

    .circle {
        width: 8px;
        height: 8px;
    }

    .fatigue-non-slide.fatigue-slide2 .care-btn {
        margin: 49px auto 20px;
        width: 86%;
    }

    .fatigue-slide2-inner figcaption span {
        font-size: 20px;
    }

    .fatigue-non-slide.fatigue-slide3 .care-btn {
        width: 86%;
        margin: 20px auto;
    }

	
	
}
.antifatigue-slide4-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.antifatigue-slide4-content>div {
    flex: 1 1;   
    position: relative; 
}

.antifatigue-slide4-content-left img {
    height: 360px;
}

#antifatigue .circle-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 1px solid #f39571;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .3s ease;
}

#antifatigue .circle-button svg {
    fill: #f39571;
}

#antifatigue .circle-button:hover svg,
#antifatigue .circle-button.active svg {
    fill: #fff;
}

#antifatigue .circle-button:hover,
#antifatigue .circle-button.active {    
    background-color: #f39571;
}

.antifatigue-slide4-content-right {
    text-align: left;
}

.antifatigue-slide4-content-right .feature .featureDesc {
    display: none;
    opacity: 0;
    width: 77%;
    padding-left: 50px;
}

.antifatigue-slide4-content-right .feature .featureDesc p {
    font-size: 15px;
    line-height: 1.4;
}

.antifatigue-slide4-content-right .feature.active {
    background-color: transparent;
}

.antifatigue-slide4-content-right .feature.active .featureDesc {
    display: block;
    opacity: 1;
}

.antifatigue-slide4-content-right strong {
    color: #f39571;
}


@media screen and (max-width: 768px ) {
    #antifatigue .circle-button {
        width: 40px;
        height: 40px;
    }

    .antifatigue-slide4-content {
        flex-direction: column;
    }

    .antifatigue-slide4-content-left {
        margin-top: 10px;
    }

    .antifatigue-slide4-content-left img {
        padding-right: 30px;
        height: 275px;
    }
    
    .antifatigue-slide4-content-right {
        text-align: center;
        margin-top: 20px;
    }
    
    .antifatigue-slide4-content-right .feature .featureDesc {       
        width: 86%;
        padding-left: 0;
        margin: 0 auto;
        min-height: 58px;
    }
    
    .antifatigue-slide4-content-right .feature .featureDesc p {
        font-size: 12px;
        line-height: 1.2;
    }

    .fatigue-non-slide.fatigue-slide4 .care-btn {
        width: 86%;
        margin: 20px auto;
    }
    
 
}
/*   HEADER SECTION    */
.Flaws {
  height: 21.875vw;
  min-height: 420px;
  background-color: #e9e4e2;
  padding-top: 65px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Flaws h1 {
  margin-bottom: 8px;
}

.Flaws h1 svg {
  width: 200px;
  margin-top: 23px;
}

#header-beauty .background-video {
  width: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  height: 100%;
  overflow: hidden;
  margin: 0;
}
#header-beauty .background-video video,
#glow .background-video video,
#lift .background-video video {
  width: 100%;
  height: auto;
  position: relative;
  min-height: 420px;
  max-width: none;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.FlawsInner {
  position: relative;
  text-align: center;
  z-index: 10;
}

.FlawsInnerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -45px;
}

.Flaws nav {
  position: absolute;
  bottom: 0;
  z-index: 10;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.Flaws nav span {
  display: inline-block;
  width: 100%;
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "PT Serif", serif;
  margin-bottom: 100px;
  color: #323e48;
}

.FlawsInnerContent .underH1 {
  color: #323e48;
  max-width: 450px;
  font-size: 14px;
}

.FlawsInnerContent .underH1:after {
  content: "";
  width: 45px;
  height: 1px;
  background-color: #323e48;
  position: absolute;
  bottom: -8px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.Flaws nav a figure {
  width: 60px;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

.Flaws nav a figure img {
  width: auto;
  height: 100%;
  max-width: none;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.Flaws nav a.link-noflaws {
  left: 50%;
  -webkit-transform: translate(-160%);
          transform: translate(-160%);
  background-color: #f39571;
}

.Flaws nav a.link-glowskin {
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  background-color: #b58874;
}

.Flaws nav a.link-lift {
  left: 50%;
  -webkit-transform: translate(58%);
          transform: translate(58%);
  background-color: #dbb68c;
}

.Flaws nav a h2 {
  font-size: 22px;
  white-space: nowrap;
  color: #fff;
  margin: 0;
  line-height: 40px;
  font-family: "PT Serif", serif;
}

.Flaws nav a {
  position: absolute;
  bottom: 0;
  min-width: 325px;
  line-height: 1;
  font-size: 14px;
  color: #fff;
  text-align: left;
  padding: 2px 10px 14px 85px;
}

/*  NEW NAVIGATION    */
.navigation-block {
  display: grid;
  grid-template-columns: 1fr 333px 333px 333px 1fr;
  grid-template-rows: 20px 20px auto;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  grid-column-gap: 2%;
  -webkit-column-gap: 2%;
          column-gap: 2%;
}

.navigation-block > span {
  text-align: center;
  grid-column: 1 /-1;
  grid-row: 1;
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "PT Serif", serif;
  margin-bottom: 22px;
  color: #323e48;
}

.middle-row-placeholder {
  grid-column: 1 / -1;
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr 333px 333px 333px 1fr;
  grid-column-gap: 2%;
  -webkit-column-gap: 2%;
          column-gap: 2%;
}

.page-indicator {
  width: 100%;
  height: 8px;
  background-color: #bf9001;
  margin-top: 8px;
}

.flaws-page-indicator {
  grid-column: 2;
}

.glow-page-indicator {
  grid-column: 3;
}

.lift-page-indicator {
  grid-column: 4;
}

.navigation-wrapper {
  /* display: flex;
  width: 100%;
  justify-content: center; */
  grid-column: 1 /-1;
  display: grid;
  grid-template-columns: 1fr 333px 333px 333px 1fr;
  grid-column-gap: 2%;
  -webkit-column-gap: 2%;
          column-gap: 2%;
}

.navigation-wrapper a {
  z-index: 20;
  cursor: pointer;
  /* margin: 0 1%; */
  flex: 1 1;
  max-width: 333px;
}

.navigation-wrapper a:nth-of-type(1) {
  background-color: #f39571;
  grid-column: 2;
}

.navigation-wrapper a:nth-of-type(2) {
  background-color: #b58874;
  grid-column: 3;
}

.navigation-wrapper a:nth-of-type(3) {
  background-color: #dbb68c;
  grid-column: 4;
}

.navigation-wrapper a h2 {
  font-size: 22px;
  white-space: nowrap;
  color: #fff;
  margin: 0;
  font-family: "PT Serif", serif;
  padding-left: 16px;
}

.navigation-wrapper a h2 span {
  line-height: 1.7;
  font-size: 14px;
  color: #fff;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  display: block;
}

.navigation-wrapper .nav-wrapper {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .navigation-block {
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }
  .middle-row-placeholder {
    grid-column: 1 / -1;
    grid-row: 2;
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }
  .flaws-page-indicator {
    grid-column: 1;
  }

  .glow-page-indicator {
    grid-column: 2;
  }

  .lift-page-indicator {
    grid-column: 3;
  }
}

@media screen and (max-width: 1100px) {
  .navigation-block {
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }
  .middle-row-placeholder {
    grid-column: 1 / -1;
    grid-row: 2;
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }
  .flaws-page-indicator {
    grid-column: 1;
  }

  .glow-page-indicator {
    grid-column: 2;
  }

  .lift-page-indicator {
    grid-column: 3;
  }
  /* .navigation-wrapper a {
    margin: 0 2px;
    height: 70px;
  } */
  .navigation-wrapper {
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
  }
  .navigation-wrapper a:nth-of-type(1) {
    grid-column: 1;
  }

  .navigation-wrapper a:nth-of-type(2) {
    grid-column: 2;
  }

  .navigation-wrapper a:nth-of-type(3) {
    grid-column: 3;
  }
  .navigation-wrapper a h2 {
    font-size: 18px;
    white-space: normal;
  }
  .navigation-wrapper a h2 span {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .navigation-wrapper a img {
    display: none;
  }
  .navigation-wrapper .nav-wrapper {
    justify-content: center;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #header-beauty .background-video video,
  #glow .background-video video,
  #lift .background-video video {
    width: auto;
    height: 100%;
  }
  .navigation-wrapper a {
    margin: 0;
  }
  .navigation-wrapper a h2 {
    font-size: 16px;
    line-height: 1;
  }
  .navigation-wrapper a h2 span {
    font-size: 10px;
    line-height: 1.2;
    padding-top: 5px;
  }
  .Flaws {
    height: auto;
    min-height: 525px;
    max-height: none;
    padding: 0;
    position: relative;
  }

  .Flaws h1 {
    /* margin: 233px auto 0!important; */
    margin: 133px auto 0 !important;
  }

  .Flaws h1 svg {
    margin-top: -15px;
    width: 174px;
  }

  .FlawsInnerContent {
    margin-top: 0;
  }

  .Flaws nav {
    position: static;
    -webkit-transform: translate(0);
            transform: translate(0);
    display: inline-block;
    width: 100%;
    font-size: 0;
    padding-bottom: 70px;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
  }

  .Flaws nav span {
    font-size: 14px;
    margin-bottom: 14px;
    text-align: center;
  }

  .Flaws nav a figure {
    width: 50px;
    height: 100%;
    overflow: hidden;
  }

  .Flaws nav a figure img {
    float: right;
    width: auto;
    height: 100%;
    max-width: none;
  }

  .Flaws nav a.link-noflaws {
    left: 50%;
    background-color: #f39571;
    height: 60px;
  }

  .Flaws nav a.link-glowskin {
    left: 50%;
    background-color: #b58874;
  }

  .Flaws nav a h2 {
    font-size: 14px;
    margin-bottom: 4px;
    white-space: normal;
    line-height: 1;
    max-width: 90%;
  }

  .Flaws nav a {
    left: 50%;
    vertical-align: top;
    width: 50%;
    min-width: auto;
    font-size: 8px;
    font-weight: 500;
    padding: 10px 0 10px 56px;
    margin: 0;
  }

  figcaption {
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
    position: relative;
    z-index: 8;
    left: auto;
    right: auto;
    top: auto;
    line-height: 1;
    font-weight: 700;
    font-family: "PT Serif", serif;
  }

  .brand-muse-noflaws figcaption {
    color: #f39571;
  }

  .brand-muse-glowskin figcaption {
    color: #b58874;
  }

  .brand-muse-noflaws {
    float: left;
    width: 50%;
    height: 200px;
    padding-top: 10px;
    margin-bottom: 20px;
    position: relative;
    right: auto;
    left: 0;
    overflow: hidden;
    top: 0;
    margin: 0;
    position: absolute;
  }

  .brand-muse-glowskin {
    float: right;
    width: 50%;
    height: 200px;
    padding-top: 10px;
    margin-bottom: 20px;
    position: relative;
    right: auto;
    right: 0;
    overflow: hidden;
    top: 0;
    margin: 0;
    position: absolute;
  }

  .FlawsInnerContent .underH1 {
    font-size: 11px;
    max-width: 94%;
  }

  .FlawsInnerContent .underH1:after {
    bottom: -13px;
  }
}

#flaws {
    font-family: 'Montserrat', sans-serif;
}

.care-btn {
    background-color: #f39571;
    color: #fff;
    display: inline-block;
    width: auto;
    font-family: 'Montserrat', sans-serif;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 45px;
    border: 0;
    border-radius: 4px;    
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}

.care-btn:hover {-webkit-filter: brightness(0.85);filter: brightness(0.85);}

/*   FAQ   */

#flaws .faqSection-content .eyeTab,
#flaws .faqSection-content .facialTab {
    border: 3px solid #f39571!important;
}
#flaws .faqSection-content .keyvalue_accordion_header h4,
#flaws .faqSection-content .keyvalue_accordion_content_left h4 {
    color: #f39571!important;
}
#flaws .faqSection-content .keyvalue_accordion_item .close {
    color: #f39571!important;
    border: 1px solid #f39571!important;
    background-color: #fff!important;
}
#flaws .faqSection-content .keyvalue_accordion_item.true .close {
    background-color: #f39571!important;
    color: #fff!important;
    border: 1px solid #f39571!important;
}

#flaws .faqFacialSection-content .keyvalue_accordion_inner {
    border-bottom: 1px solid #b58874!important;
}
#flaws .faqSection-content .toggle a div:hover, 
#flaws .faqSection-content .toggle a.activetab div {
    background-color: #f39571!important;
}
#flaws .faqSection-content .toggle a div {color: #f39571!important;}
#flaws .faqSection-content .toggle a div:hover,
#flaws .faqSection-content .toggle a.activetab div {
    color: #fff!important;
}



/*   GLOW SKIN BANNER  */
.glowskin-banner {
    background-color: #f0ece9;
    padding: 90px 0 80px;
    margin-bottom: 0;
    margin-top: 70px;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 17px;
    text-align: center;
    position: relative;
    max-width: 100%;
    height: auto;
    overflow: hidden;
}

.glowskin-banner .collection-poster {
    width: 240px;
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
    position: absolute;
    top: 70px;
    left: calc(50% + 170px);
}

.glowskin-banner .collection-eye-massager {
    width: 45px;
    -webkit-transform: rotate(-96deg);
            transform: rotate(-96deg);
    position: absolute;
    top: -70px;
    left: calc(50% + 220px);
}

.glowskin-banner .collection-brush {
    width: 125px;
    -webkit-transform: rotate(-11deg);
            transform: rotate(-11deg);
    position: absolute;
    top: 100px;
    right: calc(50% + 250px);
}

.glowskin-banner h2 {
    position: relative;
    color: #b58874;
    margin: 0 auto 15px;
    max-width: 450px;
    font-size: 45px;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    overflow-wrap: break-word;
    line-height: 1;
}

.glowskin-banner p {
    margin-bottom: 25px;
}

.glowskin-banner .care-btn{
    background-color: #b58874;
}

@media screen and (max-width: 768px) {
    .glowskin-banner {
        padding: 65px 60px 40px;
        margin-bottom: 9px;
        margin-top: 9px;
    }

    .glowskin-banner .collection-poster {
        width: 170px;
        position: absolute;
        top: 45px;
        left: calc(50% + 50px);
    }
    
    .glowskin-banner .collection-eye-massager {
        width: 30px;
        position: absolute;
        top: -52px;
        left: calc(50% + 60px);
    }
    
    .glowskin-banner .collection-brush {
        width: 83px;
        position: absolute;
        top: 60px;
        right: calc(50% + 80px);
    }
    
    .glowskin-banner h2 {
        max-width: 200px;
        margin-bottom: 10px;
        line-height: 1;
        font-size: 30px;
    }

    .glowskin-banner figure img {
        z-index: 1;
    }

    .glowskin-banner h2,
    .glowskin-banner p,
    .glowskin-banner .care-btn {
        z-index: 3;
        position: relative;
    }

    .glowskin-banner p {
        font-size: 12px;
        margin-bottom: 14px;
    }

    .glowskin-banner .care-btn {
        font-size: 12px;
        width: 86%;
        margin: 0 auto;
    }



}








#facialHeader {
    font-family: 'Montserrat', sans-serif;background-color: #e9e4e2;
}

.FacialHeader {
    position: relative;
}

#facialHeader h1 {
    margin-bottom: 8px;
}

#facialHeader h1 svg {
    width: 200px;
    margin-top: 23px;
}

.FacialHeaderInner  {
    position: absolute;
    top: 50px;
    left: 50%;
    -webkit-transform: translate(-50%,-50px);
            transform: translate(-50%,-50px);
    text-align: center;    
    z-index: 10;
}

.FacialHeaderInnerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FacialHeaderInnerContent .underH1 {
    color: #323e48;
    max-width: 450px;
    font-size: 14px;
}

.FacialHeader nav {
    height: 70px;
    display: flex;
    width: 90%;
    margin: 0 auto;
}

.FacialHeader nav a {   
    line-height: 1;
    font-size: 14px;
    color: #fff;
    text-align: center;
    flex: 1 1;
}

.FacialHeader .nav-p {
    line-height: 1;
    font-size: 19px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'PT Serif', serif;
    margin: 3.5em 0 1.8em;
    color: #323e48;
    text-align: center;
    position: relative;
}

.nav-p:before {
    content: "";
    width: 45px;
    height: 1px;
    background-color: #323e48;
    position: absolute;
    top: -2.2em;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}

.FacialHeader nav a:nth-child(1) { background-color: #f39571;  }
.FacialHeader nav a:nth-child(2) { background-color: #b58874;  }
.FacialHeader nav a:nth-child(3) { background-color: #dbb68c;  }
.FacialHeader nav a:nth-child(4) { background-color: #a49dc2;  }

.FacialHeader nav a h2 {
    font-size: 22px;
    white-space: nowrap;
    color: #fff;
    margin: 0;
    line-height: 40px;
    font-family: 'PT Serif', serif;
}


#facialHeader .slider {overflow: hidden;}
.sliderArrows {
    outline: none;
    border: none;
    background: transparent;
}
#facialHeader .slider-control-centerleft {display: none;}
#facialHeader .slider-control-centerright {display: none;}
.headerSlide {
    width: auto;
    height: auto;
    min-height: 290px;
    overflow: hidden;
    position: relative;
}
.headerSlide figcaption {display: none;}
#facialHeader .slider-list li:nth-child(1) .headerSlide {background-color:#f0ece9;}
#facialHeader .slider-list li:nth-child(2) .headerSlide {background-color:#fff0de;}
#facialHeader .slider-list li:nth-child(3) .headerSlide {background-color:#fdece7;}
#facialHeader .slider-list li .headerSlide:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 8;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
}

#facialHeader .slider-list li:nth-child(1) .headerSlide:before { border:5px solid #b58874;}
#facialHeader .slider-list li:nth-child(2) .headerSlide:before { border:5px solid #dbb68c;}
#facialHeader .slider-list li:nth-child(3) .headerSlide:before { border:5px solid #f39571;}

#facialHeader .headerSlide figcaption { margin-top: 18px;}
#facialHeader .slider-list li:nth-child(1) .headerSlide figcaption { color:#b58874;}
#facialHeader .slider-list li:nth-child(2) .headerSlide figcaption { color:#dbb68c;}
#facialHeader .slider-list li:nth-child(3) .headerSlide figcaption { color:#f39571;}

.headerSlide>img {
    position: absolute;
    z-index: 5;
}

.headerSlide2Muse {
    width: 247px;
    left: calc(50vw - 480px);
    top: 23px;
}

.headerSlide2Visual {
    position: absolute;
    z-index: 5;
    top: 45px;
    left: calc(50vw + 250px);
}
.headerSlide3Muse {
    width: 357px;
    left: calc(50vw - 550px);
    top: 12px;
}

.headerSlide3Visual {
    position: absolute;
    z-index: 5;
    left: calc(50vw + 240px);
    top: 34px;
}

.headerSlide1Muse {
    width: 260px;
    left: calc(50vw - 490px);
    top: 15px;
}

.headerSlide1Visual {
    position: absolute;
    z-index: 5;
    top: 45px;
    left: calc(50vw + 250px);
}


@media screen and ( max-width: 1100px ) {
    .sliderArrows {
      display: none;
    }
  }


@media screen and ( max-width: 768px ) {

    .sliderArrows {
        display: block;
      }
    .FacialHeader {
        height: auto;
        min-height: 525px;
        max-height: none;
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .FacialHeader h1 {
        margin: 20px auto 0!important;
    }

    #facialHeader h1 svg {
        margin-top: -15px;
        width: 174px;
    }

    .FacialHeaderInner {
        order: 1;
        width: 90%;
        position: relative;
        top: 0%;
        -webkit-transform: translate(-50%,0);
                transform: translate(-50%,0);
    }

    .FacialHeaderInnerContent {
        margin-top: 7px;
    }

    .FacialHeader nav {
        order: 3;
        width: 100%;
    }
    
    .FacialHeader .nav-p {
        font-size: 14px;
        margin: 2.3em 0 1.4em;
        order: 2;
    }

    .FacialHeader .nav-p:before {
        top: -1.6em;
    }

    .FacialHeader nav a {
        font-size: 10px;
        max-width:33.3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 6%;
    }

    .FacialHeader nav a:nth-child(3) {padding: 0 1%;}

    
    .FacialHeader nav a.link {
        height: 60px;
    }
   
    .FacialHeader nav a h2 {
        font-size: 14px;
        margin-bottom: 4px;
        white-space: normal;
        line-height: 1;
        max-width: 90%;
    }
    
    .FacialHeaderInnerContent .underH1 {
        font-size: 11px;
        max-width: 94%;
    }

    .FacialHeaderInnerContent .underH1:after {
        bottom: -13px;
    }

    #facialHeader .slider-control-centerleft {display:block;left:4vw!important}
    #facialHeader .slider-control-centerright {display:block;right: 4vw!important;}
    #facialHeader .leftArrow, .rightArrow {  width: 18px!important;  }

    .headerSlide {min-height: 220px;}
    .headerSlide figcaption {display: block;}

    .headerSlide2Muse {
        width: 150px;
        left: calc(50vw - 140px);
        top: 55px;
    }
    
    .headerSlide2Visual {
        width: 125px;
        left: 50vw;
        top: 45px;
    }

    .headerSlide3Muse {
        width: 195px;
        left: calc(50vw - 160px);
        top: 55px;
    }
    
    .headerSlide3Visual {
        width: 130px;
        left: 50vw;
        top: 50px;
    }
    
    .headerSlide1Muse {
        width: 150px;
        left: calc(50vw - 140px);
        top: 55px;
    }
    
    .headerSlide1Visual {
        width: 120px;
        left: 50vw;
        top: 50px;
    }
}

@media screen and (min-width: 1100px) {
    #facialHeader .slider-control-centerleft {display:block;left:4vw!important}
    #facialHeader .slider-control-centerright {display:block;right: 4vw!important;}
}

@media screen and (min-width: 1300px) {
    #facialHeader .slider-control-centerleft {left:10vw!important}
    #facialHeader .slider-control-centerright {right: 10vw!important;}
}

@media screen and (min-width: 1500px) {
    #facialHeader .slider-control-centerleft {left:16vw!important}
    #facialHeader .slider-control-centerright {right: 16vw!important;}
}

@media screen and (min-width: 1750px) {
    #facialHeader .slider-control-centerleft {left:20vw!important}
    #facialHeader .slider-control-centerright {right: 20vw!important;}
}
#radiance .slider-control-centerleft {
	left: -70px !important;
    top: 54% !important;
    -webkit-transform: translateY(-54%)!important;
            transform: translateY(-54%)!important;
}

#radiance .slider-control-centerright {
	right: -70px !important;
    top: 54% !important;
    -webkit-transform: translateY(-54%)!important;
            transform: translateY(-54%)!important;
}

#radiance .slider-control-centerleft button,
#radiance .slider-control-centerright button {
	border: none !important;
	background: transparent !important;
    
}

#radiance .slider-slide {
	display: flex!important;
	flex-direction: column;
	align-items: center;
	height: 100%!important;
}

.radiance-slide1-inner {
    display: flex;
    align-items: center;
}

#radiance .slider-slide {
    padding:5px 42px;
}

#radiance {
    text-align: center;
    padding-bottom: 80px;
}

.radiance_header {
    background-color: #b58874;
    color: #fff;
    padding: 16px calc(50% - 460px) 7px;
}

.radiance_header p {
    font-size: 14px;
    margin-top: 10px;
}

.radiance_h2 {
    font-size: 45px;
    margin: 0 0 10px;
    font-family: 'PT Serif', serif;
}

#radiance .carouselWrapper {
    position: relative;
    width: 920px;
    margin: 0 auto -25px;
}

.radiance-slide-header {
    height: 11vw;
    padding-top: 3vw;
}

.radiance-slide-header p {
    font-size: 15px;
    text-transform: uppercase;
    color: #b58874;
    margin-top: 9px;
}

.radiance-slide h3 {
    font-size: 35px;
    margin: 5px 0 0;
    color: #b58874;
    font-family: 'PT Serif', serif;
    font-weight: 700;
}

.radiance-slide {
    width: 920px;
    max-width: 920px; 
    background-color: #fff;
    color: #323e48;
}    

.radiance-slide1-list {
    text-align: left;
    padding-left: 57px;
    padding-top: 50px;
}

.radiance-slide1-list ul {
    margin-bottom: 65px;
}

.radiance-slide1-list li {
    list-style: none;
    margin-bottom: 16px;
    position: relative;
    font-size: 15px;
    max-height: 26px;
}

.radiance-slide1 li.brush-about-x7 svg {
    width: 26px;
    height: 27px;
    margin-right: 26px;
}

.radiance-slide1 li.brush-about-radiance svg {
    width: 47px;
    height: 35px;
    margin-left: -10px;
    margin-right: 13px;
}

.radiance-slide1 li.brush-about-complexion svg {
    width: 50px;
    height: 47px;
    margin-left: -5px;
    margin-right: 6px;
}

.radiance-slide1 li.brush-about-waterproof svg {
    width: 38px;
    height: 42px;
    margin-left: 1px;
    margin-right: 14px;
}

#radiance .radiance-slide1 ul li svg {
    fill: #b58874;
    vertical-align: middle;
}

#radiance .radiance-slide1 ul li strong {
    color: #b58874;
    font-size: 15px;
}

#radiance .radiance-slide1 .footnote p {
    width: 285px;   
    font-size: 11px;
    font-weight: 500;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
}

#radiance .care-btn {
    background-color: #b58874;
}

.radiance-slide .care-btn {
    margin-top: 37px;
    background-color: #b58874;
}

.circles {
	display: flex;
}

.circle {
	cursor: pointer;
	width: 14px;
	height: 14px;
	background: none;
	border-radius: 50%;
	margin: 5px;
	background: #fff;
    border: 1px solid #b58874;
}

.circle.current {
	background: #b58874;
}

#radiance .slider-control-bottomcenter {
	bottom: -25px!important;
}

.radiance-slide2-inner {
    display: flex;
}

.radiance-slide2-inner>div{
    display: flex;
    flex-direction: column;
    padding-right: 6px;
    position: relative;
    flex: 1 1;
    align-items: center;
}

.radiance-slide2-inner>div:last-of-type {
    padding-right: 0;
}

.radiance-slide2-inner figcaption {
    display: flex;
    text-align: left;
    margin-top: 30px;
    font-size: 15px;
}

.radiance-slide2-inner figcaption span {
    line-height: 1;
    font-size: 30px;
    font-weight: 700;
    color: #b58874;
    padding-right: 10px;
    font-family: 'Montserrat', sans-serif;
}

.corner-icon {
    position: absolute;
    top: 6px;
    left: 5px;
    width: 64px;
    height: 64px;
}

.radiance-slide3 .before-after {
    position: relative;
    width: 920px;
    height: 360px;
}

.radiance-slide.radiance-slide3 h3 {
    padding: 0;
}

.radiance-slide3 p {
    font-size: 15px;
    text-transform: uppercase;
    color: #b58874;
    margin-bottom: 28px;
}   

.radiance-slide3 .before-after svg {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 10;
    width: 66px;
    height: 66px;
}

.radiance-slide5 img {
    margin-top: 55px;
    max-height: 470px;
}

.styles_handle__33IZp {
    width: 72px !important;
    height: 100%;
    cursor: pointer;
    background: url('https://www.rowenta.com/medias/picto-before-after.png?context=bWFzdGVyfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2lufDEwODF8aW1hZ2UvcG5nfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2luL2gyZC9oMjQvMTM3OTM0NzY2NzM1NjYucG5nfDExMTA2NmFlNTk2YWY2NjRmOGRjZWM2YTkxOGI1YmNhYmRhMDViNDQ1ZjFkOTNkMmFhM2Q4NjMzOTlmZGM2Njk') no-repeat !important;
    background-size: 100% auto !important;
    background-position: bottom 10px left 0 !important;
    position: absolute !important;
    z-index: 8;
    left: calc(50% - 36px);
    top: 0 !important;
}

.styles_handle__33IZp:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 0;
}

@media screen and (max-width: 1100px) {
    .radiance-slide-header {height: 14vw; }
}

@media screen and (max-width: 980px) {
    #radiance .carouselWrapper {
        width: 100%;
    }

    #radiance .slider-slide {padding: 0;}    
    .radiance-slide {width: 100%;}
    #radiance .slider-control-centerleft {display: none; }    
    #radiance .slider-control-centerright {display: none; }
}

@media screen and (max-width: 768px) {

    #radiance {
        padding-bottom: 0;
    }
	
	.leftArrow, .rightArrow {
		width: 10px;
        display: block;
	}

    #radiance .carouselWrapper {
        width: 100%;
    }

    .radiance_header {
        padding: 24px 0 1px;
    }

    .radiance_header p {
        font-size: 12px;
        margin-top: 4px;
    }

    .radiance_h2 {
        font-size: 28px;
        margin: 0 0 8px;
    }

    .radiance-non-slide {
        width: 100%;
        height: 100%;
        max-width: 100%;
        border: none;
        padding: 0 8px;
        border-bottom: 9px solid #b58874;   
        box-sizing: border-box;     
    }

    .radiance-slide1-inner {
        flex-direction: column;
    }

    .radiance-slide3 .before-after {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .radiance-slide3 .before-after svg {
        overflow: hidden;
    }
   
    .radiance-slide3 .before-after svg {
        top: 9px;
        left: 6px;
        width: 46px;
        height: 46px;
    }

    .radiance-slide-header {
        height: auto;
        padding: 4vw 0;
    }

    .radiance-slide-header p {
        font-size: 11px;
    }

    .radiance-non-slide h3 {
        font-size: 25px;
        margin: 5px 0 0;
        color: #b58874;
        font-family: 'PT Serif', serif;
        font-weight: 700;
    }

    .radiance-slide3 p {
        font-size: 11px;
        margin-bottom: 10px;
    }

    .radiance-slide5 img {
        margin: 0 0 -4px;
        max-height: none;
    }

    .styles_wrapper__3KXDn {
        top: 0;
        left: 0;
    }

    .radiance-slide1-list {
        padding-left: 0;
        padding-top: 2px;
    }

    .radiance-slide4-content {
        flex-direction: column;
    }

    .radiance-slide1 li.brush-about-x7 svg {
        width: 19px;margin-right: 20px;
    }

    .radiance-slide1 li.brush-about-radiance svg {
        max-width:30px; height: 28px;margin-left: -12px;margin-right: 22px;
    }

    .radiance-slide1 li.brush-about-complexion svg {
        height: 34px;margin-left: -12px;margin-right: 2px;
    }

    .radiance-slide1 li.brush-about-waterproof svg {
        height: 27px;margin-left: -5px;margin-right: 9px;
    }

    .radiance-slide1-list ul {
        margin-bottom: 0;
    }

    .radiance-slide1-list li {
        font-size: 12px;
        max-height: 20px;
    }

    #radiance .radiance-slide1 .footnote p {
        text-align: left;
        width: 86%;
        margin: 7px auto 23px;
        font-size: 8px;
    }

    .radiance-non-slide .care-btn {
       font-size: 12px;
       margin-top: 37px;
    }

    .radiance-non-slide.radiance-slide1 .care-btn {
        margin-top: 20px;
        width: 86%;
        margin: 12px auto;
    }

    #radiance .radiance-slide1 ul li strong {
        font-size: 12px;
    }

    #radiance .slider-slide {
        padding: 0;
    }

    .corner-icon {
        top: 10px;
        left: 9px;
        width: 41px;
        height: 41px;
    }

	#radiance .slider-control-centerleft {
		left: 12px !important;
		top: 41% !important;
        display: block;
	}
	
	#radiance .slider-control-centerright {
		right: 12px !important;
		top: 41% !important;
        display: block;
	}	

    .radiance-slide2 .leftArrow, .rightArrow {
		width: 18px;
	}

    .radiance-slide2-inner figcaption {
        display: block;
        text-align: center;
        margin-top: 21px;
        font-size: 13px;
        font-family: 'PT Serif', serif;
        font-weight: 100;
        line-height: 1.4;
    }

    #radiance .slider-control-bottomcenter {
        bottom: -33px!important;
    }

    .circle {
        width: 8px;
        height: 8px;
    }

    .radiance-non-slide.radiance-slide2 .care-btn {
        margin: 49px auto 30px;
        width: 86%;
    }

    .radiance-slide2-inner figcaption span {
        font-size: 20px;
    }

    .radiance-non-slide.radiance-slide3 .care-btn {
        width: 86%;
        margin: 30px auto;
    }

	
	
}

@media screen and (min-width: 1200px) {
    .radiance-slide-header {padding-top: 2.3vw; }
    .radiance-slide-header {height: 10vw; }
}

@media screen and (min-width: 1450px) {
    .radiance-slide-header {height: 8.5vw; }
}

@media screen and (min-width: 1750px) {
    .radiance-slide-header {height: 8vw; }
}
.radiance-slide4-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.radiance-slide4-content>div {
    flex: 1 1;   
    position: relative; 
}

.radiance-slide4-content-left img {
    width: 100%;
    max-width: 406px;
}

#radiance .circle-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 1px solid #b58874;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .3s ease;
}

#radiance .circle-button svg {
    fill: #b58874;
}

#radiance .circle-button:hover svg,
#radiance .circle-button.active svg {
    fill: #fff;
}

#radiance .circle-button:hover,
#radiance .circle-button.active {    
    background-color: #b58874;
}

.radiance-slide4-content-right {
    text-align: left;
}

.radiance-slide4-content-right .feature .featureDesc {
    display: none;
    opacity: 0;
    width: 77%;
    padding-left: 50px;
}

.radiance-slide4-content-right .feature .featureDesc p {
    font-size: 15px;
    line-height: 1.4;
}

.radiance-slide4-content-right .feature.active {
    background-color: transparent;
}

.radiance-slide4-content-right .feature.active .featureDesc {
    display: block;
    opacity: 1;
}

.radiance-slide4-content-right strong {
    color: #b58874;
}


@media screen and (max-width: 768px ) {
    #radiance .circle-button {
        width: 40px;
        height: 40px;
    }

    .radiance-slide4-content-left {
        margin-top: 10px;
    }

    .radiance-slide4-content-left img {
        padding-right: 30px;
        width: 97%;
    }
    
    .radiance-slide4-content-right {
        text-align: center;
        margin-top: 20px;
    }
    
    .radiance-slide4-content-right .feature .featureDesc {       
        width: 86%;
        padding-left: 0;
        margin: 0 auto;
        min-height: 58px;
    }
    
    .radiance-slide4-content-right .feature .featureDesc p {
        font-size: 12px;
        line-height: 1.2;
    }

    .radiance-non-slide.radiance-slide4 .care-btn {
        width: 86%;
        margin: 30px auto;
    }
    
 
}
.mainWrapper {    width: calc(100vw - 10px);  }
.contentWrapper {    width: 100%;  }

  
.radiance2 .s1-main-img-desktop {max-width: 450px;}
.radiance2 .radiance-slide1-list li { margin-bottom: 2.6em;display: flex; align-items: center;flex-wrap: wrap; }
.radiance2 .radiance-slide1 li.brush-about-radiance svg {margin-left: -6px;}
.radiance2 .radiance-slide1 li.brush-about-complexion svg {margin-right: 10px;}
.benefits-boosts img {max-width: 50px; margin-left: -5px;margin-right: 13px;}
.benefits-wellbeing img {max-width: 47px;margin-left: -3px;margin-right: 15px;}

.radiance2 .radiance-slide2 {width: 690px;}
.radiance2 .radiance-slide4-content-left img {max-width: 360px;}

@media screen and (max-width: 768px) {
    .radiance2 .radiance-slide1-list li {margin-bottom: 2em;}
    .radiance2 .radiance-slide1-list li:first-child {margin-bottom: 1.65em;}
    .radiance2 .radiance-slide1 li.brush-about-radiance svg {margin-right: 17px; max-width: 36px;}
    .radiance2 .radiance-slide1 li.brush-about-complexion svg {height: 36px;}
    .benefits-boosts img {max-width: 40px;}
    .benefits-wellbeing img {max-width: 36px;}

    .radiance2 .radiance-slide2-inner figcaption {padding: 0 40px;}
    .radiance2 .radiance-non-slide.radiance-slide2 .radiance-slide2-inner .slider-slide>div {width: 100%;}
    .radiance2 .radiance-non-slide.radiance-slide2 .radiance-slide2-inner img {width: 100%;display: block;}
}
.radiance3 .s1-main-img-desktop {max-width: 440px;}
.radiance3 .radiance-slide1-list li {margin-bottom: 32px;}
.radiance3 .radiance-slide1 li:nth-child(1) svg {width: 42px;height: 34px;margin-right: 15px;}
.radiance3 .radiance-slide1 li:nth-child(2) svg {width: 41px;margin-right: 16px;}
.radiance3 .radiance-slide1 li:nth-child(3) svg {margin-right: 10px;width: 47px;height: 38px;}


.radiance3 .radiance-slide2 {width: 690px;}

.radiance3 .radiance-slide4-content-left img {width: auto; height: 273px;}

#radiance.radiance4 {
    padding-bottom: 40px;
}



@media screen and (max-width: 768px) {
    .radiance3 .radiance-slide1-list {padding: 5px 25px 10px;}
    .radiance3 .radiance-slide1 li:nth-child(1) svg {width: 28px;}
    .radiance3 .radiance-slide1 li:nth-child(2) svg {width: 27px;}
    .radiance3 .radiance-slide1 li:nth-child(3) svg {margin-right: 12px;width: 30px;}

    .radiance3 .radiance-slide2-inner figcaption {padding: 0 40px;}
    .radiance3 .radiance-non-slide.radiance-slide2 .radiance-slide2-inner .slider-slide>div {width: 100%;}
    .radiance3 .radiance-non-slide.radiance-slide2 .radiance-slide2-inner img {width: 100%;display: block;}
}
#faqFacialSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
}

#faqFacialSection h2 {
    margin-bottom: 15px;
    color: #b58874;
    font-size: 45px;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    overflow-wrap: break-word;
    margin: 47px 0 56px;
}

.faqFacialSection-content  {
    width: 920px;
}

.faqFacialSection-content .toggle {
    display: flex;
}

.faqFacialSection-content .toggle div {
    font-size: 21px;
    line-height: 0.9em;
    font-weight: 700;
    font-family: 'PT Serif', serif;   
    cursor: pointer;
    padding: 0px 40px 0 0;
    margin-right: 1px;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faqFacialSection-content .toggle a div {
    background-color: #f7f4f2;
    color: #b58874;
}

.faqFacialSection-content .toggle a div:hover,
.faqFacialSection-content .toggle a.activetab div {
    background-color: #b58874;
    color: #f7f4f2;
}

.faqFacialSection-content .toggle div img {
    margin-right: 20px;
}

.facialTab,
.youthTab,
.eyeTab {
    width: 100%;
    text-align: left;
    border: 3px solid #b58874;
    padding: 0 40px 10px;
    margin: 0;
}

#keyvalue {
    width: 100%;
}

.keyvalue_accordion_inner {
    border-bottom: 1px solid #b58874;
    cursor: pointer;
    padding: 15px 0;
}

.keyvalue_accordion_inner:last-of-type {
    border-bottom: none!important;
}

.keyvalue_accordion_item button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.keyvalue_accordion_header h4 {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #b58874;
    transition: font-weight .3s ease;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
}

.keyvalue_accordion_content {
    display: flex;
    color: #323e48;
    font-size: 15px;
    justify-content: space-between;
}

.keyvalue_accordion_header h4 span,
.keyvalue_accordion_content_left h4 span{
    line-height: 1;
    font-size: 30px;
    font-weight: 700;
    padding-right: 5px;
}

.keyvalue_accordion_content_left h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #b58874;
    font-size: 18px;
}

.keyvalue_accordion_content_left p {
    padding-left: 22px;
}

.keyvalue_accordion_content_left ul {
    list-style: disc;
    padding-left: 38px;
}

.keyvalue_accordion_content_left p.footnote {
    font-size: 11px;
    font-weight: 700;
}

.keyvalue_accordion_content p {
    line-height: 1.5;
}

.keyvalue_accordion_content a {
    color: #b58874;
}

.keyvalue_accordion_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.keyvalue_accordion_content_right {
    position: relative;
}

.arrowButton {
    width: 22px;
    height: 22px;
}

.keyvalue_accordion_item .close {   
	background-color: #fff;
    color: #b58874;
	cursor: pointer;
	border-radius: 50%;
    border: 1px solid #b58874;
    min-width: 50px;
	width: 50px;
	height: 50px;
	box-sizing: content-box;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
}

.keyvalue_accordion_item.true .close{
    background-color: #b58874;
    margin-right: 4px;
    margin-top: 15px;
    width: 50px;
	height: 50px;
	box-sizing: content-box;
}

.keyvalue_accordion_item .close span svg{
	vertical-align: middle;
    stroke: #b58874;
}

.keyvalue_accordion_item.true .close svg{
    stroke: #fff;
}

.keyvalue_accordion_item .keyvalue_accordion_header .close {
	font-weight: bold;
}

.open_cross {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: 500ms;
	-webkit-transform-origin: center center;
	        transform-origin: center center;
}

.close_cross {
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: 500ms;
	-webkit-transform-origin: center center;
	        transform-origin: center center;
}


@media screen and (max-width: 1100px) {
    .faqFacialSection-content {
        width: 700px;
    }
}

@media screen and (max-width: 768px) {
    .toggle>a {
        flex: 1 1;
    }

    #faqFacialSection {
        margin-bottom: 10px;
    }

	.faqFacialSection-content {
        width: 100%;
    }

    #faqFacialSection h2 {
        line-height: 1;
        font-size: 30px;
        text-align: center;
        width: 80%;
        margin: 16px 0;
    }

    .faqFacialSection-content .toggle div {
        padding: 0 20px 0 0;
    }

    .faqFacialSection-content .toggle div img{        
        display: none;
    }

    .faqFacialSection-content .toggle a {
        flex: 0 1;
    }

    .faqFacialSection-content .toggle a div {
        font-size: 14px;
        width: 100px;
        height: 50px;
        padding: 12px 5px 8px;
        margin-right: 2px;
        text-align: center;
    }

    .faqFacialSection-content .toggle a:not(:nth-child(2n)) div {
        width: 80px;
    }

    .facialTab,
    .youthTab,
    .eyeTab {
        border: 7px solid #b58874;
        border-bottom: 22px solid #b58874;
        padding: 0;
    }

    .keyvalue_accordion_header h4 {
        font-size: 14px;
    }

    .keyvalue_accordion_header h4 span, 
    .keyvalue_accordion_content_left h4 span {
        font-size: 19px;
    }

    .keyvalue_accordion_content_left h4 {
        font-size: 14px;
    }

    .keyvalue_accordion_content_left p {
        padding: 0;
        font-size: 12px;
    }

    .keyvalue_accordion_item .close {
        width: 38px;
        height: 38px;
        min-width: 38px;
        margin-left: 10px;
    }

    .keyvalue_accordion_item.true .close {
        width: 38px;
        height: 38px;
        min-width: 38px;
        margin-left: 10px;
    }

    .keyvalue_accordion_inner {
        border-bottom: 7px solid #b58874;
        padding: 3px 28px;
    }    

    .arrowButton {
        width: 16px;
        height: 16px;
    }
    
	
	
	
}

#facial {
    font-family: 'Montserrat', sans-serif;
}

.facial-care-btn {
    background-color: #b58874;
}

.facial-care-btn:hover {-webkit-filter: brightness(0.85);filter: brightness(0.85);}





































/*   HEADER SECTION    */
.Facial {
    height: 21.875vw;
    min-height: 420px;
    background-color: #e9e4e2;
    padding-top: 65px;
    position: relative;
}

#facial h1 {
    margin-bottom: 8px;
}

#facial h1 svg {
    width: 200px;
    margin-top: 23px;
}

#facial .background-video {
	width: auto;
    left: 50%;
    z-index: 5;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    margin: 0;
}
#facial .background-video video {
    width: auto;
    height: 100%;
    position: relative;
    min-height: 420px;
    max-width: none;
    background-color: #000;
}

.FacialInner {
    position: relative;
    text-align: center;    
    z-index: 10;
}

.FacialInnerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -45px;
}

.Facial nav {
    position: absolute;
    bottom: 0;
    z-index: 10;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}

.Facial nav span {
    display: inline-block;
    width: 100%;
    line-height: 1;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'PT Serif', serif;
    margin-bottom: 100px;
    color: #323e48;
}

.FacialInnerContent .underH1 {
    color: #323e48;
    max-width: 450px;
    font-size: 14px;
}

.FacialInnerContent .underH1:after {
    content: "";
    width: 45px;
    height: 1px;
    background-color: #323e48;
    position: absolute;
    bottom: -8px;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}
.Facial nav a figure {
    width: 60px;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
}

.Facial nav a figure img {
    width: auto;
    height: 100%;
    max-width: none;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}

.Facial nav a.link-nofacial {
    left: calc(50% - 20px);
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
    background-color: #f39571;
}

.Facial nav a.link-glowskin {
    left: calc(50% + 20px);
    background-color: #b58874;
}

.Facial nav a h2 {
    font-size: 22px;
    white-space: nowrap;
    color: #fff;
    margin: 0;
    line-height: 40px;
    font-family: 'PT Serif', serif;
}

.Facial nav a {
    position: absolute;
    bottom: 0;
    min-width: 325px;
    line-height: 1;
    font-size: 14px;
    color: #fff;
    text-align: left;
    padding: 2px 10px 14px 85px;
}


@media screen and ( max-width: 768px ) {
    .Facial {
        height: auto;
        min-height: 525px;
        max-height: none;
        padding: 0;
        position: relative;
    }

    .Facial h1 {
        margin: 233px auto 0!important;
    }

    #facial h1 svg {
        margin-top: -15px;
        width: 174px;
    }

    .FacialInnerContent {
        margin-top: 0;
    }

    .Facial nav {
        position: static;
        -webkit-transform: translate(0);
                transform: translate(0);
        display: inline-block;
        width: 100%;
        font-size: 0;
        padding-bottom: 70px;
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
    }
    
    .Facial nav span {
        font-size: 14px;
        margin-bottom: 14px;
        text-align: center;        
    }
    
    
    .Facial nav a figure {
        width: 50px;
        height: 100%;
        overflow: hidden;
    }
    
    .Facial nav a figure img {
        float: right;
        width: auto;
        height: 100%;
        max-width: none;
    }
    
    .Facial nav a.link-nofacial {
        left: 50%;
        background-color: #f39571;
        height: 60px;
    }
    
    .Facial nav a.link-glowskin {
        left: 50%;
        background-color: #b58874;
    }
    
    .Facial nav a h2 {
        font-size: 14px;
        margin-bottom: 4px;
        white-space: normal;
        line-height: 1;
        max-width: 90%;
    }
    
    .Facial nav a {
        left: 50%;
        vertical-align: top;
        width: 50%;
        min-width: auto;
        font-size: 8px;
        font-weight: 500;
        padding: 10px 0 10px 56px;
        margin: 0;
    }

    figcaption {
        font-size: 16px;
        text-align: center;
        margin-bottom: 5px;
        position: relative;
        z-index: 8;
        left: auto;
        right: auto;
        top: auto;
        line-height: 1;
        font-weight: 700;
        font-family: 'PT Serif', serif;
    }

    .brand-muse-nofacial figcaption {
        color: #f39571;
    }


    .brand-muse-glowskin figcaption {
        color: #b58874;
    }

    .brand-muse-nofacial {
        float: left;
        width: 50%;
        height: 200px;
        padding-top: 10px;
        margin-bottom: 20px;
        position: relative;
        right: auto;
        left: 0;
        overflow: hidden;
        top: 0;
        margin: 0;
        position: absolute;
    }

    .brand-muse-glowskin {
        float: right;
        width: 50%;
        height: 200px;
        padding-top: 10px;
        margin-bottom: 20px;
        position: relative;
        right: auto;
        right: 0;
        overflow: hidden;
        top: 0;
        margin: 0;
        position: absolute;
    }

    .FacialInnerContent .underH1 {
        font-size: 11px;
        max-width: 94%;
    }

    .FacialInnerContent .underH1:after {
        bottom: -13px;
    }
}




















































/*   NO FLAWS BANNER  */
.no-flaws-banner {
    background-color: #fdece7;
    padding: 128px 0 85px;
    margin-bottom: 30px;
    margin-top: 70px;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 17px;
    text-align: center;
    position: relative;
    max-width: 100%;
    height: auto;
    overflow: hidden;
}

.no-flaws-banner figure {
    overflow: hidden;
    margin: 0;
}

.no-flaws-banner .collection-poster {
    width: 240px;
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
    position: absolute;
    top: 70px;
    left: calc(50% + 220px);
}

.no-flaws-banner .collection-eye-massager {
    width: 45px;
    -webkit-transform: rotate(-96deg);
            transform: rotate(-96deg);
    position: absolute;
    top: -70px;
    left: calc(50% + 270px);
}

.no-flaws-banner .collection-brush {
    width: 125px;
    -webkit-transform: rotate(-11deg);
            transform: rotate(-11deg);
    position: absolute;
    top: 100px;
    right: calc(50% + 300px);
}

.no-flaws-banner h2 {
    position: relative;
    color: #f39571;
    margin: 0 auto 15px;
    max-width: 450px;
    font-size: 45px;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    overflow-wrap: break-word;
    line-height: 1;
}

.no-flaws-banner p {
    margin-bottom: 25px;
}

.no-flaws-banner .facial-care-btn{
    background-color: #f39571;
}

@media screen and (max-width: 768px) {
    .no-flaws-banner {
        padding: 65px 60px 40px;
        margin-bottom: 9px;
        margin-top: 0;
    }

    .no-flaws-banner .collection-poster {
        width: 170px;
        position: absolute;
        top: 45px;
        left: calc(50% + 50px);
    }
    
    .no-flaws-banner .collection-eye-massager {
        width: 30px;
        position: absolute;
        top: -52px;
        left: calc(50% + 60px);
    }
    
    .no-flaws-banner .collection-brush {
        width: 83px;
        position: absolute;
        top: 60px;
        right: calc(50% + 86px);
    }
    
    .no-flaws-banner h2 {
        max-width: 200px;
        margin-bottom: 10px;
        line-height: 1;
        font-size: 30px;
    }

    .no-flaws-banner figure img {
        z-index: 1;
    }

    .no-flaws-banner h2,
    .no-flaws-banner p,
    .no-flaws-banner .facial-care-btn {
        z-index: 3;
        position: relative;
    }

    .no-flaws-banner p {
        font-size: 12px;
        margin-bottom: 14px;
    }

    .no-flaws-banner .facial-care-btn {
        font-size: 12px;
        width: 86%;
        margin: 0 auto;
    }
}


/*   LIFT CONTOUR BANNER  */
.lift-banner {
    background-color: #fff0de;
    padding: 128px 0 85px;
    margin-bottom: 30px;
    margin-top: 30px;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 17px;
    text-align: center;
    position: relative;
    max-width: 100%;
    height: auto;
    overflow: hidden;
}

.lift-banner figure {
    overflow: hidden;
    margin: 0;
}

.lift-banner .lift-poster {
    width: 240px;
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
    position: absolute;
    top: 70px;
    left: calc(50% + 220px);
}

.lift-banner .lift-eye-massager {
    width: 307px;
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
    position: absolute;
    left: calc(50vw + 106px);
    top: -80px;
}

.lift-banner .lift-brush {
    width: 125px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    position: absolute;
    right: calc(50vw + 290px);
    top: 60px;
}

.lift-banner h2 {
    position: relative;
    color: #dbb68c;
    margin: 0 auto 15px;
    max-width: 450px;
    font-size: 45px;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    overflow-wrap: break-word;
    line-height: 1;
}

.lift-banner p {
    margin-bottom: 25px;
}

.lift-banner .facial-care-btn{
    background-color: #dbb68c;
}

@media screen and (max-width: 768px) {
    .lift-banner {
        padding: 65px 60px 40px;
        margin-bottom: 9px;
        margin-top: 0;
    }

    .lift-banner .lift-poster {
        width: 170px;
        position: absolute;
        top: 45px;
        left: calc(50% + 50px);
    }
    
    .lift-banner .lift-eye-massager {
        width: 30px;
        position: absolute;
        top: -52px;
        left: calc(50% + 60px);
    }
    
    .lift-banner .lift-brush {
        width: 83px;
        position: absolute;
        top: 60px;
        right: calc(50% + 86px);
    }
    
    .lift-banner h2 {
        max-width: 200px;
        margin-bottom: 10px;
        line-height: 1;
        font-size: 30px;
    }

    .lift-banner figure img {
        z-index: 1;
    }

    .lift-banner h2,
    .lift-banner p,
    .lift-banner .facial-care-btn {
        z-index: 3;
        position: relative;
    }

    .lift-banner p {
        font-size: 12px;
        margin-bottom: 14px;
    }

    .lift-banner .facial-care-btn {
        font-size: 12px;
        width: 86%;
        margin: 0 auto;
    }
}








#glow {
  font-family: "Montserrat", sans-serif;
}

/* HEADER */
.Glow {
  height: 21.875vw;
  min-height: 420px;
  background-color: #e9e4e2;
  padding-top: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#glow .glow-h1 {
  margin-bottom: 8px;
}
#glow .glow-h1 svg {
  width: 200px;
  margin-top: 23px;
}

.GlowInner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
}
.GlowInnerContent {
  margin-top: -45px;
}

.GlowInnerContent .glow-underH1 {
  color: #323e48;
  max-width: 450px;
  font-size: 14px;
  position: relative;
  padding-bottom: 22px;
}

.GlowInnerContent .glow-underH1:after {
  content: "";
  width: 45px;
  height: 1px;
  background-color: #323e48;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.GlowInnerContent .choose {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "PT Serif", serif;
  color: #323e48;
  margin-top: 24px;
}

.muses-parent {
  display: flex;
  justify-content: space-between;
  max-height: 100%;
}
#glow .glow-brand-muse-noflaws,
#glow .glow-brand-muse-glowskin {
  display: flex;
  margin: 0;
}

#glow .glow-brand-muse-glowskin {
  justify-content: flex-end;
}

#glow .Glow figcaption {
  font-size: 1.6em;
  margin-top: 6vw;
  line-height: 1;
  font-weight: 700;
  font-family: "PT Serif", serif;
}

#glow .glow-brand-muse-noflaws figcaption {
  margin-left: -9vw;
  color: #f39571;
  z-index: 8;
}
#glow .glow-brand-muse-glowskin figcaption {
  margin-right: -14vw;
  color: #b58874;
  z-index: 8;
}

.Glow nav {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Glow nav a {
  display: flex;
  align-items: center;
  flex: 1 1;
  max-width: 350px;
}

.Glow nav a.glow-link-noflaws {
  background-color: #f39571;
  margin-right: 20px;
}
.Glow nav a.glow-link-glowskin {
  background-color: #b58874;
  margin-left: 20px;
}

.Glow nav a img {
  width: 60px;
  height: 65px;
}

.Glow nav a h2 {
  font-size: 22px;
  white-space: nowrap;
  color: #fff;
  margin: 0;
  font-family: "PT Serif", serif;
  line-height: 0.9;
  padding-left: 20px;
}

.Glow nav h2 span {
  font-size: 14px;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .Glow {
    height: auto;
  }
  .GlowInnerContent .glow-underH1 {
    max-width: 250px;
  }
  #glow .glow-brand-muse-noflaws,
  #glow .glow-brand-muse-glowskin {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  #glow .Glow figcaption {
    margin: 1em 0 0 !important;
  }

  #glow .muses-parent img {
    order: 1;
  }
}

@media screen and (max-width: 1100px) {
  .navigation-wrapper a {
    margin: 0 2px;
    height: 70px;
  }
  .navigation-wrapper a h2 {
    font-size: 18px;
    white-space: normal;
  }
  .navigation-wrapper a h2 span {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .navigation-wrapper a img {
    display: none;
  }
  .navigation-wrapper .nav-wrapper {
    justify-content: center;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .navigation-block {
    position: absolute;
    bottom: 0;
  }
  .navigation-wrapper a {
    margin: 0;
  }
  .navigation-wrapper a h2 {
    font-size: 16px;
    line-height: 1;
  }
  .navigation-wrapper a h2 span {
    font-size: 10px;
    line-height: 1.2;
    padding-top: 5px;
  }
  .Glow {
    display: flex;
    flex-direction: column;
  }
  .GlowInner {
    order: 1;
    position: relative;
    top: unset;
    left: unset;
    -webkit-transform: unset;
            transform: unset;
    margin-bottom: 80px;
  }
  .GlowInnerContent {
    margin-top: -28px;
  }
  #glow .glow-h1 svg {
    width: 180px;
  }
  #glow .Glow figcaption {
    font-size: 1em;
  }
  .GlowInnerContent .glow-underH1 {
    max-width: 90%;
    margin: 0 auto;
    font-size: 12px;
    padding-bottom: 18px;
  }
  .GlowInnerContent .choose {
    font-size: 14px;
    margin-top: 18px;
  }
  .Glow nav {
    order: 2;
    position: relative;
    margin-top: 10px;
  }
  .Glow nav a.glow-link-noflaws {
    margin: 0;
  }
  .Glow nav a.glow-link-glowskin {
    margin: 0;
  }
  .Glow nav a {
    max-height: 60px;
  }
  .Glow nav a h2 {
    font-size: 14px;
    white-space: normal;
    padding-left: 10px;
  }
  .Glow nav a h2 span {
    font-size: 9px;
  }
  .Glow nav a img {
    width: 56px;
    height: 60px;
  }
}

@media screen and (min-width: 1300px) {
  #glow .glow-brand-muse-noflaws figcaption {
    margin-left: -6vw;
  }
  #glow .glow-brand-muse-glowskin figcaption {
    margin-right: -11vw;
  }
}

@media screen and (min-width: 1500px) {
  #glow .glow-brand-muse-noflaws figcaption {
    margin-left: -5vw;
  }
  #glow .glow-brand-muse-glowskin figcaption {
    margin-right: -8vw;
  }
}

/* END OF HEADER  */

#glow .radiance_header {
  padding: 22px calc(50% - 460px) 102px;
}
#glow .carouselWrapper {
  margin-top: -75px;
}
#glow .faqFacialSection-content .eyeTab,
#glow .faqFacialSection-content .facialTab {
  border: 3px solid #b58874 !important;
}
#glow .faqFacialSection-content .keyvalue_accordion_header h4,
#glow .faqFacialSection-content .keyvalue_accordion_content_left h4 {
  color: #b58874 !important;
}
#glow .faqFacialSection-content .keyvalue_accordion_item .close {
  color: #b58874 !important;
  border: 1px solid #b58874 !important;
  background-color: #fff !important;
}
#glow .faqFacialSection-content .keyvalue_accordion_item.true .close {
  background-color: #b58874 !important;
  color: #fff !important;
  border: 1px solid #b58874 !important;
}

#glow .faqFacialSection-content .keyvalue_accordion_inner {
  border-bottom: 1px solid #b58874 !important;
}

@media screen and (max-width: 768px) {
  #glow .radiance_header {
    padding: 24px 26px 1px;
  }
  #glow .carouselWrapper {
    margin-top: 0;
  }
  #glow .radiance-non-slide {
    border: solid #b58874;
    border-width: 7px 7px 3.5px;
    padding: 0;
  }
  #glow .faqFacialSection-content .toggle div img {
    height: 48px;
    display: block;
    margin-right: 2px;
  }
  #glow .faqFacialSection-content .toggle a {
    flex: 1 1;
  }
  #glow .faqFacialSection-content .toggle a div {
    width: auto;
    height: unset;
    padding: 0 4px 0 0;
    font-size: 13px;
  }
}

/*   NO FLAWS BANNER  */
.no-flaws-banner {
  background-color: #fdece7;
  padding: 128px 0 85px;
  margin-bottom: 30px;
  margin-top: 70px;
  color: #323e48;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 17px;
  text-align: center;
  position: relative;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.no-flaws-banner .figure {
  overflow: hidden;
  margin: 0;
  z-index: 1;
}

.no-flaws-banner .collection-poster {
  width: 240px;
  -webkit-transform: rotate(6deg);
          transform: rotate(6deg);
  position: absolute;
  top: 70px;
  left: calc(50% + 220px);
}

.no-flaws-banner .collection-eye-massager {
  width: 45px;
  -webkit-transform: rotate(-96deg);
          transform: rotate(-96deg);
  position: absolute;
  top: -70px;
  left: calc(50% + 270px);
}

.no-flaws-banner .collection-brush {
  width: 125px;
  -webkit-transform: rotate(-11deg);
          transform: rotate(-11deg);
  position: absolute;
  top: 100px;
  right: calc(50% + 300px);
}

.no-flaws-banner h2 {
  position: relative;
  color: #f39571;
  margin: 0 auto 15px;
  max-width: 450px;
  font-size: 45px;
  font-family: "PT Serif", serif;
  font-weight: 700;
  overflow-wrap: break-word;
  line-height: 1;
}

.no-flaws-banner p {
  margin-bottom: 25px;
}

.no-flaws-banner .facial-care-btn {
  background-color: #f39571;
}

@media screen and (max-width: 768px) {
  .no-flaws-banner {
    padding: 65px 60px 40px;
    margin-bottom: 9px;
    margin-top: 0;
  }

  .no-flaws-banner .collection-poster {
    width: 170px;
    position: absolute;
    top: 45px;
    left: calc(50% + 50px);
  }

  .no-flaws-banner .collection-eye-massager {
    width: 30px;
    position: absolute;
    top: -52px;
    left: calc(50% + 60px);
  }

  .no-flaws-banner .collection-brush {
    width: 83px;
    position: absolute;
    top: 60px;
    right: calc(50% + 86px);
  }

  .no-flaws-banner h2 {
    max-width: 200px;
    margin-bottom: 10px;
    line-height: 1;
    font-size: 30px;
  }

  .no-flaws-banner .figure img {
    z-index: 1;
  }

  .no-flaws-banner h2,
  .no-flaws-banner p,
  .no-flaws-banner .facial-care-btn {
    z-index: 3;
    position: relative;
  }

  .no-flaws-banner p {
    font-size: 12px;
    margin-bottom: 14px;
  }

  .no-flaws-banner .facial-care-btn {
    font-size: 12px;
    width: 86%;
    margin: 0 auto;
  }
}

#glow .lcm-header {
  background-color: #b58874;
  color: #fff;
  padding-bottom: 8px;
  padding-top: 15px;
  text-align: center;
}

#glow .buyOnlineButton {
  background-color: #b58874;
  width: auto;
  line-height: 40px;
  height: 40px;
  padding: 0 45px;
}

#glow h2,
#glow h3 {
  font-family: "PT Serif", serif;
}

#glow article {
  position: relative;
}

#glow .lcm-header h2 {
  font-size: 45px;
  margin-top: 0;
  margin-bottom: 15px;
}

#glow .lcm-header p {
  font-weight: 100;
}

#glow .lcm-upper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0;
}

#glow article h3 {
  color: #b58874;
  font-size: 35px;
  grid-column: 2;
  text-align: center;
}

#glow .lcm-main {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

#glow .button-container {
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0px 50px;
}

#glow .lcm-image-container {
  text-align: right;
  position: relative;
  right: 10vw;
}
#glow .lcm-image-container img {
  width: 110px;
}

#glow .main-image-container {
  width: 100%;
  cursor: pointer;
  opacity: 0.8;
  max-width: 700px;
  margin: 0 auto;
}

#glow .main-image-container:hover {
  opacity: 1;
}

#glow .main-image-container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 40%;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 18px 0 18px 26px;
  z-index: 5;
  -webkit-transform: translate(-35%, -50%);
          transform: translate(-35%, -50%);
}

#glow .main-image-container::after {
  background-color: #b58874;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 3;
  content: "";
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#glow .main-image-container:hover::after {
  background-color: #b58874;
}

#glow .video-thumbnail {
  width: 100%;
}
.lcm-video-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0;
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
}

.lcm-video {
  border: 0;
  border-radius: 5px;
}

#glow .divider {
  width: 100%;
  height: 16px;
  background-color: #b58874;
}

#glow .liftComfort-image-container a {
  color: black;
}

@media screen and (max-width: 600px) {
  #glow .lcm-header h2 {
    font-size: 32px;
    line-height: 1;
  }
  #glow .lcm-header p {
    font-size: 14px;
  }
  #glow .lcm-main {
    grid-template-columns: 1fr !important;
    padding: 0 4%;
    display: block;
  }
  #glow .lcm-image-container img {
    width: 62px;
  }
  #glow article h3 {
    font-size: 30px;
    margin: 18px 0;
  }
  #glow .buyOnlineButton {
    width: 90%;
    font-size: 12px;
    margin: 12px auto;
  }

  #glow .main-image-container::before {
    top: 38%!important;
    border-width: 11px 0 11px 19px;
  }
  #glow .main-image-container::after {
    top: 38%!important;
    width: 50px;
    height: 50px;
  }

  #glow .button-container {
    margin: 10px 0 30px;
    width: 100%;
  }
  #glow .divider {
    height: 12px;
  }
  /* .lcm-video-container {
    top: -75px;
  } */
  #glow .liftComfort-image-container {
    height: 470px;
    /* width: calc(50% - 5px); */
    position: relative;
    overflow: hidden;
  }
  #glow .liftComfort-image-container img {
    height: 400px;
    max-width: none;
    position: absolute;
    left: 0%;
    overflow: hidden;
  }
  #glow .liftComfort-image-container figcaption {
    position: absolute;
    bottom: 27px;
    left: 10%;
  }
  #glow .caption-2 {
    bottom: 13px !important;
  }
  #glow .liftComfort-image-1 {
    width: 160% !important;
    left: -21% !important;
  }
  #glow .liftComfort-image-2 {
    width: 153% !important;
    left: -27% !important;
  }
}

@media screen and (max-width: 1200px) {
  #glow .lcm-main {
    grid-template-columns: 1fr 6fr 1fr;
    padding: 0 4%;
  }
  #glow .main-image-container::before {
    /* top: 53%; */
    border-width: 11px 0 11px 19px;
  }
  #glow .main-image-container::after {
    /* top: 53%; */
  }
  #glow .lcm-image-container {right: 3vw;  } 
 
}

@media screen and (max-width: 1000px) {
  #glow .lcm-image-container {right: 1vw;  } 
}

#lift {
  font-family: "Montserrat", sans-serif;
}

#lift button { outline: none; border: none;}

#lift .care-btn {
  background-color: #f39571;
  color: #fff;
  display: inline-block;
  width: auto;
  font-family: "Montserrat", sans-serif;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 45px;
  border: 0;
  border-radius: 4px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  z-index: 5;
}

#lift .care-btn:hover {
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
}

/*   HEADER SECTION    */
.lift {
  height: 21.875vw;
  min-height: 420px;
  background-color: #e9e4e2;
  padding-top: 65px;
  position: relative;
}

#lift h1 {
  margin-bottom: 8px;
}

#lift h1 svg {
  width: 200px;
  margin-top: 23px;
}

/* #lift .background-video {
  width: auto;
  left: 50%;
  z-index: 5;
  transform: translate(-50%);
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: 0;
}
#lift .background-video video {
  width: auto;
  height: 100%;
  position: relative;
  min-height: 420px;
  max-width: none;
  background-color: #000;
} */

.liftInner {
  position: relative;
  text-align: center;
  z-index: 10;
}

.liftInnerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -45px;
}

.collection-option-text {
  width: 100%;
  text-align: center;
  padding-top: 40px;
}

.lift nav {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.lift nav a {
  position: absolute;
  bottom: 0;
  min-width: 325px;
  line-height: 1;
  font-size: 14px;
  color: #fff;
  text-align: left;
  padding: 2px 10px 14px 85px;
}
.lift nav a figure {
  width: 60px;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

.lift nav a figure img {
  width: auto;
  height: 100%;
  max-width: none;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.lift nav a.link-noFlaws {
  left: calc(50% - 20px);
  -webkit-transform: translate(-100%);
          transform: translate(-100%);
  background-color: #f39571;
}

.lift nav a.link-glowskin {
  left: calc(50% + 20px);
  background-color: #b58874;
}

.lift nav a h2 {
  font-size: 22px;
  white-space: nowrap;
  color: #fff;
  margin: 0;
  line-height: 40px;
  font-family: "PT Serif", serif;
}

.liftInnerContent .underH1 {
  color: #323e48;
  max-width: 450px;
  font-size: 14px;
}

.liftInnerContent .underH1:after {
  content: "";
  width: 45px;
  height: 1px;
  background-color: #323e48;
  position: absolute;
  bottom: -8px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

@media screen and (max-width: 768px) {
  .lift {
    height: auto;
    min-height: 525px;
    max-height: none;
    padding: 0;
    position: relative;
  }

  .lift h1 {
    margin: 233px auto 0 !important;
  }

  #lift h1 svg {
    margin-top: -15px;
    width: 174px;
  }

  .liftInnerContent {
    margin-top: 0;
  }

  .lift nav {
    position: static;
    -webkit-transform: translate(0);
            transform: translate(0);
    display: inline-block;
    width: 100%;
    font-size: 0;
    padding-bottom: 70px;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
  }

  .lift nav span {
    font-size: 14px;
    margin-bottom: 14px;
    text-align: center;
  }

  .lift nav a figure {
    width: 50px;
    height: 100%;
    overflow: hidden;
  }

  .lift nav a figure img {
    float: right;
    width: auto;
    height: 100%;
    max-width: none;
  }

  .lift nav a.link-noFlaws {
    left: 50%;
    background-color: #f39571;
    height: 60px;
  }

  .lift nav a.link-glowskin {
    left: 50%;
    background-color: #b58874;
  }

  .lift nav a h2 {
    font-size: 14px;
    margin-bottom: 4px;
    white-space: normal;
    line-height: 1;
    max-width: 90%;
  }

  .lift nav a {
    left: 50%;
    vertical-align: top;
    width: 50%;
    min-width: auto;
    font-size: 8px;
    font-weight: 500;
    padding: 10px 0 10px 56px;
    margin: 0;
  }

  #lift figcaption {
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
    position: relative;
    z-index: 8;
    left: auto;
    right: auto;
    top: auto;
    line-height: 1;
    font-weight: 700;
    font-family: "PT Serif", serif;
  }

  #lift .brand-muse-noFlaws figcaption {
    color: #f39571;
  }

  #lift .brand-muse-glowskin figcaption {
    color: #b58874;
  }

  #lift .brand-muse-noFLaws {
    float: left;
    width: 50%;
    height: 200px;
    padding-top: 10px;
    margin-bottom: 20px;
    position: relative;
    right: auto;
    left: 0;
    overflow: hidden;
    top: 0;
    margin: 0;
    position: absolute;
  }

  #lift .brand-muse-glowskin {
    float: right;
    width: 50%;
    height: 200px;
    padding-top: 10px;
    margin-bottom: 20px;
    position: relative;
    right: auto;
    right: 0;
    overflow: hidden;
    top: 0;
    margin: 0;
    position: absolute;
  }

  .liftInnerContent .underH1 {
    font-size: 11px;
    max-width: 94%;
  }

  .liftInnerContent .underH1:after {
    bottom: -13px;
  }
}


/*  FLAWS BANNER */
#lift .no-flaws-banner { margin-bottom: 0; }

@media screen and (max-width: 768px) {
  #lift .no-flaws-banner { margin: 10px 0; }
  #lift .no-flaws-banner .care-btn {
    font-size: 15px!important;
    width: 86%!important;
    margin: 0 auto!important;
  }
  
}

/*   GLOW SKIN BANNER  */
#lift .glowskin-banner {
  background-color: #f0ece9;
  padding: 90px 0 80px;
  margin-bottom: 0;
  margin-top: 30px;
  color: #323e48;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 17px;
  text-align: center;
  position: relative;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

#lift .glowskin-banner .collection-poster {
  width: 240px;
  -webkit-transform: rotate(6deg);
          transform: rotate(6deg);
  position: absolute;
  top: 70px;
  left: calc(50% + 170px);
}

#lift .glowskin-banner .collection-eye-massager {
  width: 45px;
  -webkit-transform: rotate(-96deg);
          transform: rotate(-96deg);
  position: absolute;
  top: -70px;
  left: calc(50% + 220px);
}

#lift .glowskin-banner .collection-brush {
  width: 125px;
  -webkit-transform: rotate(-11deg);
          transform: rotate(-11deg);
  position: absolute;
  top: 100px;
  right: calc(50% + 250px);
}

#lift .glowskin-banner h2 {
  position: relative;
  color: #b58874;
  margin: 0 auto 15px;
  max-width: 450px;
  font-size: 45px;
  font-family: "PT Serif", serif;
  font-weight: 700;
  overflow-wrap: break-word;
  line-height: 1;
}

#lift .glowskin-banner p {
  margin-bottom: 25px;
}

#lift .glowskin-banner .care-btn {
  background-color: #b58874;
}

@media screen and (max-width: 768px) {
  #lift .glowskin-banner {
    padding: 65px 60px 40px;
    margin-bottom: 9px;
    margin-top: 0;
  }

  #lift .glowskin-banner .collection-poster {
    width: 170px;
    position: absolute;
    top: 45px;
    left: calc(50% + 50px);
  }

  #lift .glowskin-banner .collection-eye-massager {
    width: 30px;
    position: absolute;
    top: -52px;
    left: calc(50% + 60px);
  }

  #lift .glowskin-banner .collection-brush {
    width: 83px;
    position: absolute;
    top: 60px;
    right: calc(50% + 80px);
  }

  #lift .glowskin-banner h2 {
    max-width: 200px;
    margin-bottom: 10px;
    line-height: 1;
    font-size: 30px;
  }

  #lift .glowskin-banner figure img {
    z-index: 1;
  }

  #lift .glowskin-banner h2,
  #lift .glowskin-banner p,
  #lift .glowskin-banner .care-btn {
    z-index: 3;
    position: relative;
  }

  #lift .glowskin-banner p {
    font-size: 12px;
    margin-bottom: 14px;
  }

  #lift .glowskin-banner .care-btn {
    font-size: 15px!important;
    width: 86%!important;
    margin: 0 auto!important;
  }
}

@media screen and (max-width: 400px) {
  #lift .glowskin-banner {
    max-height: 260px;
  }
}
.lcm-header {
  background-color: #dbb68c;
  color: #fff;
  padding-bottom: 8px;
  padding-top: 15px;
  text-align: center;
}

#lift h2,
#lift h3 {
  font-family: "PT Serif", serif;
}

#lift article {
  position: relative;
}

.lcm-header h2 {
  font-size: 45px;
  margin-top: 0;
  margin-bottom: 15px;
}

.lcm-header p {
  font-weight: 100;
}

.lcm-upper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0;
  align-items: center;
}

#lift article h3 {
  color: #dbb68c;
  font-size: 35px;
  grid-column: 2;
  text-align: center;
}

.lcm-main {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

#lift .buttonContainer {
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0px 50px;
}

.lcm-image-container {
  text-align: right;
  position: absolute;
  right: 5vw;
  top: 1vw;
}
.lcm-image-container img {
  width: 16vw;
  max-width: 200px;
}

#lift .main-image-container {
  width: 100%;
  cursor: pointer;
  opacity: 0.8;
  max-width: 700px;
  margin: 0 auto;
}

#lift .main-image-container:hover {
  opacity: 1;
}

#lift .main-image-container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 42%;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 18px 0 18px 26px;
  z-index: 5;
  -webkit-transform: translate(-35%, -50%);
          transform: translate(-35%, -50%);
}

#lift .main-image-container::after {
  background-color: #dbb68c;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 3;
  content: "";
  position: absolute;
  left: 50%;
  top: 42%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#lift .main-image-container:hover::after {
  background-color: #ac7f68;
}

#lift .video-thumbnail {
  width: 100%;
}
.lcm-video-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0;
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
}

.lcm-video {
  border: 0;
  border-radius: 5px;
}

#lift .divider {
  width: 100%;
  height: 16px;
  background-color: #dbb68c;
}

#lift .liftComfort-image-container a {
  color: black;
  display: block;
}



@media screen and (max-width: 1200px) {
  .lcm-main {
    grid-template-columns: 1fr 6fr 1fr;
    padding: 0 4%;
  }
  #lift .main-image-container::before {
    /* top: 53%; */
    border-width: 11px 0 11px 19px;
  }
  #lift .main-image-container::after {
    /* top: 53%; */
  }
  .lcm-image-container {right: 3vw;  } 
  .lcm-image-container img {
    width: 13vw;
  }
}

@media screen and (max-width: 1000px) {
  .lcm-image-container {right: 1vw;  } 
  
}

@media screen and (max-width: 600px) {
  .negativeMargin.buttonContainer {margin-left: -15px!important;margin-right: -15px!important;}

  .lcm-header h2 {
    font-size: 32px;
    line-height: 1;
  }
  .lcm-header p {
    font-size: 14px;
  }
  .lcm-main {
    grid-template-columns: 1fr !important;
    padding: 0 4%;
  }
  .lcm-image-container {
    position: relative;
  }
  .lcm-image-container img {
    width: 28vw;
    max-width: 145px
  }

  #lift article h3 {
    font-size: 30px;
    margin: 0;
  }

  #lift .main-image-container::before {
    top: 44%;
    border-width: 11px 0 11px 19px;
  }
  #lift .main-image-container::after {
    top: 44%;
    width: 50px;
    height: 50px;
  }

  #lift .buttonContainer {
    margin: 10px 0 30px;
  }
  #lift .divider {
    height: 12px;
  }
 
  #lift .liftComfort-image-container {
    height: 470px;
    position: relative;
    overflow: hidden;
  }
  #lift .liftComfort-image-container img {
    height: 400px;
    max-width: none;
    position: absolute;
    left: 0%;
    overflow: hidden;
  }
  #lift .liftComfort-image-container figcaption {
    position: absolute;
    bottom: 27px;
    left: 10%;
  }
  #lift .caption-2 {
    bottom: 13px !important;
  }
  .liftComfort-image-1 {
    width: 160% !important;
    left: -21% !important;
  }
  .liftComfort-image-2 {
    width: 153% !important;
    left: -27% !important;
  }
}

@media screen and (min-width: 1600px) {
  .lcm-image-container {
    right: 13vw;
  }
}

@media screen and (min-width: 1800px) {
  .lcm-image-container {
    right: 17vw;
  }
}

.internal-link {
  cursor: pointer;
}

#lift .carouselWrapper {
  position: relative;
  width: 920px;
  margin: -100px auto 0;
}

#lift .slider {
  height: auto;
}

#lift .discover-slide {
  width: 920px;
  max-width: 920px;
  background-color: #fff;
  color: #323e48;
}

#lift .discover-slide-header {
  height: 90px;
  padding-top: 24px;
}

#antiblemish.lift-page-discover {
  position: relative;
  padding-top: 6vw;
}

#lift .clinicallyProven-image-container {
  position: absolute;
  z-index: 1;
  left: unset;
  top: 20px;
  text-align: right;
  margin: 0;
  right: 5vw;
}
#lift .clinicallyProven-image-container img {
  width: 16vw;
  max-width: 200px;
}
#lift .discover-slide h3 {
  font-size: 35px;
  margin: 5px 0 0;
  color: #dbb68c;
  font-family: "PT Serif", serif;
  font-weight: 700;
  line-height: 1;
}

#lift .discover-slide1-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.liftComfort-image-container figcaption {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 900;
}

#lift .buttonContainer {
  display: flex;
  justify-content: center;
}

#lift .footnote {
  font-size: 12px;
  font-family: "Montserrat";
  padding: 55px 0 0 0;
}

.liftComfort-image {
  height: auto;
  width: 280px;
}

.liftComfort-image1 {
  margin-right: 18px;
}

.liftComfort-image2 {
  margin-left: 18px;
}

#lift .discover-slide-header {
  color: #dbb68c;
}
#lift .discover-slide-header p {
  font-weight: 100;
  margin-top: 5px;
  font-size: 15px;
}

#lift .discover-slide2-inner {
  display: flex;
  flex-direction: row;
  margin: 35px 0 50px 0;
}

#lift .slide2-inner-section {
  width: 50%;
  height: auto;
}

.lift-slide2-subtitle {
  text-transform: uppercase;
}

#lift .slide2-section2 {
  text-align: left;
  padding: 90px 0 0 50px;
  font-size: 15px;
  font-weight: 500;
}

#lift .slide2-section2 span {
  padding-left: 3px;
}

#lift .slide2-section2 strong {
  color: #dbb68c;
}
#lift .slide2-section2 h4 {
  margin: 0;
  color: #dbb68c;
  text-align: center;
}

#lift .slide3-inner-section {
  width: 100%;
  position: relative;
  height: auto;
  padding-left: 60px;
}

#lift .slide3-image-container {
  width: 250px;
}
#lift .slide3-inner-section figcaption {
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  padding-left: 42%;
  width: 76%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#lift .slide3-inner-section figcaption ul {
  height: 60px;
}

#lift .slide3-inner-section figcaption .more-button {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 38px !important;
  height: 38px !important;
  max-width: 38px !important;
  max-height: 38px !important;
  background-color: #fff;
  color: #dbb68c;
  text-align: center;
  border: 1px solid #dbb68c;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-button::after {
  background-color: #dbb68c;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  top: 8px;
  width: 1px;
  height: 20px;
  -webkit-transform-origin: center;
          transform-origin: center;
}

#lift .more-button::before {
  background-color: #dbb68c;
  /* transform: rotate(90deg); */
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  top: 8px;
  width: 1px;
  height: 20px;
  -webkit-transform-origin: center;
          transform-origin: center;
}

#lift .slide3-inner-section figcaption .more-button.true {
  background-color: #dbb68c;
}

#lift .more-button.true::before {
  background-color: white;
}
#lift .more-button.true::after {
  background-color: white;
}

#lift .discover-descripton {
  color: #dbb68c;
  font-size: 15px;
  font-weight: 600;
}

#lift .discover-descripton li:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #dbb68c;
  border-radius: 50%;
  margin-right: 5px;
}

#lift .discover-descripton li {
  color: black;
  margin: 5px 0;
  font-weight: 400;
  font-size: 14px;
}

#lift .disclaimer {
  font-size: 10px;
  margin-top: 0;
  text-align: center;
}
#lift .disclaimer sup {
  color: #dbb68c;
  font-weight: 800;
}

#lift .circle {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: none;
  border-radius: 50%;
  margin: 5px;
  background: #fff;
  border: 1px solid #dbb68c;
}

#lift .circle.current {
  background: #dbb68c;
}
#lift .more-button-1 {
  top: 40px !important;
  left: 30px !important;
}

#lift .more-button-2 {
  top: 100px !important;
  left: 177px !important;
}
#lift .more-button-3 {
  top: 142px !important;
  left: 173px !important;
}
#lift .more-button-4 {
  top: 184px !important;
  left: 171px !important;
}
#lift .more-button-5 {
  top: 226px !important;
  left: 167px !important;
}
#lift .more-button-6 {
  top: 360px !important;
  left: 15px !important;
}
#lift .more-button-7 {
  top: 335px !important;
  left: 310px !important;
}

@media screen and (max-width: 1800px) {
  #antiblemish.lift-page-discover {
    padding-top: 8vw;
  }
}

@media screen and (max-width: 1400px) {
  #antiblemish.lift-page-discover {
    padding-top: 10vw;
  }
}

@media screen and (max-width: 1200px) {
  #lift .clinicallyProven-image-container {
    right: 3vw;
  }
  #lift .clinicallyProven-image-container img {
    width: 13vw;
  }
  #antiblemish.lift-page-discover {
    padding-top: 11vw;
  }
}

@media screen and (max-width: 1100px) {
  #antiblemish.lift-page-discover .slider-control-centerright {
    right: -45px !important;
  }
  #antiblemish.lift-page-discover .slider-control-centerleft {
    left: -45px !important;
  }
}

@media screen and (max-width: 1000px) {
  #lift .clinicallyProven-image-container {
    right: 1vw;
  }
}

@media screen and (max-width: 980px) {
  #lift .carouselWrapper {
    width: 100%;
  }
  #lift .discover-slide {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  #lift .carouselWrapper {
    width: 100%;
  }
  #antiblemish.lift-page-discover {
    padding-top: 3vw;
  }
}

@media screen and (max-width: 600px) {
  #lift .discover-slide {
    width: 100% !important;
  }
  #lift .discover-slide-header {
    height: auto !important;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    padding-top: 5px !important;
  }
  #lift .discover-slide h3 {
    font-size: 28px !important;
    margin: 35px 0 15px;
  }
  #lift .discover-slide-header p {
    font-size: 11px;
    margin-top: -10px;
  }
  .clinicallyProven-image-container-mobile {
    position: relative !important;
    right: 10px !important;
  }
  .clinicallyProven-image-container-mobile img {
    width: 28vw;
    max-width: 145px;
  }
  .liftComfort-image-container {
    margin: 0 !important;
  }
  #lift .discover-slide1-inner {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 10px !important;
    padding: 10px 10px !important;
  }
  #lift .discover-slide1-inner figcaption {
    font-size: 13px !important;
  }
  .buttonContainer {
    margin: 10px 0 20px !important;
  }
  .marginTop100.buttonContainer {
    margin-top: 110px !important;
  }
  #lift .slide2-section2 span {
    font-size: 13px;
    font-weight: 500;
  }

  #lift .discover-slide2-inner {
    flex-direction: column;
    margin: 10px 0 10px 0 !important;
  }

  #lift .slide2-inner-section-mobile {
    width: 100% !important;
    padding: 10px 20px !important;
  }
  .pronounced {
    padding-left: 34px !important;
  }
  #lift .slide3-image-container {
    width: 200px !important;
  }
  #lift .slide3-inner-section {
    display: flex;
    flex-direction: column;
    padding: 30px 0px 0px 70px !important;
    margin: 0 !important;
  }
  #lift .slide3-inner-section figcaption {
    position: relative;
    padding-left: 0;
    width: 100% !important;
  }
  #lift .discover-descripton {
    position: absolute !important;
    bottom: -130px !important;
    width: 100% !important;
    text-align: center !important;
    left: 0 !important;
  }

  #lift .slide3-inner-section figcaption h4 {
    margin-bottom: 10px;
  }

  #lift .slide3-inner-section figcaption ul {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }

  #lift .slide3-inner-section figcaption li {
    text-align: left;
    padding-left: 20px;
    font-size: 13px;
    font-family: "Montserrat", serif;
    font-weight: 100;
    line-height: 1.2em;
  }

  #lift .slide3-button {
    margin: 130px 0 30px !important;
  }
  #lift .more-button-1 {
    top: 47px !important;
    left: 35px !important;
  }
  #lift .more-button-2 {
    top: 100px !important;
    left: 170px !important;
  }
  #lift .more-button-3 {
    top: 140px !important;
    left: 166px !important;
  }
  #lift .more-button-4 {
    top: 180px !important;
    left: 162px !important;
  }
  #lift .more-button-5 {
    top: 220px !important;
    left: 158px !important;
  }
  #lift .more-button-6 {
    top: 330px !important;
    left: 25px !important;
  }
  #lift .more-button-7 {
    top: 315px !important;
    left: 275px !important;
  }
}

@media screen and (min-width: 1600px) {
  #lift .clinicallyProven-image-container {
    right: 13vw;
  }
}

@media screen and (min-width: 1800px) {
  #lift .clinicallyProven-image-container {
    right: 17vw;
  }
}

#antiblemish.FaceContour .slider-slide {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  height: 100% !important;
}

#antiblemish.FaceContour .slider-control-centerright {
  right: -70px !important;
}
#antiblemish.FaceContour .slider-control-centerleft {
  left: -70px !important;
}

#antiblemish.FaceContour {
  text-align: center;
  padding-bottom: 0;
  position: relative;
}
.FaceContour-header {
  width: 100%;
  background-color: #dbb68c;
  color: white;
  height: auto;
  text-align: center;
  font-family: "PT Serif", serif;
}

.FaceContour-header h2 {
  font-size: 45px;
  margin: 0;
}

.FaceContour-header p {
  font-weight: 100;
  margin-top: 10px;
}

.FaceContour-header-inner {
  padding: 15px 0;
}

#lift #antiblemish.FaceContour .clinicallyProven-image-container {
  top: 160px;
}

#lift #antiblemish.FaceContour .carouselWrapper {
  position: relative;
  width: 920px;
  margin: 0 auto;
}

#lift .slider {
  height: auto;
}

.FaceContour-slide {
  width: 920px;
  max-width: 920px;
  background-color: #fff;
  color: #323e48;
}

.FaceContourcover-slide-header {
  height: 90px;
  padding-top: 16px;
  margin: 0 0 25px 0;
}

.clinicallyProven-image-container {
  position: relative;
  z-index: 1;
  left: unset;
  top: 20px;
  text-align: right;
  margin: 0;
  right: 10vw;
}
.clinicallyProven-image-container img {
  width: 110px;
}
.FaceContour-slide h3 {
  font-size: 35px;
  margin: 5px 0 0;
  color: #dbb68c;
  font-family: "PT Serif", serif;
  font-weight: 700;
  text-align: center;
}

.FaceContour-slide1-inner {
  display: flex;
  flex-direction: row;
  margin: 60px 0 0;
}

#lift .buttonContainer {
  display: flex;
  justify-content: center;
}

.FaceContour-image {
  width: 100%;

  object-fit: cover;
}

.FaceContour-image-container {
  float: left;
  width: calc(45% - 20px);
  height: 385px;
  text-align: center;
  overflow: hidden;
}

.FaceContour-image-container.FaceContour-image1 {
  width: calc(55% - 20px);
  height: 400px;
}

#lift figcaption {
  font-family: "Montserrat", serif !important;
}

.FaceContour-mobile-image-container {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

.FaceContour-image1 {
  margin: 0;
}

.FaceContour-image2 {
  margin-top: 20px;
}

.FaceContour-product-image {
  display: inline-block;
  width: auto;
  height: 145px;
  max-height: 100%;
  max-width: none;
}

.FaceContour-image2 figcaption strong {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 55px;
  color: #dbb68c;
}
.FaceContour-image2 figcaption span {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 15px;
  text-align: left;
  padding-left: 8px;
}
.FaceContour-image2 figcaption p {
  font-weight: 500;
  font-size: 15px;
}

.FaceContour-slide-header {
  color: #dbb68c;
  padding-top: 24px;
}
.FaceContour-slide-header p {
  font-weight: 400;
  margin-top: 5px;
  font-size: 14px;
}

.FaceContour-slide2-inner {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  height: 450px;
  margin-top: 30px;
}

.FaceContour-slide2-inner figure {
  height: auto;
  margin: 0;
  overflow: hidden;
  position: relative;
  float: left;
}
.FaceContour-slide2-inner figure img {
  max-width: none !important;
  width: 170%;
  left: -53px;
  top: 0;
  position: relative;
  z-index: 9;
}

.FaceContour-slide2-inner figcaption {
  background-color: #fff;
  padding-top: 25px;
  padding-left: 35px;
  text-align: left;
  position: relative;
  z-index: 10;
  -webkit-transform: translateY(-58px);
          transform: translateY(-58px);
  font-weight: 300;
}

.FaceContour-slide2-inner figcaption strong {
  position: absolute;
  top: 10px;
  left: 2px;
  line-height: 1;
  font-size: 25px;
  color: #dbb68c;
}
.FaceContour-slide2-inner figcaption strong::after {
  content: " /";
  position: relative;
  top: 7.5px;
  font-size: 0.8em;
}

.FaceContour-slide2-inner figcaption span {
  font-size: 14px;
  font-weight: 500;
}

#lift .figure-image-two {
  width: 145% !important;
  left: -46px !important;
}

#lift .figcaption-two {
  -webkit-transform: translateY(-6px) !important;
          transform: translateY(-6px) !important;
}

#lift .figure-image-five {
  width: 145% !important;
  left: -40px !important;
}

#lift .figcaption-five {
  -webkit-transform: translateY(-6px) !important;
          transform: translateY(-6px) !important;
}

#lift #lift.slide2-inner-section {
  width: 50% !important;
  height: auto;
}

.FaceContour-slide2-subtitle {
  text-transform: uppercase;
}

.FaceContour-slide2-subtitle strong {
  margin: 3px 0;
}

#lift .slide2-section2 {
  text-align: left;
  padding: 90px 0 0 50px;
  font-size: 15px;
  font-weight: 100;
}

#lift .slide2-section2 strong {
  color: #dbb68c;
}
#lift .slide2-section2 h4 {
  margin: 0;
  color: #dbb68c;
  text-align: center;
}

#lift .slide3-inner-section {
  width: 100%;
  position: relative;
  height: auto;
  padding-left: 60px;
}

#lift .slide3-image-container {
  width: 250px;
}
#lift .slide3-inner-section figcaption {
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  padding-left: 40%;
  width: 76%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#lift .discover-descripton {
  color: #dbb68c;
  font-size: 15px;
  font-weight: 600;
}

#lift .discover-descripton li {
  color: black;
  margin: 5px 0;
  font-weight: 200;
}
#lift .circle {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: none;
  border-radius: 50%;
  margin: 5px;
  background: #fff;
  border: 1px solid #dbb68c;
}

#lift .circle.current {
  background: #dbb68c;
}

.FaceContour-mobile-slide2-inner div {
  padding: 5px;
}

.FaceContour-mobile-slide2-inner div figcaption {
  width: 100%;
}

.FaceContour-mobile-slide2-inner div figcaption strong {
  line-height: 1;
  font-size: 25px;
  color: #dbb68c;
  margin-right: 10px;
}
.FaceContour-mobile-slide2-inner {
  position: relative;
  height: 620px;
}
.FaceContour-mobile-slide2-inner div figcaption {
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
}
.FaceContour-mobile-slide2-inner div figcaption strong::after {
  content: " /";
  position: relative;
  top: 7.5px;
  font-size: 0.8em;
}

.FaceContour-mobile-slide2-inner div figcaption span {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  color: #323e48;
}

.FaceContour-mobile-slide2-inner div figcaption {
  padding: 28px 23px 0;
}

.FaceContour-mobile-slide2-inner .slider-control-bottomcenter {
  position: relative;
  bottom: 0 !important;
  display: flex;
  justify-content: center;
}
.faceContour-divider {
  margin: 30px 0 12px;
}

.FaceContour-footnote {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

@media screen and (max-width: 1400px) {
  #lift #antiblemish.FaceContour .clinicallyProven-image-container {
    right: 1vw;
  }
}

@media screen and (max-width: 1300px) {
  #lift #antiblemish.FaceContour .clinicallyProven-image-container {
    top: 140px;
    right: 3vw;
  }
  #lift #antiblemish.FaceContour .clinicallyProven-image-container img {
    width: 12vw;
  }
}

@media screen and (max-width: 1100px) {
  #antiblemish.FaceContour .slider-control-centerright {
    right: -45px !important;
  }
  #antiblemish.FaceContour .slider-control-centerleft {
    left: -45px !important;
  }
}

@media screen and (max-width: 1000px) {
  .clinicallyProven-image-container {
    right: 1vw;
  }
}

@media screen and (max-width: 600px) {
  .FaceContour-slide-header {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    padding-top: 0;
  }
  .FaceContour-header-inner {
    padding: 11px 0 9px;
  }
  .FaceContour-header h2 {
    font-size: 35px;
  }
  .FaceContour-slide-header h3 {
    font-size: 28px;
    margin: 0;
    padding: 14px 30px;
    line-height: 1;
    margin-top: 15px;
  }
  .FaceContour-header p {
    margin-top: 2px;
  }
  .FaceContour-slide-header p {
    font-size: 12px;
  }
  #lift #antiblemish.FaceContour .carouselWrapper {
    height: 830px;
    width: 100%;
    margin: -100px auto 60px;
  }

  .FaceContour-product-image {
    height: 80px;
    padding: 10px 0 10px 33px;
    margin-top: 15px;
  }
  .clinically-proven-FaceContour-moblie-one {
    padding-top: 6px;
  }

  .FaceContour-image1 figcaption strong {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-size: 55px;
    color: #dbb68c;
  }
  .FaceContour-image1 figcaption span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-size: 15px;
    text-align: left;
    padding-left: 8px;
  }
  .FaceContour-image1 figcaption p {
    font-weight: 500;
    font-size: 15px;
  }
}

@media screen and (max-width: 980px) {
  #lift .carouselWrapper {
    width: 100%;
  }
  .FaceContour-slide {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #lift #antiblemish.FaceContour {
    padding-bottom: 40px;
  }
  #lift .carouselWrapper {
    width: 100%;
    top: 100px !important;
  }
  #antiblemish.FaceContour .slider-control-centerright {
    right: 15px !important;
  }
  #antiblemish.FaceContour .slider-control-centerleft {
    left: 15px !important;
  }
  #antiblemish.FaceContour .slider-control-centerright .rightArrow {
    width: 18px !important;
  }
  #antiblemish.FaceContour .slider-control-centerleft .leftArrow {
    width: 18px !important;
  }
  
}

@media screen and (min-width: 1600px) {
  #lift #antiblemish.FaceContour .clinicallyProven-image-container {
    right: 10vw;
  }
}

@media screen and (min-width: 1800px) {
  #lift #antiblemish.FaceContour .clinicallyProven-image-container {
    right: 14vw;
  }
}

#EyeContour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0;
}

#EyeContour.EyeContour .slider-control-centerright {
  right: -70px !important;
}
#EyeContour.EyeContour .slider-control-centerleft {
  left: -70px !important;
}

.EyeContour-header {
  width: 100%;
  background-color: #dbb68c;
  color: white;
  height: auto;
  text-align: center;
}

.EyeContour-header h2 {
  font-size: 45px;
  margin: 0;
}

.EyeContour-header p {
  font-weight: 100;
  margin-top: 10px;
  font-family: "Montserrat", serif;
}

.EyeContour-header-inner {
  padding: 15px 0;
}

#lift .EyeContour .carouselWrapper {
  position: relative;
  width: 920px;
  margin: 20px auto 0;
}

#lift .slider {
  height: auto;
}

.EyeContour-slide {
  width: 920px;
  max-width: 920px;
  background-color: #fff;
  color: #323e48;
}

.EyeContour-slide-header {
  padding-top: 16px;
}

.clinicallyProven-image-container {
  position: relative;
  z-index: 1;
  left: unset;
  top: 20px;
  text-align: right;
  margin: 0;
  right: 10vw;
}
.clinicallyProven-image-container img {
  width: 110px;
}
.EyeContour-slide h3 {
  font-size: 35px;
  margin: 5px 0 0;
  color: #dbb68c;
  font-family: "PT Serif", serif;
  font-weight: 700;
}

.EyeContour-slide1-inner {
  display: flex;
  flex-direction: row;
  margin: 60px 0 0;
}

#lift .buttonContainer-slide1 {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
/* .footnote {
  float: left;
  width: 100%;
  height: 0;
  margin: 0;
  position: relative;
  top: 85px;
  font-size: 12px;
} */

.EyeContour-image-container {
  float: left;
  width: calc(45% - 20px);
  text-align: center;
  overflow: hidden;
  margin: 0;
}

.EyeContour-image-container.EyeContour-image1 {
  width: calc(55% - 20px);
}

.EyeContour-image {
  width: 100%;
  object-fit: cover;
}

.EyeContour-image2 {
  margin-top: 100px;
}

.EyeContour-product-image {
  display: inline-block;
  width: auto;
  height: 145px;
  max-height: 100%;
  max-width: none;
}

.EyeContour-image2 figcaption strong {
  line-height: 1;
  color: #dbb68c;
}
.EyeContour-image2 figcaption span {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 15px;
  text-align: left;
  padding-left: 8px;
}
.EyeContour-image2 figcaption p {
  font-weight: 500;
  font-size: 15px;
}

.EyeContour-slide-header {
  color: #dbb68c;
  padding-top: 24px;
  text-align: center;
}
.EyeContour-slide-header p {
  font-weight: 400;
  margin-top: 5px;
  font-size: 14px;
}

.EyeContour-slide2-inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  height: 425px;
  margin-top: 40px;
}

.EyeContour-slide2-inner figure {
  height: auto;
  margin: 0;
  overflow: hidden;
  position: relative;
  float: left;
}
.EyeContour-slide2-inner figure img {
  max-width: none !important;
  width: 170%;
  left: -78px;
  top: -32px;
  position: relative;
  z-index: 9;
}

.EyeContour-slide2-inner figcaption {
  background-color: #fff;
  padding-top: 25px;
  padding-left: 35px;
  text-align: left;
  position: relative;
  z-index: 10;
  height: 200px;
  -webkit-transform: translateY(-165px);
          transform: translateY(-165px);
  font-weight: 300;
}

.EyeContour-slide2-inner figcaption strong {
  position: absolute;
  top: 10px;
  left: 2px;
  line-height: 1;
  font-size: 25px;
  color: #dbb68c;
}
.EyeContour-slide2-inner figcaption strong::after {
  content: " /";
  position: relative;
  top: 7.5px;
  font-size: 0.8em;
}

.EyeContour-slide2-inner figcaption span {
  font-size: 14px;
  font-weight: 500;
}

.EyeContour-slide2-mobile-inner figure {
  margin: 0 12px !important;
}

.EyeContour-slide2-mobile-inner figcaption {
  display: grid;
  grid-template-columns: 1fr 9fr;
  margin: 0;
  padding: 33px 28px 0;
}

.EyeContour-slide2-mobile-inner figcaption strong {
  color: #dbb68c;
  line-height: 1;
  font-size: 25px;
  margin-right: 10px;
  white-space: nowrap;
}
.EyeContour-slide2-mobile-inner figcaption span {
  font-size: 13px;
  font-weight: 700;
  padding-top: 8px;
}

.EyeContour-slide2-mobile-inner .sliderArrows {
  color: white !important;
}
.EyeContour-slide2-mobile-inner figcaption strong::after {
  content: " /";
  position: relative;
  top: 7.5px;
  font-size: 0.8em;
}

#lift .slide2-figure-image-two {
  width: 112% !important;
  left: -16px !important;
  top: 0px !important;
}

#lift .slide2-figcaption-two {
  -webkit-transform: translateY(-10px) !important;
          transform: translateY(-10px) !important;
}

#lift .slide2-figure-image-three {
  width: 152% !important;
  left: -61px !important;
  top: -24px !important;
}

#lift .slide2-figcaption-three {
  -webkit-transform: translateY(-116px) !important;
          transform: translateY(-116px) !important;
}

#lift .slide2-inner-section {
  width: 50% !important;
  height: auto;
}

.EyeContour-slide2-subtitle {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400 !important;
  margin-top: 5px;
  padding: 0 30px !important;
}

.EyeContour-slide2-subtitle strong {
  margin: 3px 0;
}

#lift .slide2-section2 {
  text-align: left;
  padding: 90px 0 0 50px;
  font-size: 15px;
  font-weight: 100;
}

#lift .slide2-section2 strong {
  color: #dbb68c;
}
#lift .slide2-section2 h4 {
  margin: 0;
  color: #dbb68c;
  text-align: center;
}

#lift .slide3-inner-section {
  width: 100%;
  position: relative;
  height: auto;
  padding-left: 60px;
}

#lift .slide3-image-container {
  width: 250px;
}
#lift .slide3-inner-section figcaption {
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  padding-left: 40%;
  width: 76%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#lift .discover-descripton li {
  color: black;
  margin: 5px 0;
  font-weight: 200;
}
#lift .circle {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: none;
  border-radius: 50%;
  margin: 5px;
  background: #fff;
  border: 1px solid #dbb68c;
}

#lift .circle.current {
  background: #dbb68c;
}

.mobile-header-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EyeContour-slide2-mobile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 12px;
}

.EyeContour-slide2-mobile-header h3 {
  font-size: 28px;
  margin: 0;
  padding: 14px 30px;
  line-height: 1;
  color: #dbb68c;
}
.EyeContour-slide2-mobile-header p {
  font-size: 12px;
  color: #dbb68c;
  font-weight: 100;
  padding: 0 15px;
}

#lift .buttonContainer-slide2-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .EyeContour-slide-header {
    text-align: center;
  }
  #lift .EyeContour .carouselWrapper {
    width: 100%;
    top: 0 !important;
    margin-top: 8px;
    margin-bottom: 36px;
  }
  #lift .carouselWrapper {
    height: 590px;
  }
  .EyeContour-slide {
    width: 100% !important;
    padding-bottom: 22px;
  }
  .EyeContour-header h2 {
    font-size: 35px;
  }
  .EyeContour-header p {
    font-size: 15px;
  }
  .EyeContour-header-inner {
    padding: 10px 0;
  }
  .EyeContour-slide-header {
    height: auto !important;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding-top: 0 !important;
    padding-bottom: 0;
  }

  .EyeContour-slide-header img {
    max-height: 60px;
    width: auto;
    margin-left: 33px;
  }
  .EyeContour-slide-header h3 {
    font-size: 28px;
    margin: -2px 0 0;
    padding: 14px 30px;
    line-height: 1;
  }
  .EyeContour-slide1-inner {
    flex-direction: column;
    margin: 0;
  }
  .EyeContour-slide1-image-container {
    margin: 0%;
    padding: 0;
  }
  .EyeContour-image-container {
    margin: 0 10px;
    width: unset;
    margin-bottom: 10px !important;
  }
  .EyeContour-image2 {
    margin-top: 0;
  }
  .EyeContour-image2 figcaption p {
    font-size: 14px;
    margin-top: 18px;
  }

  .EyeContour-slide2-mobile-inner .slider-control-bottomcenter {
    position: relative;
    bottom: -50px !important;
    display: flex;
    justify-content: center;
  }
  #lift .buttonContainer-slide1 {
    margin-top: 0;
  }
  #lift .footnote {
    font-size: 11px;
    padding: 15px 0 15px 0;
    text-align: center;
  }
  .Eyecontour-slide1-inner {
    text-align: center;
  }
}

@media screen and (max-width: 980px) {
  #lift .carouselWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .clinicallyProven-image-container {
    right: 3vw;
  }
}

@media screen and (max-width: 1100px) {
  #EyeContour.EyeContour .slider-control-centerright {
    right: -45px !important;
  }
  #EyeContour.EyeContour .slider-control-centerleft {
    left: -45px !important;
  }
}

@media screen and (max-width: 1000px) {
  .clinicallyProven-image-container {
    right: 1vw;
  }
  .sliderArrows {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sliderArrows {
    display: block;
  }
  #lift .carouselWrapper {
    width: 100%;
  }
  #EyeContour {
    padding-bottom: 0;
  }
  #EyeContour.EyeContour .slider-control-centerright {
    right: 15px !important;
  }
  #EyeContour.EyeContour .slider-control-centerleft {
    left: 15px !important;
  }
  #EyeContour.EyeContour .slider-control-centerright .rightArrow {
    width: 18px !important;
  }
  #EyeContour.EyeContour .slider-control-centerleft .leftArrow {
    width: 18px !important;
  }
  #EyeContour.EyeContour .slider-control-centerleft {
    top: 41% !important;
    -webkit-transform: translateY(-41%) !important;
            transform: translateY(-41%) !important;
  }
  #EyeContour.EyeContour .slider-control-centerright {
    top: 41% !important;
    -webkit-transform: translateY(-41%) !important;
            transform: translateY(-41%) !important;
  }
}

.Tutorials-header {
  color: #dbb68c;
}

#antiblemish.tutorials-section .slider-control-centerright {
  right: -70px!important;
  top: 44%!important; 
  -webkit-transform: translateY(-44%); 
          transform: translateY(-44%);
}

#antiblemish.tutorials-section .slider-control-centerleft {
  left: -70px!important;
  top: 44%!important; 
  -webkit-transform: translateY(-44%); 
          transform: translateY(-44%);
}

.Tutorials-upper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 10px 0px;
  color: #dbb68c;
}
.Tutorials-upper h3 {
  font-size: 38px;
  margin-top: 20px;
  margin-bottom: 70px;
}

#lift .carouselWrapper {
  position: relative;
  width: 920px;
  margin: -100px auto 0;
}

#lift .slider {
  height: auto;
}

.Tutorials-slide {
  margin-top: 40px;
}
.Tutorials-slide-image-container {
  position: relative;
  width: 650px;
  height: 365px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 75px;
  cursor: pointer;
}
.Tutorials-slide-image-container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 18px 0 18px 26px;
  z-index: 5;
  -webkit-transform: translate(-35%, -50%);
          transform: translate(-35%, -50%);
}

.Tutorials-slide-image-container::after {
  background-color: #dbb68c;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 3;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Tutorials-slide-image-container:hover::after {
  background-color: #ac7f68;
}

.Tutorials-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  opacity: 0.7;
}

.Tutorials-image:hover {
  opacity: 1;
}
.Tutorials-slide-image-container figcaption {
  width: 100%;
  position: absolute;
  bottom: 55px;
  font-weight: 100;
  font-size: 15px;
}
.Tutorials-slide-image-container figcaption strong {
  display: block;
  margin-bottom: 2px;
}

#lift .buttonContainer {
  display: flex;
  justify-content: center;
}

.Tutorials-video-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0;
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
}

.Tutorials-video {
  border: 0;
  border-radius: 5px;
}

#lift .circle {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: none;
  border-radius: 50%;
  margin: 5px;
  background: #fff;
  border: 1px solid #dbb68c;
}

#lift .circle.current {
  background: #dbb68c;
}

#lift .divider {
  width: 100%;
  height: 16px;
  background-color: #dbb68c;
}
#lift .slide3-inner-section ul {
  height: 100px;
}

.tutorial-mobile-buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

@media screen and (max-width: 1100px){
  #antiblemish.tutorials-section .slider-control-centerright {right: -45px!important;}
  #antiblemish.tutorials-section .slider-control-centerleft {left: -45px!important;}
}

@media screen and (max-width: 980px) {
  #lift .carouselWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .Tutorials-upper {
    height: 84px;
    padding: 0;
  }
  .Tutorials-upper h3 {font-size: 28px;margin-bottom: 0;}
  #lift .carouselWrapper {   width: 100%;  }
  .tutorial-carousel { height: 540px !important;  }
  .Tutorials-slide {margin: 0;}

  .Tutorials-slide-image-container {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 10px;
  }
  .Tutorials-slide-image-container::before {
    top: 46%;
    border-width: 11px 0 11px 19px;
  }
  .Tutorials-slide-image-container::after {
    top: 46%;
    width: 50px;
    height: 50px;
  }
  .Tutorials-slide-image-container figcaption {
    bottom: 20px;
    margin: -50px 0 26px;
  }
  .Tutorials-image {opacity: 1;}
  /* .tutorial-carousel .slider-control-bottomcenter {bottom: 160px!important;} */
  #antiblemish.tutorials-section .slider-control-centerright {display: none;}
  #antiblemish.tutorials-section .slider-control-centerleft {display: none;}
}

#faqSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1px;
}

#faqSection h2 {
  margin-bottom: 15px;
  color: #dbb68c;
  font-size: 45px;
  font-family: "PT Serif", serif;
  font-weight: 700;
  overflow-wrap: break-word;
  margin: 47px 0 56px;
}

.divider {
  width: 100%;
  height: 16px;
  background-color: #dbb68c;
}

.faqSection-content {
  width: 920px;
}

.faqSection-content .toggle {
  display: flex;
}

.faqSection-content .toggle div {
  font-size: 21px;
  font-weight: 400;
  font-family: "PT Serif", serif;
  cursor: pointer;
  padding: 0px 40px 0 0;
  margin-right: 1px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faqSection-content .toggle div {
  background-color: #dbb68c;
  color: white;
}

.faqSection-content .toggle a div:hover,
.faqSection-content .toggle a.activetab div {
  background-color: #dbb68c;
  color: #f7f4f2;
}

.faqSection-content .toggle div img {
  margin-right: 20px;
}

.facialTab,
.eyeTab {
  width: 100%;
  text-align: left;
  border: 3px solid #dbb68c;
  padding: 0 40px 10px;
  margin: 0;
}

#keyvalue {
  width: 100%;
}

.keyvalue_accordion_inner {
  border-bottom: 1px solid #dbb68c;
  cursor: pointer;
  padding: 15px 0;
}

.keyvalue_accordion_inner:last-of-type {
  border-bottom: none!important;
}

.keyvalue_accordion_item button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.keyvalue_accordion_header h4 {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #dbb68c;
  transition: font-weight 0.3s ease;
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.keyvalue_accordion_content {
  display: flex;
  color: #323e48;
  font-size: 15px;
}

.keyvalue_accordion_header h4 span,
.keyvalue_accordion_content_left h4 span {
  line-height: 1;
  font-size: 30px;
  font-weight: 700;
  padding-right: 5px;
}

.keyvalue_accordion_content_left h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #dbb68c;
  font-size: 18px;
}

.keyvalue_accordion_content_left p {
  padding-left: 22px;
}

.keyvalue_accordion_content_left ul {
  list-style: disc;
  padding-left: 38px;
}

.keyvalue_accordion_content_left p.footnote {
  font-size: 11px;
  font-weight: 700;
}

.keyvalue_accordion_content p {
  line-height: 1.5;
}

.keyvalue_accordion_content a {
  color: #dbb68c;
}

.keyvalue_accordion_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.keyvalue_accordion_content_right {
  position: relative;
}

.arrowButton {
  width: 22px;
  height: 22px;
}

.keyvalue_accordion_item .close {
  background-color: #fff;
  color: #dbb68c;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #dbb68c;
  min-width: 50px;
  width: 50px;
  height: 50px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.keyvalue_accordion_item.true .close {
  background-color: #dbb68c;
  margin-right: 4px;
  margin-top: 15px;
  width: 50px;
  height: 50px;
  box-sizing: content-box;
}

.keyvalue_accordion_item .close span svg {
  vertical-align: middle;
  stroke: #dbb68c;
}

.keyvalue_accordion_item.true .close svg {
  stroke: #fff;
}

.keyvalue_accordion_item .keyvalue_accordion_header .close {
  font-weight: bold;
}

.open_cross {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 500ms;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.close_cross {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 500ms;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

#faqSection {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#faqSection h2 {
  margin-bottom: 15px;
  color: #dbb68c;
  font-size: 45px;
  font-family: "PT Serif", serif;
  font-weight: 700;
  overflow-wrap: break-word;
  margin: 47px 0 56px;
}

.faqSection-content {
  width: 920px;
}

.faqSection-content .toggle {
  display: flex;
}

.faqSection-content .toggle div {
  font-size: 21px;
  font-weight: 700;
  font-family: "PT Serif", serif;
  cursor: pointer;
  padding: 0px 40px 0 0;
  margin-right: 1px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faqSection-content .toggle a div {
  background-color: #f7f4f2;
  color: #dbb68c;
}

.faqSection-content .toggle a div:hover,
.faqSection-content .toggle a.activetab div {
  background-color: #dbb68c;
  color: #f7f4f2;
}

.faqSection-content .toggle div img {
  margin-right: 20px;
}

.facialTab,
.eyeTab {
  width: 100%;
  text-align: left;
  border: 3px solid #dbb68c;
  padding: 0 40px 10px;
  margin: 0;
}

#keyvalue {
  width: 100%;
}

.keyvalue_accordion_inner {
  border-bottom: 1px solid #dbb68c;
  cursor: pointer;
  padding: 15px 0;
}

.keyvalue_accordion_inner:last-of-type {
  border-bottom: none!important;
}

.keyvalue_accordion_item button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.keyvalue_accordion_item {
  transition: all 1s ease-in-out;
}

.keyvalue_accordion_header h4 {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #dbb68c;
  transition: font-weight 0.3s ease;
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.keyvalue_accordion_content {
  display: flex;
  color: #323e48;
  font-size: 15px;
}
.keyvalue_accordion_content_left {
  width: 93%;
}

.keyvalue_accordion_header h4 span,
.keyvalue_accordion_content_left h4 span {
  line-height: 1;
  font-size: 30px;
  font-weight: 700;
  padding-right: 5px;
}

.keyvalue_accordion_content_left h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #dbb68c;
  font-size: 18px;
}

.keyvalue_accordion_content_left p {
  padding-left: 22px;
  font-weight: 500;
}

.keyvalue_accordion_content_left ul {
  list-style: disc;
  padding-left: 38px;
}

.keyvalue_accordion_content_left p.footnote {
  font-size: 11px;
  font-weight: 700;
}

.keyvalue_accordion_content p {
  line-height: 1.5;
  font-weight: 300;
}

.keyvalue_accordion_content a {
  color: #dbb68c;
}

.keyvalue_accordion_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.keyvalue_accordion_content_right {
  position: relative;
}

.arrowButton {
  width: 22px;
  height: 22px;
}

.keyvalue_accordion_item .close {
  background-color: #fff;
  color: #dbb68c;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #dbb68c;
  min-width: 50px;
  width: 50px;
  height: 50px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.keyvalue_accordion_item.true .close {
  background-color: #dbb68c;
  margin-right: 4px;
  margin-top: 15px;
  width: 50px;
  height: 50px;
  box-sizing: content-box;
}

.keyvalue_accordion_item .close span svg {
  vertical-align: middle;
  stroke: #dbb68c;
}

.keyvalue_accordion_item.true .close svg {
  stroke: #fff;
}

.keyvalue_accordion_item .keyvalue_accordion_header .close {
  font-weight: bold;
}

.open_cross {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 500ms;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.close_cross {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 500ms;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

@media screen and (max-width: 1100px) {
  .faqSection-content {
    width: 700px;
  }
}

@media screen and (max-width: 768px) {
  .toggle > a {
    flex: 1 1;
  }

  .faqSection-content {
    width: 100%;
  }

  #faqSection h2 {
    line-height: 1;
    font-size: 30px;
    text-align: center;
    width: 80%;
    margin: 16px 0 20px;
  }

  .faqSection-content .toggle div {
    padding: 0 6px 0 0;
    font-size: 18px;
    line-height: 1.2;
  }

  .faqSection-content .toggle div img {
    height: 48px;
  }

  .faqSection-content .toggle a div {
    font-size: 15px;
  }

  .facialTab,
  .eyeTab {
    border: 7px solid #dbb68c;
    border-bottom: 22px solid #dbb68c;
    padding: 0;
  }

  .keyvalue_accordion_header h4 {
    font-size: 14px;
  }

  .keyvalue_accordion_header h4 span,
  .keyvalue_accordion_content_left h4 span {
    font-size: 19px;
  }

  .keyvalue_accordion_content_left h4 {
    font-size: 14px;
  }

  .keyvalue_accordion_content_left p {
    padding: 0;
    font-size: 12px;
  }

  .keyvalue_accordion_item .close {
    width: 38px;
    height: 38px;
    min-width: 38px;
    margin-left: 10px;
  }

  .keyvalue_accordion_item.true .close {
    width: 38px;
    height: 38px;
    min-width: 38px;
    margin-left: 10px;
  }

  .keyvalue_accordion_inner {
    border-bottom: 7px solid #dbb68c;
    padding: 3px 28px;
  }

  .arrowButton {
    width: 16px;
    height: 16px;
  }
}

@font-face {
	font-family: 'Uni Sans';
	font-style: normal;
	font-weight: 400;
	src: url(/static/media/Unisans.196f5b40.eot);
	src: url(/static/media/Unisans.196f5b40.eot?#iefix) format('embedded-opentype'), url(/static/media/Unisans.76efa2ca.woff2) format('woff2'), url(/static/media/Unisans.7812b3a7.woff) format('woff'), url(/static/media/Unisans.21803d74.ttf) format('truetype'), url(/static/media/Unisans.41094c8c.svg#UniSans) format('svg');
}

@font-face {
	font-family: 'PT italics';
	font-style: normal;
	font-weight: 400;
	src: url(/static/media/PT_Italic.0cff059f.eot);
	src: url(/static/media/PT_Italic.0cff059f.eot?#iefix) format('embedded-opentype'), url(/static/media/PT_Italic.bdfcebc8.woff2) format('woff2'), url(/static/media/PT_Italic.8b62c1f5.woff) format('woff'), url(/static/media/PT_Italic.159feaba.ttf) format('truetype'), url(/static/media/PT_Italic.9bf92c16.svg#PT_Italic) format('svg');
}

html {
	scroll-behavior: smooth;
}

/*  @font-face {
	font-family: 'Canela';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/Canela-Regular.woff') format('woff'), url('../fonts/Canela-Regular.woff2') format('woff2');
}  

@font-face {
	font-family: 'Canela-bold';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/Canela-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'Gotham';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/Gotham-Book.woff') format('woff'), url('../fonts/Gotham-Book.woff2') format('woff2');
}  

@font-face {
	font-family: 'Gotham-medium';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/Gotham-Medium.woff') format('woff');
}  */

body {
	font-family: 'Uni Sans', sans-serif;
	margin: 0;
}

i {
	font-family: 'PT italics', sans-serif;
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
	padding-left: 0;
}

img {
	max-width: 100%;
	height: auto;
}

.btn {
	min-width: 140px;
	padding: 9px 25px;
	border: 0;
	border-radius: 35px;
	text-align: center;
	font-size: 12px;
	text-shadow: none;
	transition: all 0.3s;
	background: #009DC0;
	color: #fff;
	font-family: 'PT sans', sans-serif;
}

.btn-big {
	min-width: 200px;
	font-size: 16px;
}

.btn.btn-primary {
	background: #009DC0;
	color: #fff;
}

.SectionTitleSimple {
	margin: 0 auto;
	padding: 13px;
	font-size: 35px;
	font-weight: 100;
	color: #a4b0b7;
}

.btn-small {
	border-radius: 15px;
  background: #20a9ca;
  padding: 6px 18px;
  color: #fff;
  display: inline-block;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
}

.frame::-webkit-scrollbar {
    -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
    width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
    height: 11px;
}

.frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

.frame::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
} 





.content {
	display: flex;
	align-items: center;
	margin-bottom: 50px;
	margin-top: 25px;
}

.link {
	text-decoration: underline;
}

.store {
	display: flex;
}

.stores {
	margin-left: 25px;
}

.store img {
	align-self: center;
	margin-right: 10px;
}

@media (max-width: 768px) {
	.content {
		flex-direction: column;
		padding: 0;
		align-items: flex-start;
	}

	.stores {
		margin: 15px 0;
	}

	.page {
		padding: 0 15px;
	}
}
