#flaws {
    font-family: 'Montserrat', sans-serif;
}

.care-btn {
    background-color: #f39571;
    color: #fff;
    display: inline-block;
    width: auto;
    font-family: 'Montserrat', sans-serif;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 45px;
    border: 0;
    border-radius: 4px;    
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
}

.care-btn:hover {filter: brightness(0.85);}

/*   FAQ   */

#flaws .faqSection-content .eyeTab,
#flaws .faqSection-content .facialTab {
    border: 3px solid #f39571!important;
}
#flaws .faqSection-content .keyvalue_accordion_header h4,
#flaws .faqSection-content .keyvalue_accordion_content_left h4 {
    color: #f39571!important;
}
#flaws .faqSection-content .keyvalue_accordion_item .close {
    color: #f39571!important;
    border: 1px solid #f39571!important;
    background-color: #fff!important;
}
#flaws .faqSection-content .keyvalue_accordion_item.true .close {
    background-color: #f39571!important;
    color: #fff!important;
    border: 1px solid #f39571!important;
}

#flaws .faqFacialSection-content .keyvalue_accordion_inner {
    border-bottom: 1px solid #b58874!important;
}
#flaws .faqSection-content .toggle a div:hover, 
#flaws .faqSection-content .toggle a.activetab div {
    background-color: #f39571!important;
}
#flaws .faqSection-content .toggle a div {color: #f39571!important;}
#flaws .faqSection-content .toggle a div:hover,
#flaws .faqSection-content .toggle a.activetab div {
    color: #fff!important;
}



/*   GLOW SKIN BANNER  */
.glowskin-banner {
    background-color: #f0ece9;
    padding: 90px 0 80px;
    margin-bottom: 0;
    margin-top: 70px;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 17px;
    text-align: center;
    position: relative;
    max-width: 100%;
    height: auto;
    overflow: hidden;
}

.glowskin-banner .collection-poster {
    width: 240px;
    transform: rotate(6deg);
    position: absolute;
    top: 70px;
    left: calc(50% + 170px);
}

.glowskin-banner .collection-eye-massager {
    width: 45px;
    transform: rotate(-96deg);
    position: absolute;
    top: -70px;
    left: calc(50% + 220px);
}

.glowskin-banner .collection-brush {
    width: 125px;
    transform: rotate(-11deg);
    position: absolute;
    top: 100px;
    right: calc(50% + 250px);
}

.glowskin-banner h2 {
    position: relative;
    color: #b58874;
    margin: 0 auto 15px;
    max-width: 450px;
    font-size: 45px;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    overflow-wrap: break-word;
    line-height: 1;
}

.glowskin-banner p {
    margin-bottom: 25px;
}

.glowskin-banner .care-btn{
    background-color: #b58874;
}

@media screen and (max-width: 768px) {
    .glowskin-banner {
        padding: 65px 60px 40px;
        margin-bottom: 9px;
        margin-top: 9px;
    }

    .glowskin-banner .collection-poster {
        width: 170px;
        position: absolute;
        top: 45px;
        left: calc(50% + 50px);
    }
    
    .glowskin-banner .collection-eye-massager {
        width: 30px;
        position: absolute;
        top: -52px;
        left: calc(50% + 60px);
    }
    
    .glowskin-banner .collection-brush {
        width: 83px;
        position: absolute;
        top: 60px;
        right: calc(50% + 80px);
    }
    
    .glowskin-banner h2 {
        max-width: 200px;
        margin-bottom: 10px;
        line-height: 1;
        font-size: 30px;
    }

    .glowskin-banner figure img {
        z-index: 1;
    }

    .glowskin-banner h2,
    .glowskin-banner p,
    .glowskin-banner .care-btn {
        z-index: 3;
        position: relative;
    }

    .glowskin-banner p {
        font-size: 12px;
        margin-bottom: 14px;
    }

    .glowskin-banner .care-btn {
        font-size: 12px;
        width: 86%;
        margin: 0 auto;
    }



}







