.Benefits {
	clear: both;
	padding-top: 40px;
}

.Benefit {
	width: 20%;
	display: inline-block;
	padding: 0 20px;
	line-height: 20px;
	vertical-align: text-top;
	text-align: center;
}

.BenefitCaption {
	margin-top: 20px;
	min-height: 200px;
}

.BenefitCaption ul {
	padding-left: 10px;
	list-style: black;
	text-align: left;
}

.Benefit p {
	max-width: 160px;
	line-height: 1.2em;
	color: #6d6e70;
	margin: 0 auto;
	font-size: 14px;
}

.Benefit p:first-child {
	text-transform: uppercase;
	font-weight: 100;
	font-size: 15px;
	color: #00ACCF;
	line-height: 1.2em;
	margin-bottom: 15px;
	min-height: 54px;
}

.Benefit-img-wrapper {
	min-height: 170px;
}

.Benefit-img-wrapper img {
	width: 140px;
}

.DetailsNav {
	margin-bottom: 30px;
	margin-top: 50px;
}

.DetailsNav li {
	display: inline-block;
	vertical-align: middle;
	border-bottom: 3px solid #dbdcdc;
}

.DetailsNav li a {
	color: #000;
	display: block;
	height: 100%;
	padding: 18px;
	line-height: 18px;
	text-transform: uppercase;
	font-size: 16px;
}

.DetailsNav li a:hover {
	color: rgba(0, 157, 192, 0.8);
}

.DetailsNav li a:active {
	border-bottom: 3px solid rgba(0, 157, 192, 0.8);
}

.DetailsSection-1 .disclaimer {
	color: #a4b0b7;
}

.DetailsSection-1-inner {
	padding: 90px 60px 75px;
	margin: 0 auto;
	background-color: #000;
	color: #fff;
	text-align: center;
}

.DetailsSection-1 h2 {
	margin: 0 0 20px;
	font-weight: 700;
	font-size: 44px;
	line-height: 1em;
	text-transform: uppercase;
}

.DetailsSection-1 h3 {
	margin: 0 auto;
	width: 85%;
	padding: 13px;
	font-size: 28px;
	font-weight: 100;
	font-style: italic;
}

.DetailsSection-1 hr {
	width: 75px;
	margin: 0 auto;
}

.DetailsSection-1 p {
	line-height: 1.1em;
	font-size: 16px;
	padding: 0 150px;
}

.DetailsSection-1 {
	margin: 50px 0;
}

.DetailsSection-2 {
	margin-top: 30px;
	margin-bottom: 50px;
}

.FixedPartTable .picture img {
	max-height: 120px;
	max-width: 100px;
}

.FixedPartTable .name {
	font-weight: 100;
	margin: 0 auto;
	padding: 10px 5px;
	font-size: 18px;
	line-height: 1.1em;
	width: 65%;
}

.FixedPartTable .name a {
	color: #00ACCF;
	text-transform: uppercase;
	height: 120p;
}

.FixedPartTable .picture>a {
	min-height: 110px;
	display: block;
}

.FixedPartTable table tr {
	vertical-align: top;
}

.FixedPartTable table tr th {
	width: 20%;
	height: 150px;
}

.ScrollableTable td {
	padding: 10px 60px;
	border-top: 1px solid #EDEEF0;
}

.ScrollableTable td.info {
	text-align: left;
	padding: 10px 62px 10px 0;
}

.DetailsSection-3 {
	padding: 60px 0;
	text-align: center;
	background: #a4b0b7;
}

.Doc {
	background: #FFFFFF;
	height: 175px;
	border-radius: 25px;
	border: 2px solid #A4B0B8;
	display: block;
	padding: 15px;
	width: 12%;
	margin: 0 auto;
}

.Doc .DocIcon {
	margin: 0 auto;
}

.Doc .DocTitle {
	text-transform: uppercase;
	color: #000;
	line-height: 1em;
	margin: 10px auto;
}

.Doc .DocTitle p {
	margin: 10px auto;
}

.Doc hr {
	margin: 0 auto;
	color: #EDEEF0;
	width: 60%;
}

.DetailsSection-4 {
	padding-top: 30px;
}

.ProductBlock {
	margin-top: 10px;
	overflow: hidden;
	margin-bottom: 50px;
}

.ProductBlock-left {
	width: 50%;
	float: left;
}

.ProductBlock-left-main {
	border: solid 1px #dfe1e2;
	padding: 50px;
}

.ProductBlock-left-thumbnail {
	width: 25%;
	margin-top: 25px;
}

.ProductBlock-right {
	width: 50%;
	float: left;
	text-align: left;
	padding: 0 70px 25px 66px;
	margin: 0;
	color: #6d6e70;
	font-weight: 100;
	line-height: 24px;
	font-size: 16px;
}

.ProductBlock-right h2 {
	color: #1E252B;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1em;
	font-size: 45px;
	margin: 0 0 9px;
	padding: 0 0 12px;
	letter-spacing: -0.009em;
	position: relative;
}

.ProductBlock-right h3 {
	font-size: 25px;
	line-height: 22px;
	font-weight: 100;
}

.ProductBlock-right a {
	display: inline-block;
	margin-right: 10px;
	margin-top: 50px;
}

.ProductBlock-right p {
	color: #6d6e70;
}

.ProductBlock-right p.ref {
	border-top: solid 1px #dfe1e2;
	border-bottom: solid 1px #dfe1e2;
	width: 30%;
	padding: 10px 0;
}

.ProductBlock-left-main .product-image.hide {
	display: none;
}

.ProductBlock-left-thumbnail {
	width: 100%;
	box-sizing: border-box;
}

.ProductBlock-left-thumbnail img {
	display: inline-block;
	width: 25%;
	padding: 15px;
	box-sizing: border-box;
	border: solid 1px #dfe1e2;
}

.ProductBlock-left-thumbnail img:hover {
	cursor: pointer;
}


@media (max-width: 768px) {
	.Benefits {
		padding-top: 15px;
	}
	.ProductBlock-right, .ProductBlock-left {
		width: 100%;
	}
	.Product {
		width: 100% !important;
		padding: 0 15px;
	}
	.ProductBlock-right {
		padding: 0;
		margin-top: 15px;
	}
	.Product .btn.btn-primary {
		width: 100%;
		margin: 10px 0;
	}
	.Benefit {
		width: 100%;
		margin-bottom: 50px;
	}
	.Benefit-img-wrapper {
		min-height: 0;
	}
	.Benefit p:first-child {
		margin-bottom: 0;
	}
	.BenefitCaption {
		min-height: 0;
	}
	.DetailsSection-1 {
		margin-top: 0;
		margin-bottom: 30px;
	}
	.DetailsSection-1-inner {
		padding: 15px;
	}
	.DetailsSection-1-inner h2 {
		font-size: 35px;
	}
	.DetailsSection-1-inner h3 {
		width: 100%;
		padding: 13px 0;
		font-size: 26px;
	}
	.DetailsSection-1 p {
		padding: 0;
	}
}

@media (max-width: 768px) {
	.DetailsSection-3 {
		background: none;
		padding: 0;
	}
	.Doc {
		width: 100%;
	}
}

.ProductBlock {
	margin-bottom: 0;
}

.ProductBlock-left {
	width: 50%;
	float: left;
}

.ProductBlock-left-main {
	border: solid 1px #dfe1e2;
	padding: 50px;
}

.ProductBlock-left-thumbnail {
	width: 25%;
	margin-top: 25px;
}

.ProductBlock-right {
	width: 50%;
	float: left;
	text-align: left;
	padding: 0 70px 25px 66px;
	margin: 0;
	color: #6d6e70;
	font-size: 14.5px;
	line-height: 1.2em;
}

.ProductBlock-right h2 {
	color: #1E252B;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1em;
	font-size: 45px;
	margin: 10px 0 9px;
	padding: 0 0 12px;
	letter-spacing: -0.009em;
	position: relative;
}

.ProductBlock-right h3 {
	font-size: 25px;
	line-height: 22px;
	font-weight: 100;
}

.ProductBlock-right a {
	display: inline-block;
	margin-right: 10px;
	margin-top: 50px;
}

.ProductBlock-right p {
	color: #6d6e70;
}

.ProductBlock-right p.ref {
	border-top: solid 1px #dfe1e2;
	border-bottom: solid 1px #dfe1e2;
	width: 50%;
	padding: 10px 0;
}

.ProductBlock-left-main .product-image.hide {
	display: none;
}

.ProductBlock-left-thumbnail {
	width: 100%;
	box-sizing: border-box;
}

.ProductBlock-left-thumbnail img {
	display: inline-block;
	width: 25%;
	padding: 15px;
	box-sizing: border-box;
	border: solid 1px #dfe1e2;
}

.ProductBlock-left-thumbnail img:hover {
	cursor: pointer;
}

@media (max-width: 768px) {
	.ProductBlock-right, .ProductBlock-left {
		width: 100%;
	}
	.Product {
		width: 100% !important;
		padding: 0 15px;
	}
	.ProductBlock-right {
		padding: 0;
		margin-top: 15px;
	}
	.Product .btn.btn-primary {
		width: 100%;
		margin: 10px 0;
	}
	.Benefit {
		width: 100%;
		margin-bottom: 50px;
	}
	.Benefit-img-wrapper {
		min-height: 0;
	}
	.Benefit p:first-child {
		margin-bottom: 0;
	}
	.BenefitCaption {
		min-height: 0;
	}
	.DetailsSection-1 p {
		padding: 0;
	}
}

@media (max-width: 768px) {
	.DetailsSection-3 {
		background: none;
		padding: 0;
	}
	.Doc {
		width: 100%;
	}
	.FixedPartTable table tr th {
		display: none;
	}
	.FixedPartTable table tr th:first-child {
		display: block;
	}
}

.ProductBoxesThree {
	padding-top: 35px;
	padding-bottom: 80px;
}

.RowItem {
	width: 25%;
	display: inline-block;
	border: 1px solid #e3e6e8;
	min-height: 370px;
	position: relative;
}

.RowItem_Img {
	height: 200px;
	padding: 10px 0;
}

.RowItem_Caption {
	position: absolute;
	z-index: 2;
	left: 0;
	top: 220px;
	width: 100%;
	padding: 0 25px 20px 25px;
}

.RowItem_Title {
	font-size: 21px;
	margin: 0 0 4px;
	padding: 0 0 10px;
	color: #009DC0;
	line-height: 1em;
	text-transform: uppercase;
	position: relative;
	min-height: 52px;
}

.RowItem_Title:after {
	content: "";
	width: 25px;
	position: absolute;
	bottom: 0;
	display: block;
	left: 50%;
	margin-left: -12px;
	background: #A4B0B8;
	height: 1px;
}

.RowrItem_Description {
	font-size: 16px;
	color: #6d6e70;
	margin: 0 auto;
	min-height: 0;
	line-height: 1.2em;
	letter-spacing: -0.009em;
	width: 80%;
}

@media (max-width: 768px) {
	.RowItem {
		width: 100%;
	}
}