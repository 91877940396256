.ContactUsBanner {
	background: url(../images/about-us-bg.jpg);
	text-align: center;
    padding: 44px 0;
    background-size: cover;
    background-position: center center;
    margin-bottom: 30px;
}
.ContactUsBanner h1 {
    line-height: 1.5em;
    font-size: 45px;
    font-size: 3.0rem;
    color: #6d6e70;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Uni Sans', sans-serif;
    font-weight: bold;
}



.cnt-p {
    max-width: 800px;
    margin: 25px 0;
}

.cnt-p .btn {
    margin: 35px 0;
    text-transform: uppercase;
    padding: 8px 30px;
    display: block;
    width: 100%;
}

.cnt-p-details {
    display: flex;
    justify-content: space-between;
}

.cnt-p-address {
    text-align: right;
}

@media screen and (max-width: 580px) {
    .cnt-p-details {
        flex-direction: column;
    }

    .cnt-p-address {
        text-align: left;
    }
}