body.no-scroll {
  overflow: hidden;
}

.main-nav {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  padding-top: 80px;
  left: 0;
  text-align: left;
  z-index: 40;
  transition: all 0.4s;
  -webkit-transform: translateX(-320px);
  transform: translateX(-320px);
}

.main-nav a,
.main-nav h2 {
  color: #fff;
  font-family: "PT sans";
}

.main-nav a {
  text-transform: uppercase;
}

.main-nav h2,
.main-nav .h2 {
  margin: 0;
  padding: 13px 0 11px;
  line-height: 1.3em;
  color: #fff;
  font-weight: normal;
  font-family: "PT", sans-serif;
  text-align: center;
  font-size: 24px;
}

.main-nav h2 strong,
.main-nav .h2 strong {
  font-family: "uni", sans-serif, sans-serif;
  text-transform: uppercase;
  display: block;
  font-size: 32px;
}

.main-nav.show-nav {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.main-nav .niv1 {
  position: relative;
  background: #00add0;
  width: 320px;
  height: 100%;
}

.main-nav li img {
  max-width: 43px;
  position: relative;
  margin: 0 8px 0 19px;
  float: left;
  z-index: 10;
}

.main-nav li.secondary-nav img {
  margin: 0 8px 0 0;
}

.main-nav .navigation a,
.main-nav .navigation span {
  outline: none;
  display: block;
  text-decoration: none;
  padding: 12px 20px 12px;
  text-transform: uppercase;
  color: #fff;
  height: 100%;
  position: relative;
  font-size: 15px;
  transition: all 0.3s;
}

.main-nav .navigation a:before,
.main-nav .navigation span:before {
  display: block;
  position: absolute;
  font-size: 30px;
  right: 15px;
  top: 38%;
  margin: -15px 0 0 0;
  content: "\203A";
  font-family: ico;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  z-index: 10;
}

.main-nav .navigation a:after,
.main-nav .navigation span:after {
  height: 1px;
  background: #0093bb;
  left: 20px;
  right: 20px;
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  transition: all 0.3s;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.main-nav li.secondary-nav a,
.main-nav li.secondary-nav span {
  position: relative;
}

.main-nav li.secondary-nav a:after,
.main-nav li.secondary-nav span:after {
  left: 0;
  right: 0;
  background: #00add0;
}

.main-nav .navigation li a:after,
.main-nav .navigation li span:after {
  height: 1px;
  background: #0093bb;
  left: 20px;
  right: 20px;
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  transition: all 0.3s;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.main-nav .navigation li.direct-link a:before,
.main-nav .navigation li.direct-link span:before {
  display: none;
}

.main-nav li {
  list-style-type: none;
  margin-top: -1px;
  clear: both;
}

.main-nav li.secondary-nav ~ li.secondary-nav {
  margin-top: 0;
}

.main-nav li.secondary-nav {
  background: #009dc0;
  margin: 20px 20px 0 20px;
}
.main-nav .navigation .niv3 {
  position: absolute;
  z-index: -1;
  top: 0;
  min-width: 320px;
  left: 320px;
  height: 100%;
  background: #52c6de;
  color: #6d6e70;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: all 0.4s;
  -webkit-transform: translateX(-320px);
  transform: translateX(-320px);
  visibility: hidden;
  /* border-left: solid white 1px; */
}

.main-nav .navigation .niv3.open {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  right: 320px;
  visibility: visible;
}

.main-nav .navigation .niv3 .col {
  float: left;
  width: 320px;
  padding: 20px;
}

main-nav .navigation .niv3 h2,
.main-nav .navigation .niv3 .h2 {
  border-top: 1px solid #64cdeb;
  border-bottom: 1px solid #64cdeb;
  padding: 7px 0 9px;
  margin: 0 0 17px;
}

.main-nav .navigation .niv3 .col li {
  height: 44px;
  display: table;
  width: 100%;
}

.main-nav .navigation .niv3 .col li a {
  display: table-cell;
  vertical-align: middle;
  padding: 3px 16px 0 9px;
  line-height: 1.2em;
  height: 100%;
}

.main-nav .navigation .niv3 li a {
  color: #fff;
  min-height: 49px;
  border-bottom: 1px solid #99deec;
}

.main-nav .navigation .niv3 li a:before {
  display: none;
}

.main-nav .navigation .niv3 li a:after {
  display: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  background-color: #dbdfe4;
}

/* kedwjhfewoi */

.main-nav .navigation .niv2 {
  position: absolute;
  z-index: -1;
  top: 0;
  min-width: 320px;
  left: 320px;
  height: 100%;
  background: #33bdd9;
  color: #6d6e70;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: all 0.4s;
  -webkit-transform: translateX(-320px);
  transform: translateX(-320px);
  visibility: hidden;
}

.main-nav .navigation .niv2.open {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  right: 320px;
  visibility: visible;
}

.main-nav .navigation .niv2 .col {
  float: left;
  width: 320px;
  padding: 20px;
}

.main-nav .navigation .niv2 h2,
.main-nav .navigation .niv2 .h2 {
  border-top: 1px solid #64cdeb;
  border-bottom: 1px solid #64cdeb;
  padding: 7px 0 9px;
  margin: 0 0 17px;
}

.main-nav .navigation .niv2 .col li {
  height: 44px;
  display: table;
  width: 100%;
}

.main-nav .navigation .niv2 .col li a {
  display: table-cell;
  vertical-align: middle;
  padding: 3px 16px 0 9px;
  line-height: 1.2em;
  height: 100%;
}

.main-nav .navigation .niv2 li a {
  color: #fff;
  min-height: 49px;
  border-bottom: 1px solid #99deec;
}

.main-nav .navigation .niv2 li a:before {
  display: none;
}

#skin-care:before {
  display: block;
  position: absolute;
  font-size: 30px;
  right: 15px;
  top: 38%;
  margin: -15px 0 0 0;
  content: "\203A";
  font-family: ico;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  z-index: 10;
}

.main-nav .navigation .niv2 li a:after {
  display: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  background-color: #dbdfe4;
}

.main-nav .navigation a.all,
.main-nav .navigation span.all {
  background: #009dc0;
}

.main-nav .navigation .niv2 li a:hover {
  background-color: #fff;
  color: #009dc0;
}

.main-nav .navigation a:hover,
.main-nav .navigation span:hover {
  background: #33bdd9;
  color: #fff;
}

.overlay {
  position: fixed;
  z-index: 39;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  transition: all 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  visibility: hidden;
}

.overlay.open {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  visibility: visible;
  cursor: pointer;
}
