.Nav {
  position: relative;
  z-index: 20;
  background-color: rgba(0, 157, 192, 0.8);
  color: #fff;
}

.active {
  background: #0185a3;
}

.Nav nav li a.active:hover {
  color: #fff;
}

.main-nav .navigation .niv2 h2,
.main-nav .navigation .niv2 .h2 {
  font-family: "PT italics", sans-serif;
  font-weight: 100;
  text-transform: uppercase;
}

.Nav ul {
  text-align: left;
  width: 100%;
  height: 100%;
  min-height: 70px;
  padding: 0;
  margin: 0;
  font-size: 0;
  background-color: #00a7ca;
  background-color: rgba(0, 167, 202, 0.8);
}

.Nav nav li {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.Nav nav li:hover {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.Nav nav li a {
  display: block;
  height: 100%;
  padding: 18px;
  line-height: 18px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Uni Sans";
  min-width: 130px;
  text-align: center;
  font-weight: 100;
}

.Nav nav li a:hover {
  color: rgba(0, 157, 192, 0.8);
}

.Nav nav li a:active {
  background: #1ab5d5;
  border-right: 1px solid #4ac5dd;
  border-left: 1px solid #4ac5dd;
}

.Nav nav li:nth-child(5) {
  float: right;
  background: #039fbf;
  border-left: 1px solid #4ac5dd;
}

.Nav nav li:nth-child(5):hover {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.Nav.nav-subcat nav li:nth-child(5) {
  float: none;
  background: none;
  border-left: 0;
}

.Nav.nav-subcat nav li:nth-child(5):hover {
  background-color: none;
  background-color: none;
}

@media (max-width: 768px) {
  .Nav {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .main-nav .navigation .niv2.open {
    left: 20px;
    box-shadow: 0 0 40px #444;
    z-index: 20;
  }
  .main-nav .navigation .niv3.open {
    left: 15px;
    box-shadow: 0 0 40px #444;
    z-index: 20;
  }
}
