.Headerpage {
  border-bottom: 1px solid #dbdcdc;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 50;
}

.ToggleNav {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 12px 23px 38px 23px;
  transition: 0.25s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: left;
  font-size: 12px;
  color: #fff;
  background-color: #009dc0;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
}

.skin-care-devices ::before {
  display: block;
  position: absolute;
  font-size: 30px;
  right: 15px;
  top: 38%;
  margin: -15px 0 0 0;
  content: "\203A";
  font-family: ico;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #fff;
  z-index: 10;
}

.ToggleNav .burger-icon:before,
.ToggleNav .burger-icon:after {
  transition: 0.25s;
}

.ToggleNav:hover .burger-icon:before {
  top: 9px;
}

.ToggleNav:hover .burger-icon:after {
  top: -9px;
}

.ToggleNav.active:hover .burger-icon {
  -webkit-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
}

.ToggleNav.active .burger-icon {
  background: transparent;
  transition: all 0.3s;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.active:not(.steps) .burger-icon:before,
.active:not(.steps) .burger-icon:after {
  top: 0 !important;
}

.ToggleNav:hover .burger-icon:before {
  top: 9px;
}

.ToggleNav.active .burger-icon:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ToggleNav:hover .burger-icon:after {
  top: -9px;
}

.ToggleNav.active .burger-icon:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.active .burger-icon:before,
.active .burger-icon:after {
  transition: 0.5s;
}

.Logo {
  max-width: 80%;
}

.HeaderCenter {
  text-align: center;
}

.HeaderRight {
  padding: 7px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 0 !important;
}

.repair-logo {
  width: 60px;
  padding-right: 10px;
  border-right: 1px solid #e4e4e4;
  margin-right: 10px;
}

.back-button {
  position: absolute;
  left: -60px;
  top: 10px;
  display: none;
}

@media screen and (min-width: 440px) {
  .Headerpage {
    min-height: 60px;
  }
  .ToggleNav {
    font-size: 12px;
  }
  .HeaderRight {
    padding: 14px 20px;
  }
  .Logo {
    max-width: 100%;
  }
}
