#faqSection {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#faqSection h2 {
    margin-bottom: 15px;
    font-size: 45px;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    overflow-wrap: break-word;
    margin: 47px 0 56px;
}

#flaws #faqSection h2 {
    color: #f39571;
}

.faqSection-content  {
    width: 920px;
}

.faqSection-content .toggle {
    display: flex;
}

.faqSection-content .toggle div {
    font-size: 21px;
    font-weight: 700;
    font-family: 'PT Serif', serif;   
    cursor: pointer;
    padding: 0px 40px 0 0;
    margin-right: 1px;
    transition: all .3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.faqSection-content .toggle a div {
    background-color: #f7f4f2;
    color: #f39571;
}

.faqSection-content .toggle a div:hover,
.faqSection-content .toggle a.activetab div {
    background-color: #f39571;
    color: #f7f4f2;
}

.faqSection-content .toggle div img {
    margin-right: 20px;
}

.facialTab,
.eyeTab {
    width: 100%;
    text-align: left;
    border: 3px solid #f39571;
    padding: 0 40px 10px;
    margin: 0;
}

#keyvalue {
    width: 100%;
}

.keyvalue_accordion_inner {
    border-bottom: 1px solid #f39571;
    cursor: pointer;
    padding: 15px 0;
}

.keyvalue_accordion_inner:last-of-type {
    border-bottom: none!important;
}

.keyvalue_accordion_item button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.keyvalue_accordion_header h4 {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #f39571;
    transition: font-weight .3s ease;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
}

.keyvalue_accordion_content {
    display: flex;
    color: #323e48;
    font-size: 15px;
}

.keyvalue_accordion_header h4 span,
.keyvalue_accordion_content_left h4 span{
    line-height: 1;
    font-size: 30px;
    font-weight: 700;
    padding-right: 5px;
}

.keyvalue_accordion_content_left h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #f39571;
    font-size: 18px;
}

.keyvalue_accordion_content_left p {
    padding-left: 22px;
}

.keyvalue_accordion_content_left ul {
    list-style: disc;
    padding-left: 38px;
}

.keyvalue_accordion_content_left p.footnote {
    font-size: 11px;
    font-weight: 700;
}

.keyvalue_accordion_content p {
    line-height: 1.5;
}

.keyvalue_accordion_content a {
    color: #f39571;
}

.keyvalue_accordion_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.keyvalue_accordion_content_right {
    position: relative;
}

.arrowButton {
    width: 22px;
    height: 22px;
}

.keyvalue_accordion_item .close {   
	background-color: #fff;
    color: #f39571;
	cursor: pointer;
	border-radius: 50%;
    border: 1px solid #f39571;
    min-width: 50px;
	width: 50px;
	height: 50px;
	box-sizing: content-box;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: rotate(-90deg);
}

.keyvalue_accordion_item.true .close{
    background-color: #f39571;
    margin-right: 4px;
    margin-top: 15px;
    width: 50px;
	height: 50px;
	box-sizing: content-box;
}

.keyvalue_accordion_item .close span svg{
	vertical-align: middle;
    stroke: #f39571;
}

.keyvalue_accordion_item.true .close svg{
    stroke: #fff;
}

.keyvalue_accordion_item .keyvalue_accordion_header .close {
	font-weight: bold;
}

.open_cross {
	transform: rotate(180deg);
	transition-property: transform;
	transition-duration: 500ms;
	transform-origin: center center;
}

.close_cross {
	transition-property: transform;
	transition-duration: 500ms;
	transform-origin: center center;
}


@media screen and (max-width: 1100px) {
    .faqSection-content {
        width: 700px;
    }
}

@media screen and (max-width: 768px) {
    .toggle>a {
        flex: 1;
    }

	.faqSection-content {
        width: 100%;
    }

    #faqSection h2 {
        line-height: 1;
        font-size: 30px;
        text-align: center;
        width: 80%;
        margin: 16px 0;
    }

    .faqSection-content .toggle div {
        padding: 0 20px 0 0;
        justify-content: flex-start!important;
    }

    .faqSection-content .toggle div img{
        height: 48px;
    }

    .faqSection-content .toggle a div {
        font-size: 15px;
    }

    .facialTab,
    .eyeTab {
        border: 7px solid #f39571;
        border-bottom: 22px solid #f39571;
        padding: 0;
    }

    .keyvalue_accordion_header h4 {
        font-size: 14px;
    }

    .keyvalue_accordion_header h4 span, 
    .keyvalue_accordion_content_left h4 span {
        font-size: 19px;
    }

    .keyvalue_accordion_content_left h4 {
        font-size: 14px;
    }

    .keyvalue_accordion_content_left p {
        padding: 0;
        font-size: 12px;
    }

    .keyvalue_accordion_item .close {
        width: 38px;
        height: 38px;
        min-width: 38px;
        margin-left: 10px;
    }

    .keyvalue_accordion_item.true .close {
        width: 38px;
        height: 38px;
        min-width: 38px;
        margin-left: 10px;
    }

    .keyvalue_accordion_inner {
        border-bottom: 7px solid #f39571;
        padding: 3px 28px;
    }    

    .arrowButton {
        width: 16px;
        height: 16px;
    }
    
	
	
	
}
