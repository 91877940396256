#facial {
    font-family: 'Montserrat', sans-serif;
}

.facial-care-btn {
    background-color: #b58874;
}

.facial-care-btn:hover {filter: brightness(0.85);}





































/*   HEADER SECTION    */
.Facial {
    height: 21.875vw;
    min-height: 420px;
    background-color: #e9e4e2;
    padding-top: 65px;
    position: relative;
}

#facial h1 {
    margin-bottom: 8px;
}

#facial h1 svg {
    width: 200px;
    margin-top: 23px;
}

#facial .background-video {
	width: auto;
    left: 50%;
    z-index: 5;
    transform: translate(-50%);
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    margin: 0;
}
#facial .background-video video {
    width: auto;
    height: 100%;
    position: relative;
    min-height: 420px;
    max-width: none;
    background-color: #000;
}

.FacialInner {
    position: relative;
    text-align: center;    
    z-index: 10;
}

.FacialInnerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -45px;
}

.Facial nav {
    position: absolute;
    bottom: 0;
    z-index: 10;
    left: 50%;
    transform: translate(-50%);
}

.Facial nav span {
    display: inline-block;
    width: 100%;
    line-height: 1;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'PT Serif', serif;
    margin-bottom: 100px;
    color: #323e48;
}

.FacialInnerContent .underH1 {
    color: #323e48;
    max-width: 450px;
    font-size: 14px;
}

.FacialInnerContent .underH1:after {
    content: "";
    width: 45px;
    height: 1px;
    background-color: #323e48;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%);
}
.Facial nav a figure {
    width: 60px;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
}

.Facial nav a figure img {
    width: auto;
    height: 100%;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.Facial nav a.link-nofacial {
    left: calc(50% - 20px);
    transform: translate(-100%);
    background-color: #f39571;
}

.Facial nav a.link-glowskin {
    left: calc(50% + 20px);
    background-color: #b58874;
}

.Facial nav a h2 {
    font-size: 22px;
    white-space: nowrap;
    color: #fff;
    margin: 0;
    line-height: 40px;
    font-family: 'PT Serif', serif;
}

.Facial nav a {
    position: absolute;
    bottom: 0;
    min-width: 325px;
    line-height: 1;
    font-size: 14px;
    color: #fff;
    text-align: left;
    padding: 2px 10px 14px 85px;
}


@media screen and ( max-width: 768px ) {
    .Facial {
        height: auto;
        min-height: 525px;
        max-height: none;
        padding: 0;
        position: relative;
    }

    .Facial h1 {
        margin: 233px auto 0!important;
    }

    #facial h1 svg {
        margin-top: -15px;
        width: 174px;
    }

    .FacialInnerContent {
        margin-top: 0;
    }

    .Facial nav {
        position: static;
        transform: translate(0);
        display: inline-block;
        width: 100%;
        font-size: 0;
        padding-bottom: 70px;
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
    }
    
    .Facial nav span {
        font-size: 14px;
        margin-bottom: 14px;
        text-align: center;        
    }
    
    
    .Facial nav a figure {
        width: 50px;
        height: 100%;
        overflow: hidden;
    }
    
    .Facial nav a figure img {
        float: right;
        width: auto;
        height: 100%;
        max-width: none;
    }
    
    .Facial nav a.link-nofacial {
        left: 50%;
        background-color: #f39571;
        height: 60px;
    }
    
    .Facial nav a.link-glowskin {
        left: 50%;
        background-color: #b58874;
    }
    
    .Facial nav a h2 {
        font-size: 14px;
        margin-bottom: 4px;
        white-space: normal;
        line-height: 1;
        max-width: 90%;
    }
    
    .Facial nav a {
        left: 50%;
        vertical-align: top;
        width: 50%;
        min-width: auto;
        font-size: 8px;
        font-weight: 500;
        padding: 10px 0 10px 56px;
        margin: 0;
    }

    figcaption {
        font-size: 16px;
        text-align: center;
        margin-bottom: 5px;
        position: relative;
        z-index: 8;
        left: auto;
        right: auto;
        top: auto;
        line-height: 1;
        font-weight: 700;
        font-family: 'PT Serif', serif;
    }

    .brand-muse-nofacial figcaption {
        color: #f39571;
    }


    .brand-muse-glowskin figcaption {
        color: #b58874;
    }

    .brand-muse-nofacial {
        float: left;
        width: 50%;
        height: 200px;
        padding-top: 10px;
        margin-bottom: 20px;
        position: relative;
        right: auto;
        left: 0;
        overflow: hidden;
        top: 0;
        margin: 0;
        position: absolute;
    }

    .brand-muse-glowskin {
        float: right;
        width: 50%;
        height: 200px;
        padding-top: 10px;
        margin-bottom: 20px;
        position: relative;
        right: auto;
        right: 0;
        overflow: hidden;
        top: 0;
        margin: 0;
        position: absolute;
    }

    .FacialInnerContent .underH1 {
        font-size: 11px;
        max-width: 94%;
    }

    .FacialInnerContent .underH1:after {
        bottom: -13px;
    }
}




















































/*   NO FLAWS BANNER  */
.no-flaws-banner {
    background-color: #fdece7;
    padding: 128px 0 85px;
    margin-bottom: 30px;
    margin-top: 70px;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 17px;
    text-align: center;
    position: relative;
    max-width: 100%;
    height: auto;
    overflow: hidden;
}

.no-flaws-banner figure {
    overflow: hidden;
    margin: 0;
}

.no-flaws-banner .collection-poster {
    width: 240px;
    transform: rotate(6deg);
    position: absolute;
    top: 70px;
    left: calc(50% + 220px);
}

.no-flaws-banner .collection-eye-massager {
    width: 45px;
    transform: rotate(-96deg);
    position: absolute;
    top: -70px;
    left: calc(50% + 270px);
}

.no-flaws-banner .collection-brush {
    width: 125px;
    transform: rotate(-11deg);
    position: absolute;
    top: 100px;
    right: calc(50% + 300px);
}

.no-flaws-banner h2 {
    position: relative;
    color: #f39571;
    margin: 0 auto 15px;
    max-width: 450px;
    font-size: 45px;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    overflow-wrap: break-word;
    line-height: 1;
}

.no-flaws-banner p {
    margin-bottom: 25px;
}

.no-flaws-banner .facial-care-btn{
    background-color: #f39571;
}

@media screen and (max-width: 768px) {
    .no-flaws-banner {
        padding: 65px 60px 40px;
        margin-bottom: 9px;
        margin-top: 0;
    }

    .no-flaws-banner .collection-poster {
        width: 170px;
        position: absolute;
        top: 45px;
        left: calc(50% + 50px);
    }
    
    .no-flaws-banner .collection-eye-massager {
        width: 30px;
        position: absolute;
        top: -52px;
        left: calc(50% + 60px);
    }
    
    .no-flaws-banner .collection-brush {
        width: 83px;
        position: absolute;
        top: 60px;
        right: calc(50% + 86px);
    }
    
    .no-flaws-banner h2 {
        max-width: 200px;
        margin-bottom: 10px;
        line-height: 1;
        font-size: 30px;
    }

    .no-flaws-banner figure img {
        z-index: 1;
    }

    .no-flaws-banner h2,
    .no-flaws-banner p,
    .no-flaws-banner .facial-care-btn {
        z-index: 3;
        position: relative;
    }

    .no-flaws-banner p {
        font-size: 12px;
        margin-bottom: 14px;
    }

    .no-flaws-banner .facial-care-btn {
        font-size: 12px;
        width: 86%;
        margin: 0 auto;
    }
}


/*   LIFT CONTOUR BANNER  */
.lift-banner {
    background-color: #fff0de;
    padding: 128px 0 85px;
    margin-bottom: 30px;
    margin-top: 30px;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 17px;
    text-align: center;
    position: relative;
    max-width: 100%;
    height: auto;
    overflow: hidden;
}

.lift-banner figure {
    overflow: hidden;
    margin: 0;
}

.lift-banner .lift-poster {
    width: 240px;
    transform: rotate(6deg);
    position: absolute;
    top: 70px;
    left: calc(50% + 220px);
}

.lift-banner .lift-eye-massager {
    width: 307px;
    transform: rotate(22deg);
    position: absolute;
    left: calc(50vw + 106px);
    top: -80px;
}

.lift-banner .lift-brush {
    width: 125px;
    transform: rotate(-10deg);
    position: absolute;
    right: calc(50vw + 290px);
    top: 60px;
}

.lift-banner h2 {
    position: relative;
    color: #dbb68c;
    margin: 0 auto 15px;
    max-width: 450px;
    font-size: 45px;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    overflow-wrap: break-word;
    line-height: 1;
}

.lift-banner p {
    margin-bottom: 25px;
}

.lift-banner .facial-care-btn{
    background-color: #dbb68c;
}

@media screen and (max-width: 768px) {
    .lift-banner {
        padding: 65px 60px 40px;
        margin-bottom: 9px;
        margin-top: 0;
    }

    .lift-banner .lift-poster {
        width: 170px;
        position: absolute;
        top: 45px;
        left: calc(50% + 50px);
    }
    
    .lift-banner .lift-eye-massager {
        width: 30px;
        position: absolute;
        top: -52px;
        left: calc(50% + 60px);
    }
    
    .lift-banner .lift-brush {
        width: 83px;
        position: absolute;
        top: 60px;
        right: calc(50% + 86px);
    }
    
    .lift-banner h2 {
        max-width: 200px;
        margin-bottom: 10px;
        line-height: 1;
        font-size: 30px;
    }

    .lift-banner figure img {
        z-index: 1;
    }

    .lift-banner h2,
    .lift-banner p,
    .lift-banner .facial-care-btn {
        z-index: 3;
        position: relative;
    }

    .lift-banner p {
        font-size: 12px;
        margin-bottom: 14px;
    }

    .lift-banner .facial-care-btn {
        font-size: 12px;
        width: 86%;
        margin: 0 auto;
    }
}







