.Tutorials-header {
  color: #dbb68c;
}

#antiblemish.tutorials-section .slider-control-centerright {
  right: -70px!important;
  top: 44%!important; 
  transform: translateY(-44%);
}

#antiblemish.tutorials-section .slider-control-centerleft {
  left: -70px!important;
  top: 44%!important; 
  transform: translateY(-44%);
}

.Tutorials-upper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 10px 0px;
  color: #dbb68c;
}
.Tutorials-upper h3 {
  font-size: 38px;
  margin-top: 20px;
  margin-bottom: 70px;
}

#lift .carouselWrapper {
  position: relative;
  width: 920px;
  margin: -100px auto 0;
}

#lift .slider {
  height: auto;
}

.Tutorials-slide {
  margin-top: 40px;
}
.Tutorials-slide-image-container {
  position: relative;
  width: 650px;
  height: 365px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 75px;
  cursor: pointer;
}
.Tutorials-slide-image-container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 18px 0 18px 26px;
  z-index: 5;
  transform: translate(-35%, -50%);
}

.Tutorials-slide-image-container::after {
  background-color: #dbb68c;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 3;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Tutorials-slide-image-container:hover::after {
  background-color: #ac7f68;
}

.Tutorials-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  opacity: 0.7;
}

.Tutorials-image:hover {
  opacity: 1;
}
.Tutorials-slide-image-container figcaption {
  width: 100%;
  position: absolute;
  bottom: 55px;
  font-weight: 100;
  font-size: 15px;
}
.Tutorials-slide-image-container figcaption strong {
  display: block;
  margin-bottom: 2px;
}

#lift .buttonContainer {
  display: flex;
  justify-content: center;
}

.Tutorials-video-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0;
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
}

.Tutorials-video {
  border: 0;
  border-radius: 5px;
}

#lift .circle {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: none;
  border-radius: 50%;
  margin: 5px;
  background: #fff;
  border: 1px solid #dbb68c;
}

#lift .circle.current {
  background: #dbb68c;
}

#lift .divider {
  width: 100%;
  height: 16px;
  background-color: #dbb68c;
}
#lift .slide3-inner-section ul {
  height: 100px;
}

.tutorial-mobile-buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

@media screen and (max-width: 1100px){
  #antiblemish.tutorials-section .slider-control-centerright {right: -45px!important;}
  #antiblemish.tutorials-section .slider-control-centerleft {left: -45px!important;}
}

@media screen and (max-width: 980px) {
  #lift .carouselWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .Tutorials-upper {
    height: 84px;
    padding: 0;
  }
  .Tutorials-upper h3 {font-size: 28px;margin-bottom: 0;}
  #lift .carouselWrapper {   width: 100%;  }
  .tutorial-carousel { height: 540px !important;  }
  .Tutorials-slide {margin: 0;}

  .Tutorials-slide-image-container {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 10px;
  }
  .Tutorials-slide-image-container::before {
    top: 46%;
    border-width: 11px 0 11px 19px;
  }
  .Tutorials-slide-image-container::after {
    top: 46%;
    width: 50px;
    height: 50px;
  }
  .Tutorials-slide-image-container figcaption {
    bottom: 20px;
    margin: -50px 0 26px;
  }
  .Tutorials-image {opacity: 1;}
  /* .tutorial-carousel .slider-control-bottomcenter {bottom: 160px!important;} */
  #antiblemish.tutorials-section .slider-control-centerright {display: none;}
  #antiblemish.tutorials-section .slider-control-centerleft {display: none;}
}
