.buyOnlineButton {
  background-color: #dbb68c;
  color: #fff;
  height: 35px;
  width: 180px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border: 0;
  border-radius: 4px;
  transition: 0.2s ease;
  transition-property: background-color, color;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  display: flex;
  justify-content: center;
  align-items: center;
}

.buyOnlineButton:hover {filter: brightness(1.1);}

@media screen and (max-width: 1200px) {
  .buyOnlineButton {
    height: 50px;
    width: 280px;
    font-size: 15px;
  }
}
