#antifatigue .slider-control-centerleft {
	left: -70px !important;
    top: 54% !important;
    transform: translateY(-54%)!important;
}

#antifatigue .slider-control-centerright {
	right: -70px !important;
    top: 54% !important;
    transform: translateY(-54%)!important;
}

#antifatigue .slider-control-centerleft button,
#antifatigue .slider-control-centerright button {
	border: none !important;
	background: transparent !important;
    
}

.leftArrow, .rightArrow {
	width: 30px;
    cursor: pointer;
}

.sliderArrows:focus {
	outline: none;
	border-color: rgba(255, 255, 255, 0.85);
}

.slider-list {
    cursor: initial!important;
}

#antifatigue .slider-slide {
	display: flex!important;
	flex-direction: column;
	align-items: center;
	height: 100%!important;
}

.fatigue-slide1-inner {
    display: flex;
    align-items: center;
}

#antifatigue .slider-slide {
    padding:5px 42px;
}

#antifatigue {
    text-align: center;
}

.antifatigue_header {
    background-color: #f39571;
    color: #fff;
    padding: 22px calc(50% - 460px) 20px;
    padding-bottom: 102px;
}

.antifatigue_header p {
    font-size: 14px;
}

.antifatigue_h2 {
    font-size: 45px;
    margin: 0 0 15px;
    font-family: 'PT Serif', serif;
}

#antifatigue .carouselWrapper {
    position: relative;
    top: -75px;
    width: 920px;
    margin: 0 auto -25px;
}

.fatigue-slide-header {
    height: 120px;
    padding-top: 16px;
}

.fatigue-slide-header p {
    font-size: 15px;
    text-transform: uppercase;
    color: #f39571;
    margin-top: 9px;
}

.fatigue-slide h3 {
    font-size: 35px;
    margin: 5px 0 0;
    color: #f39571;
    font-family: 'PT Serif', serif;
    font-weight: 700;
}

.fatigue-slide {
    width: 920px;
    max-width: 920px; 
    background-color: #fff;
    color: #323e48;
}    

.fatigue-slide1-list {
    text-align: left;
    padding-left: 57px;
    padding-top: 50px;
}

.fatigue-slide1-list ul {
    margin-bottom: 65px;
}

.fatigue-slide1-list li {
    list-style: none;
    margin-bottom: 16px;
    position: relative;
    font-size: 15px;
    max-height: 26px;
}

.fatigue-slide1 li.brush-about-x7 svg {
    width: 26px;
    height: 27px;
    margin-right: 26px;
}

.fatigue-slide1 li.brush-about-skin svg {
    width: 49px;
    height: 35px;
    margin-left: -10px;
    margin-right: 13px;
}

.fatigue-slide1 li.brush-about-complexion svg {
    width: 50px;
    height: 47px;
    margin-left: -5px;
    margin-right: 6px;
}

.fatigue-slide1 li.brush-about-waterproof svg {
    width: 38px;
    height: 42px;
    margin-left: 1px;
    margin-right: 14px;
}

#antifatigue .fatigue-slide1 ul li svg {
    fill: #f39571;
    vertical-align: middle;
}

#antifatigue .fatigue-slide1 ul li strong {
    color: #f39571;
    font-size: 15px;
}

#antifatigue .fatigue-slide1 .footnote p {
    width: 285px;   
    font-size: 11px;
    font-weight: 500;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
}

.fatigue-slide .care-btn {
    margin-top: 37px;
}

.circles {
	display: flex;
}

.circle {
	cursor: pointer;
	width: 14px;
	height: 14px;
	background: none;
	border-radius: 50%;
	margin: 5px;
	background: #fff;
    border: 1px solid #f39571;
}

.circle.current {
	background: #f39571;
}

#flaws .slider-control-bottomcenter {
	bottom: -25px!important;
}

.fatigue-slide2-inner {
    display: flex;
    max-width: 700px;
    margin: 0 auto;
}

.fatigue-slide2-inner>div{
    display: flex;
    flex-direction: column;
    padding-right: 6px;
    position: relative;
    flex: 1;
    align-items: center;
}

.fatigue-slide2-inner>div:last-of-type {
    padding-right: 0;
}

.fatigue-slide2-inner figcaption {
    display: flex;
    text-align: left;
    margin-top: 30px;
    font-size: 15px;
}

.fatigue-slide2-inner figcaption span {
    line-height: 1;
    font-size: 30px;
    font-weight: 700;
    color: #f39571;
    padding-right: 10px;
    font-family: 'Montserrat', sans-serif;
}

.corner-icon {
    position: absolute;
    top: 6px;
    left: 5px;
    width: 64px;
    height: 64px;
}

.fatigue-slide3 .before-after {
    position: relative;
    width: 920px;
    height: 360px;
}

.fatigue-slide.fatigue-slide3 h3 {
    padding: 0;
}

.fatigue-slide3 p {
    font-size: 15px;
    text-transform: uppercase;
    color: #f39571;
    margin-bottom: 28px;
}   

.fatigue-slide3 .before-after svg {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 10;
    width: 66px;
    height: 66px;
}

.fatigue-slide5 img {
    margin-top: 55px;
    max-height: 470px;
}

.styles_handle__33IZp {
    width: 72px !important;
    height: 100%;
    cursor: pointer;
    background: url('https://www.rowenta.com/medias/picto-before-after.png?context=bWFzdGVyfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2lufDEwODF8aW1hZ2UvcG5nfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2luL2gyZC9oMjQvMTM3OTM0NzY2NzM1NjYucG5nfDExMTA2NmFlNTk2YWY2NjRmOGRjZWM2YTkxOGI1YmNhYmRhMDViNDQ1ZjFkOTNkMmFhM2Q4NjMzOTlmZGM2Njk') no-repeat !important;
    background-size: 100% auto !important;
    background-position: bottom 10px left 0 !important;
    position: absolute !important;
    z-index: 8;
    left: calc(50% - 36px);
    top: 0 !important;
}

.styles_handle__33IZp:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 0;
}

@media screen and (max-width: 1100px) {
    #antifatigue .carouselWrapper {
        width: 100%;
    }
    .sliderArrows {
        display: none;
      }
}




@media screen and (max-width: 768px) {

    #antifatigue {
        padding-bottom: 25px;
    }

    .sliderArrows {
        display: block;
      }
	
	.leftArrow, .rightArrow {
		width: 10px;
	}

    #antifatigue .carouselWrapper {
        width: 100%;
    }

    .antifatigue_header {
        padding: 24px 0 1px;
    }

    .antifatigue_header p {
        font-size: 12px;
        margin-top: 4px;
        padding: 0 10px;
    }

    #antifatigue .carouselWrapper {
        top: 0;
    }

    .antifatigue_h2 {
        font-size: 28px;
        margin: 0 0 8px;
    }

    .fatigue-non-slide {
        width: 100%;
        height: 100%;
        max-width: 100%;
        border: 7px solid #f39571;
        box-sizing: border-box;     
    }

    .fatigue-slide1-inner {
        flex-direction: column;
    }

    .fatigue-slide3 .before-after {
        width: 100%;
        height: auto;
    }

    .fatigue-slide3 .before-after svg {
        overflow: hidden;
    }
   
    .fatigue-slide3 .before-after svg {
        top: 9px;
        left: 6px;
        width: 40px;
        height: 40px;
    }

    .fatigue-slide-header {
        height: auto;
        padding: 13px 0;
    }

    .fatigue-slide-header p {
        font-size: 11px;
    }

    .fatigue-non-slide h3 {
        font-size: 25px;
        margin: 5px 0 0;
        color: #f39571;
        font-family: 'PT Serif', serif;
        font-weight: 700;
    }

    .fatigue-slide3 p {
        font-size: 11px;
        margin-bottom: 10px;
    }

    .styles_wrapper__3KXDn {
        top: 0;
        left: 0;
    }

    .fatigue-slide1-list {
        padding-left: 0;
        padding-top: 2px;
    }

    .fatigue-slide4-content {
        flex-direction: column;
    }

    .fatigue-slide5 img {
        margin: 0 0 -4px;
        max-height: none;
    }

    .fatigue-slide1 li.brush-about-x7 svg {
        width: 19px;margin-right: 20px;
    }

    .fatigue-slide1 li.brush-about-skin svg {
        height: 28px;margin-left: -12px;margin-right: 3px;
    }

    .fatigue-slide1 li.brush-about-complexion svg {
        height: 34px;margin-left: -12px;margin-right: 2px;
    }

    .fatigue-slide1 li.brush-about-waterproof svg {
        width: 29px;height: 27px;margin-left: -1px;margin-right: 11px;
    }

    .fatigue-slide1-list ul {
        margin-bottom: 0;
    }

    .fatigue-slide1-list li {
        font-size: 12px;
        max-height: 20px;
    }

    #antifatigue .fatigue-slide1 .footnote p {
        text-align: left;
        width: 86%;
        margin: 12px auto;
    }

    .fatigue-non-slide .care-btn {
       font-size: 12px;
       margin-top: 37px;
    }

    .fatigue-non-slide.fatigue-slide1 .care-btn {
        margin-top: 20px;
        width: 86%;
        margin: 12px auto;
    }

    #antifatigue .fatigue-slide1 ul li strong {
        font-size: 12px;
    }

    #antifatigue .slider-slide {
        padding: 0;
    }

    .corner-icon {
        top: 10px;
        left: 9px;
        width: 41px;
        height: 41px;
    }

	#antifatigue .slider-control-centerleft {
		left: 12px !important;
		top: 41% !important;
	}
	
	#antifatigue .slider-control-centerright {
		right: 12px !important;
		top: 41% !important;
	}	
   
    .fatigue-slide2 .leftArrow, .rightArrow {
		width: 18px;
	}

    .fatigue-slide2-inner figcaption {
        display: block;
        text-align: center;
        font-size: 13px;
        font-family: 'PT Serif', serif;
        font-weight: 100;
        line-height: 1.4;
        width: 67%;
        margin: 21px auto 0;
    }

    #flaws .slider-control-bottomcenter {
        bottom: -33px!important;
    }

    .circle {
        width: 8px;
        height: 8px;
    }

    .fatigue-non-slide.fatigue-slide2 .care-btn {
        margin: 49px auto 20px;
        width: 86%;
    }

    .fatigue-slide2-inner figcaption span {
        font-size: 20px;
    }

    .fatigue-non-slide.fatigue-slide3 .care-btn {
        width: 86%;
        margin: 20px auto;
    }

	
	
}