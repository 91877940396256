#radiance .slider-control-centerleft {
	left: -70px !important;
    top: 54% !important;
    transform: translateY(-54%)!important;
}

#radiance .slider-control-centerright {
	right: -70px !important;
    top: 54% !important;
    transform: translateY(-54%)!important;
}

#radiance .slider-control-centerleft button,
#radiance .slider-control-centerright button {
	border: none !important;
	background: transparent !important;
    
}

#radiance .slider-slide {
	display: flex!important;
	flex-direction: column;
	align-items: center;
	height: 100%!important;
}

.radiance-slide1-inner {
    display: flex;
    align-items: center;
}

#radiance .slider-slide {
    padding:5px 42px;
}

#radiance {
    text-align: center;
    padding-bottom: 80px;
}

.radiance_header {
    background-color: #b58874;
    color: #fff;
    padding: 16px calc(50% - 460px) 7px;
}

.radiance_header p {
    font-size: 14px;
    margin-top: 10px;
}

.radiance_h2 {
    font-size: 45px;
    margin: 0 0 10px;
    font-family: 'PT Serif', serif;
}

#radiance .carouselWrapper {
    position: relative;
    width: 920px;
    margin: 0 auto -25px;
}

.radiance-slide-header {
    height: 11vw;
    padding-top: 3vw;
}

.radiance-slide-header p {
    font-size: 15px;
    text-transform: uppercase;
    color: #b58874;
    margin-top: 9px;
}

.radiance-slide h3 {
    font-size: 35px;
    margin: 5px 0 0;
    color: #b58874;
    font-family: 'PT Serif', serif;
    font-weight: 700;
}

.radiance-slide {
    width: 920px;
    max-width: 920px; 
    background-color: #fff;
    color: #323e48;
}    

.radiance-slide1-list {
    text-align: left;
    padding-left: 57px;
    padding-top: 50px;
}

.radiance-slide1-list ul {
    margin-bottom: 65px;
}

.radiance-slide1-list li {
    list-style: none;
    margin-bottom: 16px;
    position: relative;
    font-size: 15px;
    max-height: 26px;
}

.radiance-slide1 li.brush-about-x7 svg {
    width: 26px;
    height: 27px;
    margin-right: 26px;
}

.radiance-slide1 li.brush-about-radiance svg {
    width: 47px;
    height: 35px;
    margin-left: -10px;
    margin-right: 13px;
}

.radiance-slide1 li.brush-about-complexion svg {
    width: 50px;
    height: 47px;
    margin-left: -5px;
    margin-right: 6px;
}

.radiance-slide1 li.brush-about-waterproof svg {
    width: 38px;
    height: 42px;
    margin-left: 1px;
    margin-right: 14px;
}

#radiance .radiance-slide1 ul li svg {
    fill: #b58874;
    vertical-align: middle;
}

#radiance .radiance-slide1 ul li strong {
    color: #b58874;
    font-size: 15px;
}

#radiance .radiance-slide1 .footnote p {
    width: 285px;   
    font-size: 11px;
    font-weight: 500;
    color: #323e48;
    font-family: 'Montserrat', sans-serif;
}

#radiance .care-btn {
    background-color: #b58874;
}

.radiance-slide .care-btn {
    margin-top: 37px;
    background-color: #b58874;
}

.circles {
	display: flex;
}

.circle {
	cursor: pointer;
	width: 14px;
	height: 14px;
	background: none;
	border-radius: 50%;
	margin: 5px;
	background: #fff;
    border: 1px solid #b58874;
}

.circle.current {
	background: #b58874;
}

#radiance .slider-control-bottomcenter {
	bottom: -25px!important;
}

.radiance-slide2-inner {
    display: flex;
}

.radiance-slide2-inner>div{
    display: flex;
    flex-direction: column;
    padding-right: 6px;
    position: relative;
    flex: 1;
    align-items: center;
}

.radiance-slide2-inner>div:last-of-type {
    padding-right: 0;
}

.radiance-slide2-inner figcaption {
    display: flex;
    text-align: left;
    margin-top: 30px;
    font-size: 15px;
}

.radiance-slide2-inner figcaption span {
    line-height: 1;
    font-size: 30px;
    font-weight: 700;
    color: #b58874;
    padding-right: 10px;
    font-family: 'Montserrat', sans-serif;
}

.corner-icon {
    position: absolute;
    top: 6px;
    left: 5px;
    width: 64px;
    height: 64px;
}

.radiance-slide3 .before-after {
    position: relative;
    width: 920px;
    height: 360px;
}

.radiance-slide.radiance-slide3 h3 {
    padding: 0;
}

.radiance-slide3 p {
    font-size: 15px;
    text-transform: uppercase;
    color: #b58874;
    margin-bottom: 28px;
}   

.radiance-slide3 .before-after svg {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 10;
    width: 66px;
    height: 66px;
}

.radiance-slide5 img {
    margin-top: 55px;
    max-height: 470px;
}

.styles_handle__33IZp {
    width: 72px !important;
    height: 100%;
    cursor: pointer;
    background: url('https://www.rowenta.com/medias/picto-before-after.png?context=bWFzdGVyfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2lufDEwODF8aW1hZ2UvcG5nfG11ei1yb3dlbnRhLWJlYXV0eS1yb3V0aW5lLWdsb3dza2luL2gyZC9oMjQvMTM3OTM0NzY2NzM1NjYucG5nfDExMTA2NmFlNTk2YWY2NjRmOGRjZWM2YTkxOGI1YmNhYmRhMDViNDQ1ZjFkOTNkMmFhM2Q4NjMzOTlmZGM2Njk') no-repeat !important;
    background-size: 100% auto !important;
    background-position: bottom 10px left 0 !important;
    position: absolute !important;
    z-index: 8;
    left: calc(50% - 36px);
    top: 0 !important;
}

.styles_handle__33IZp:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 0;
}

@media screen and (max-width: 1100px) {
    .radiance-slide-header {height: 14vw; }
}

@media screen and (max-width: 980px) {
    #radiance .carouselWrapper {
        width: 100%;
    }

    #radiance .slider-slide {padding: 0;}    
    .radiance-slide {width: 100%;}
    #radiance .slider-control-centerleft {display: none; }    
    #radiance .slider-control-centerright {display: none; }
}

@media screen and (max-width: 768px) {

    #radiance {
        padding-bottom: 0;
    }
	
	.leftArrow, .rightArrow {
		width: 10px;
        display: block;
	}

    #radiance .carouselWrapper {
        width: 100%;
    }

    .radiance_header {
        padding: 24px 0 1px;
    }

    .radiance_header p {
        font-size: 12px;
        margin-top: 4px;
    }

    .radiance_h2 {
        font-size: 28px;
        margin: 0 0 8px;
    }

    .radiance-non-slide {
        width: 100%;
        height: 100%;
        max-width: 100%;
        border: none;
        padding: 0 8px;
        border-bottom: 9px solid #b58874;   
        box-sizing: border-box;     
    }

    .radiance-slide1-inner {
        flex-direction: column;
    }

    .radiance-slide3 .before-after {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .radiance-slide3 .before-after svg {
        overflow: hidden;
    }
   
    .radiance-slide3 .before-after svg {
        top: 9px;
        left: 6px;
        width: 46px;
        height: 46px;
    }

    .radiance-slide-header {
        height: auto;
        padding: 4vw 0;
    }

    .radiance-slide-header p {
        font-size: 11px;
    }

    .radiance-non-slide h3 {
        font-size: 25px;
        margin: 5px 0 0;
        color: #b58874;
        font-family: 'PT Serif', serif;
        font-weight: 700;
    }

    .radiance-slide3 p {
        font-size: 11px;
        margin-bottom: 10px;
    }

    .radiance-slide5 img {
        margin: 0 0 -4px;
        max-height: none;
    }

    .styles_wrapper__3KXDn {
        top: 0;
        left: 0;
    }

    .radiance-slide1-list {
        padding-left: 0;
        padding-top: 2px;
    }

    .radiance-slide4-content {
        flex-direction: column;
    }

    .radiance-slide1 li.brush-about-x7 svg {
        width: 19px;margin-right: 20px;
    }

    .radiance-slide1 li.brush-about-radiance svg {
        max-width:30px; height: 28px;margin-left: -12px;margin-right: 22px;
    }

    .radiance-slide1 li.brush-about-complexion svg {
        height: 34px;margin-left: -12px;margin-right: 2px;
    }

    .radiance-slide1 li.brush-about-waterproof svg {
        height: 27px;margin-left: -5px;margin-right: 9px;
    }

    .radiance-slide1-list ul {
        margin-bottom: 0;
    }

    .radiance-slide1-list li {
        font-size: 12px;
        max-height: 20px;
    }

    #radiance .radiance-slide1 .footnote p {
        text-align: left;
        width: 86%;
        margin: 7px auto 23px;
        font-size: 8px;
    }

    .radiance-non-slide .care-btn {
       font-size: 12px;
       margin-top: 37px;
    }

    .radiance-non-slide.radiance-slide1 .care-btn {
        margin-top: 20px;
        width: 86%;
        margin: 12px auto;
    }

    #radiance .radiance-slide1 ul li strong {
        font-size: 12px;
    }

    #radiance .slider-slide {
        padding: 0;
    }

    .corner-icon {
        top: 10px;
        left: 9px;
        width: 41px;
        height: 41px;
    }

	#radiance .slider-control-centerleft {
		left: 12px !important;
		top: 41% !important;
        display: block;
	}
	
	#radiance .slider-control-centerright {
		right: 12px !important;
		top: 41% !important;
        display: block;
	}	

    .radiance-slide2 .leftArrow, .rightArrow {
		width: 18px;
	}

    .radiance-slide2-inner figcaption {
        display: block;
        text-align: center;
        margin-top: 21px;
        font-size: 13px;
        font-family: 'PT Serif', serif;
        font-weight: 100;
        line-height: 1.4;
    }

    #radiance .slider-control-bottomcenter {
        bottom: -33px!important;
    }

    .circle {
        width: 8px;
        height: 8px;
    }

    .radiance-non-slide.radiance-slide2 .care-btn {
        margin: 49px auto 30px;
        width: 86%;
    }

    .radiance-slide2-inner figcaption span {
        font-size: 20px;
    }

    .radiance-non-slide.radiance-slide3 .care-btn {
        width: 86%;
        margin: 30px auto;
    }

	
	
}

@media screen and (min-width: 1200px) {
    .radiance-slide-header {padding-top: 2.3vw; }
    .radiance-slide-header {height: 10vw; }
}

@media screen and (min-width: 1450px) {
    .radiance-slide-header {height: 8.5vw; }
}

@media screen and (min-width: 1750px) {
    .radiance-slide-header {height: 8vw; }
}