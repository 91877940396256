.buttonContainer {
    display: flex;
    justify-content: center;
}
.buttonContainer .retailers {
    padding: 1em;
    text-align: center;
}
.buttonContainer .retailers:first-child {
    border-right: 1px solid #dbb68c;
}
.buttonContainer .retailers img {
    height: 60px;
}
.buttonContainer .buyOnlineButton {
    margin: 10px;
    white-space: nowrap;
}

@media screen and (max-width: 768px) {
    .buttonContainer .retailers {
        flex: 1;
    }
    .buttonContainer .buyOnlineButton {
        height: 35px;
        width: auto;
        padding: 0 1em;
        margin: 10px 0;
    }
    .flawsMobileBorder.buttonContainer {
        margin: 10px 10px 20px!important;
    }
    .flawsMobileBorder.buttonContainer .retailers {
        padding: 5px;
    }
}