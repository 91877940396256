.lcm-header {
  background-color: #dbb68c;
  color: #fff;
  padding-bottom: 8px;
  padding-top: 15px;
  text-align: center;
}

#lift h2,
#lift h3 {
  font-family: "PT Serif", serif;
}

#lift article {
  position: relative;
}

.lcm-header h2 {
  font-size: 45px;
  margin-top: 0;
  margin-bottom: 15px;
}

.lcm-header p {
  font-weight: 100;
}

.lcm-upper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0;
  align-items: center;
}

#lift article h3 {
  color: #dbb68c;
  font-size: 35px;
  grid-column: 2;
  text-align: center;
}

.lcm-main {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

#lift .buttonContainer {
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0px 50px;
}

.lcm-image-container {
  text-align: right;
  position: absolute;
  right: 5vw;
  top: 1vw;
}
.lcm-image-container img {
  width: 16vw;
  max-width: 200px;
}

#lift .main-image-container {
  width: 100%;
  cursor: pointer;
  opacity: 0.8;
  max-width: 700px;
  margin: 0 auto;
}

#lift .main-image-container:hover {
  opacity: 1;
}

#lift .main-image-container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 42%;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 18px 0 18px 26px;
  z-index: 5;
  transform: translate(-35%, -50%);
}

#lift .main-image-container::after {
  background-color: #dbb68c;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 3;
  content: "";
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
}

#lift .main-image-container:hover::after {
  background-color: #ac7f68;
}

#lift .video-thumbnail {
  width: 100%;
}
.lcm-video-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0;
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
}

.lcm-video {
  border: 0;
  border-radius: 5px;
}

#lift .divider {
  width: 100%;
  height: 16px;
  background-color: #dbb68c;
}

#lift .liftComfort-image-container a {
  color: black;
  display: block;
}



@media screen and (max-width: 1200px) {
  .lcm-main {
    grid-template-columns: 1fr 6fr 1fr;
    padding: 0 4%;
  }
  #lift .main-image-container::before {
    /* top: 53%; */
    border-width: 11px 0 11px 19px;
  }
  #lift .main-image-container::after {
    /* top: 53%; */
  }
  .lcm-image-container {right: 3vw;  } 
  .lcm-image-container img {
    width: 13vw;
  }
}

@media screen and (max-width: 1000px) {
  .lcm-image-container {right: 1vw;  } 
  
}

@media screen and (max-width: 600px) {
  .negativeMargin.buttonContainer {margin-left: -15px!important;margin-right: -15px!important;}

  .lcm-header h2 {
    font-size: 32px;
    line-height: 1;
  }
  .lcm-header p {
    font-size: 14px;
  }
  .lcm-main {
    grid-template-columns: 1fr !important;
    padding: 0 4%;
  }
  .lcm-image-container {
    position: relative;
  }
  .lcm-image-container img {
    width: 28vw;
    max-width: 145px
  }

  #lift article h3 {
    font-size: 30px;
    margin: 0;
  }

  #lift .main-image-container::before {
    top: 44%;
    border-width: 11px 0 11px 19px;
  }
  #lift .main-image-container::after {
    top: 44%;
    width: 50px;
    height: 50px;
  }

  #lift .buttonContainer {
    margin: 10px 0 30px;
  }
  #lift .divider {
    height: 12px;
  }
 
  #lift .liftComfort-image-container {
    height: 470px;
    position: relative;
    overflow: hidden;
  }
  #lift .liftComfort-image-container img {
    height: 400px;
    max-width: none;
    position: absolute;
    left: 0%;
    overflow: hidden;
  }
  #lift .liftComfort-image-container figcaption {
    position: absolute;
    bottom: 27px;
    left: 10%;
  }
  #lift .caption-2 {
    bottom: 13px !important;
  }
  .liftComfort-image-1 {
    width: 160% !important;
    left: -21% !important;
  }
  .liftComfort-image-2 {
    width: 153% !important;
    left: -27% !important;
  }
}

@media screen and (min-width: 1600px) {
  .lcm-image-container {
    right: 13vw;
  }
}

@media screen and (min-width: 1800px) {
  .lcm-image-container {
    right: 17vw;
  }
}
