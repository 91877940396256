#glow {
  font-family: "Montserrat", sans-serif;
}

/* HEADER */
.Glow {
  height: 21.875vw;
  min-height: 420px;
  background-color: #e9e4e2;
  padding-top: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#glow .glow-h1 {
  margin-bottom: 8px;
}
#glow .glow-h1 svg {
  width: 200px;
  margin-top: 23px;
}

.GlowInner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
}
.GlowInnerContent {
  margin-top: -45px;
}

.GlowInnerContent .glow-underH1 {
  color: #323e48;
  max-width: 450px;
  font-size: 14px;
  position: relative;
  padding-bottom: 22px;
}

.GlowInnerContent .glow-underH1:after {
  content: "";
  width: 45px;
  height: 1px;
  background-color: #323e48;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
.GlowInnerContent .choose {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "PT Serif", serif;
  color: #323e48;
  margin-top: 24px;
}

.muses-parent {
  display: flex;
  justify-content: space-between;
  max-height: 100%;
}
#glow .glow-brand-muse-noflaws,
#glow .glow-brand-muse-glowskin {
  display: flex;
  margin: 0;
}

#glow .glow-brand-muse-glowskin {
  justify-content: flex-end;
}

#glow .Glow figcaption {
  font-size: 1.6em;
  margin-top: 6vw;
  line-height: 1;
  font-weight: 700;
  font-family: "PT Serif", serif;
}

#glow .glow-brand-muse-noflaws figcaption {
  margin-left: -9vw;
  color: #f39571;
  z-index: 8;
}
#glow .glow-brand-muse-glowskin figcaption {
  margin-right: -14vw;
  color: #b58874;
  z-index: 8;
}

.Glow nav {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Glow nav a {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 350px;
}

.Glow nav a.glow-link-noflaws {
  background-color: #f39571;
  margin-right: 20px;
}
.Glow nav a.glow-link-glowskin {
  background-color: #b58874;
  margin-left: 20px;
}

.Glow nav a img {
  width: 60px;
  height: 65px;
}

.Glow nav a h2 {
  font-size: 22px;
  white-space: nowrap;
  color: #fff;
  margin: 0;
  font-family: "PT Serif", serif;
  line-height: 0.9;
  padding-left: 20px;
}

.Glow nav h2 span {
  font-size: 14px;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .Glow {
    height: auto;
  }
  .GlowInnerContent .glow-underH1 {
    max-width: 250px;
  }
  #glow .glow-brand-muse-noflaws,
  #glow .glow-brand-muse-glowskin {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  #glow .Glow figcaption {
    margin: 1em 0 0 !important;
  }

  #glow .muses-parent img {
    order: 1;
  }
}

@media screen and (max-width: 1100px) {
  .navigation-wrapper a {
    margin: 0 2px;
    height: 70px;
  }
  .navigation-wrapper a h2 {
    font-size: 18px;
    white-space: normal;
  }
  .navigation-wrapper a h2 span {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .navigation-wrapper a img {
    display: none;
  }
  .navigation-wrapper .nav-wrapper {
    justify-content: center;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .navigation-block {
    position: absolute;
    bottom: 0;
  }
  .navigation-wrapper a {
    margin: 0;
  }
  .navigation-wrapper a h2 {
    font-size: 16px;
    line-height: 1;
  }
  .navigation-wrapper a h2 span {
    font-size: 10px;
    line-height: 1.2;
    padding-top: 5px;
  }
  .Glow {
    display: flex;
    flex-direction: column;
  }
  .GlowInner {
    order: 1;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    margin-bottom: 80px;
  }
  .GlowInnerContent {
    margin-top: -28px;
  }
  #glow .glow-h1 svg {
    width: 180px;
  }
  #glow .Glow figcaption {
    font-size: 1em;
  }
  .GlowInnerContent .glow-underH1 {
    max-width: 90%;
    margin: 0 auto;
    font-size: 12px;
    padding-bottom: 18px;
  }
  .GlowInnerContent .choose {
    font-size: 14px;
    margin-top: 18px;
  }
  .Glow nav {
    order: 2;
    position: relative;
    margin-top: 10px;
  }
  .Glow nav a.glow-link-noflaws {
    margin: 0;
  }
  .Glow nav a.glow-link-glowskin {
    margin: 0;
  }
  .Glow nav a {
    max-height: 60px;
  }
  .Glow nav a h2 {
    font-size: 14px;
    white-space: normal;
    padding-left: 10px;
  }
  .Glow nav a h2 span {
    font-size: 9px;
  }
  .Glow nav a img {
    width: 56px;
    height: 60px;
  }
}

@media screen and (min-width: 1300px) {
  #glow .glow-brand-muse-noflaws figcaption {
    margin-left: -6vw;
  }
  #glow .glow-brand-muse-glowskin figcaption {
    margin-right: -11vw;
  }
}

@media screen and (min-width: 1500px) {
  #glow .glow-brand-muse-noflaws figcaption {
    margin-left: -5vw;
  }
  #glow .glow-brand-muse-glowskin figcaption {
    margin-right: -8vw;
  }
}

/* END OF HEADER  */

#glow .radiance_header {
  padding: 22px calc(50% - 460px) 102px;
}
#glow .carouselWrapper {
  margin-top: -75px;
}
#glow .faqFacialSection-content .eyeTab,
#glow .faqFacialSection-content .facialTab {
  border: 3px solid #b58874 !important;
}
#glow .faqFacialSection-content .keyvalue_accordion_header h4,
#glow .faqFacialSection-content .keyvalue_accordion_content_left h4 {
  color: #b58874 !important;
}
#glow .faqFacialSection-content .keyvalue_accordion_item .close {
  color: #b58874 !important;
  border: 1px solid #b58874 !important;
  background-color: #fff !important;
}
#glow .faqFacialSection-content .keyvalue_accordion_item.true .close {
  background-color: #b58874 !important;
  color: #fff !important;
  border: 1px solid #b58874 !important;
}

#glow .faqFacialSection-content .keyvalue_accordion_inner {
  border-bottom: 1px solid #b58874 !important;
}

@media screen and (max-width: 768px) {
  #glow .radiance_header {
    padding: 24px 26px 1px;
  }
  #glow .carouselWrapper {
    margin-top: 0;
  }
  #glow .radiance-non-slide {
    border: solid #b58874;
    border-width: 7px 7px 3.5px;
    padding: 0;
  }
  #glow .faqFacialSection-content .toggle div img {
    height: 48px;
    display: block;
    margin-right: 2px;
  }
  #glow .faqFacialSection-content .toggle a {
    flex: 1;
  }
  #glow .faqFacialSection-content .toggle a div {
    width: auto;
    height: unset;
    padding: 0 4px 0 0;
    font-size: 13px;
  }
}

/*   NO FLAWS BANNER  */
.no-flaws-banner {
  background-color: #fdece7;
  padding: 128px 0 85px;
  margin-bottom: 30px;
  margin-top: 70px;
  color: #323e48;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 17px;
  text-align: center;
  position: relative;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.no-flaws-banner .figure {
  overflow: hidden;
  margin: 0;
  z-index: 1;
}

.no-flaws-banner .collection-poster {
  width: 240px;
  transform: rotate(6deg);
  position: absolute;
  top: 70px;
  left: calc(50% + 220px);
}

.no-flaws-banner .collection-eye-massager {
  width: 45px;
  transform: rotate(-96deg);
  position: absolute;
  top: -70px;
  left: calc(50% + 270px);
}

.no-flaws-banner .collection-brush {
  width: 125px;
  transform: rotate(-11deg);
  position: absolute;
  top: 100px;
  right: calc(50% + 300px);
}

.no-flaws-banner h2 {
  position: relative;
  color: #f39571;
  margin: 0 auto 15px;
  max-width: 450px;
  font-size: 45px;
  font-family: "PT Serif", serif;
  font-weight: 700;
  overflow-wrap: break-word;
  line-height: 1;
}

.no-flaws-banner p {
  margin-bottom: 25px;
}

.no-flaws-banner .facial-care-btn {
  background-color: #f39571;
}

@media screen and (max-width: 768px) {
  .no-flaws-banner {
    padding: 65px 60px 40px;
    margin-bottom: 9px;
    margin-top: 0;
  }

  .no-flaws-banner .collection-poster {
    width: 170px;
    position: absolute;
    top: 45px;
    left: calc(50% + 50px);
  }

  .no-flaws-banner .collection-eye-massager {
    width: 30px;
    position: absolute;
    top: -52px;
    left: calc(50% + 60px);
  }

  .no-flaws-banner .collection-brush {
    width: 83px;
    position: absolute;
    top: 60px;
    right: calc(50% + 86px);
  }

  .no-flaws-banner h2 {
    max-width: 200px;
    margin-bottom: 10px;
    line-height: 1;
    font-size: 30px;
  }

  .no-flaws-banner .figure img {
    z-index: 1;
  }

  .no-flaws-banner h2,
  .no-flaws-banner p,
  .no-flaws-banner .facial-care-btn {
    z-index: 3;
    position: relative;
  }

  .no-flaws-banner p {
    font-size: 12px;
    margin-bottom: 14px;
  }

  .no-flaws-banner .facial-care-btn {
    font-size: 12px;
    width: 86%;
    margin: 0 auto;
  }
}
