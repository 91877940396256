#facialHeader {
    font-family: 'Montserrat', sans-serif;background-color: #e9e4e2;
}

.FacialHeader {
    position: relative;
}

#facialHeader h1 {
    margin-bottom: 8px;
}

#facialHeader h1 svg {
    width: 200px;
    margin-top: 23px;
}

.FacialHeaderInner  {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%,-50px);
    text-align: center;    
    z-index: 10;
}

.FacialHeaderInnerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FacialHeaderInnerContent .underH1 {
    color: #323e48;
    max-width: 450px;
    font-size: 14px;
}

.FacialHeader nav {
    height: 70px;
    display: flex;
    width: 90%;
    margin: 0 auto;
}

.FacialHeader nav a {   
    line-height: 1;
    font-size: 14px;
    color: #fff;
    text-align: center;
    flex: 1;
}

.FacialHeader .nav-p {
    line-height: 1;
    font-size: 19px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'PT Serif', serif;
    margin: 3.5em 0 1.8em;
    color: #323e48;
    text-align: center;
    position: relative;
}

.nav-p:before {
    content: "";
    width: 45px;
    height: 1px;
    background-color: #323e48;
    position: absolute;
    top: -2.2em;
    left: 50%;
    transform: translate(-50%);
}

.FacialHeader nav a:nth-child(1) { background-color: #f39571;  }
.FacialHeader nav a:nth-child(2) { background-color: #b58874;  }
.FacialHeader nav a:nth-child(3) { background-color: #dbb68c;  }
.FacialHeader nav a:nth-child(4) { background-color: #a49dc2;  }

.FacialHeader nav a h2 {
    font-size: 22px;
    white-space: nowrap;
    color: #fff;
    margin: 0;
    line-height: 40px;
    font-family: 'PT Serif', serif;
}


#facialHeader .slider {overflow: hidden;}
.sliderArrows {
    outline: none;
    border: none;
    background: transparent;
}
#facialHeader .slider-control-centerleft {display: none;}
#facialHeader .slider-control-centerright {display: none;}
.headerSlide {
    width: auto;
    height: auto;
    min-height: 290px;
    overflow: hidden;
    position: relative;
}
.headerSlide figcaption {display: none;}
#facialHeader .slider-list li:nth-child(1) .headerSlide {background-color:#f0ece9;}
#facialHeader .slider-list li:nth-child(2) .headerSlide {background-color:#fff0de;}
#facialHeader .slider-list li:nth-child(3) .headerSlide {background-color:#fdece7;}
#facialHeader .slider-list li .headerSlide:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 8;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
}

#facialHeader .slider-list li:nth-child(1) .headerSlide:before { border:5px solid #b58874;}
#facialHeader .slider-list li:nth-child(2) .headerSlide:before { border:5px solid #dbb68c;}
#facialHeader .slider-list li:nth-child(3) .headerSlide:before { border:5px solid #f39571;}

#facialHeader .headerSlide figcaption { margin-top: 18px;}
#facialHeader .slider-list li:nth-child(1) .headerSlide figcaption { color:#b58874;}
#facialHeader .slider-list li:nth-child(2) .headerSlide figcaption { color:#dbb68c;}
#facialHeader .slider-list li:nth-child(3) .headerSlide figcaption { color:#f39571;}

.headerSlide>img {
    position: absolute;
    z-index: 5;
}

.headerSlide2Muse {
    width: 247px;
    left: calc(50vw - 480px);
    top: 23px;
}

.headerSlide2Visual {
    position: absolute;
    z-index: 5;
    top: 45px;
    left: calc(50vw + 250px);
}
.headerSlide3Muse {
    width: 357px;
    left: calc(50vw - 550px);
    top: 12px;
}

.headerSlide3Visual {
    position: absolute;
    z-index: 5;
    left: calc(50vw + 240px);
    top: 34px;
}

.headerSlide1Muse {
    width: 260px;
    left: calc(50vw - 490px);
    top: 15px;
}

.headerSlide1Visual {
    position: absolute;
    z-index: 5;
    top: 45px;
    left: calc(50vw + 250px);
}


@media screen and ( max-width: 1100px ) {
    .sliderArrows {
      display: none;
    }
  }


@media screen and ( max-width: 768px ) {

    .sliderArrows {
        display: block;
      }
    .FacialHeader {
        height: auto;
        min-height: 525px;
        max-height: none;
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .FacialHeader h1 {
        margin: 20px auto 0!important;
    }

    #facialHeader h1 svg {
        margin-top: -15px;
        width: 174px;
    }

    .FacialHeaderInner {
        order: 1;
        width: 90%;
        position: relative;
        top: 0%;
        transform: translate(-50%,0);
    }

    .FacialHeaderInnerContent {
        margin-top: 7px;
    }

    .FacialHeader nav {
        order: 3;
        width: 100%;
    }
    
    .FacialHeader .nav-p {
        font-size: 14px;
        margin: 2.3em 0 1.4em;
        order: 2;
    }

    .FacialHeader .nav-p:before {
        top: -1.6em;
    }

    .FacialHeader nav a {
        font-size: 10px;
        max-width:33.3%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 6%;
    }

    .FacialHeader nav a:nth-child(3) {padding: 0 1%;}

    
    .FacialHeader nav a.link {
        height: 60px;
    }
   
    .FacialHeader nav a h2 {
        font-size: 14px;
        margin-bottom: 4px;
        white-space: normal;
        line-height: 1;
        max-width: 90%;
    }
    
    .FacialHeaderInnerContent .underH1 {
        font-size: 11px;
        max-width: 94%;
    }

    .FacialHeaderInnerContent .underH1:after {
        bottom: -13px;
    }

    #facialHeader .slider-control-centerleft {display:block;left:4vw!important}
    #facialHeader .slider-control-centerright {display:block;right: 4vw!important;}
    #facialHeader .leftArrow, .rightArrow {  width: 18px!important;  }

    .headerSlide {min-height: 220px;}
    .headerSlide figcaption {display: block;}

    .headerSlide2Muse {
        width: 150px;
        left: calc(50vw - 140px);
        top: 55px;
    }
    
    .headerSlide2Visual {
        width: 125px;
        left: 50vw;
        top: 45px;
    }

    .headerSlide3Muse {
        width: 195px;
        left: calc(50vw - 160px);
        top: 55px;
    }
    
    .headerSlide3Visual {
        width: 130px;
        left: 50vw;
        top: 50px;
    }
    
    .headerSlide1Muse {
        width: 150px;
        left: calc(50vw - 140px);
        top: 55px;
    }
    
    .headerSlide1Visual {
        width: 120px;
        left: 50vw;
        top: 50px;
    }
}

@media screen and (min-width: 1100px) {
    #facialHeader .slider-control-centerleft {display:block;left:4vw!important}
    #facialHeader .slider-control-centerright {display:block;right: 4vw!important;}
}

@media screen and (min-width: 1300px) {
    #facialHeader .slider-control-centerleft {left:10vw!important}
    #facialHeader .slider-control-centerright {right: 10vw!important;}
}

@media screen and (min-width: 1500px) {
    #facialHeader .slider-control-centerleft {left:16vw!important}
    #facialHeader .slider-control-centerright {right: 16vw!important;}
}

@media screen and (min-width: 1750px) {
    #facialHeader .slider-control-centerleft {left:20vw!important}
    #facialHeader .slider-control-centerright {right: 20vw!important;}
}