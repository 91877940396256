/*   HEADER SECTION    */
.Flaws {
  height: 21.875vw;
  min-height: 420px;
  background-color: #e9e4e2;
  padding-top: 65px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Flaws h1 {
  margin-bottom: 8px;
}

.Flaws h1 svg {
  width: 200px;
  margin-top: 23px;
}

#header-beauty .background-video {
  width: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  height: 100%;
  overflow: hidden;
  margin: 0;
}
#header-beauty .background-video video,
#glow .background-video video,
#lift .background-video video {
  width: 100%;
  height: auto;
  position: relative;
  min-height: 420px;
  max-width: none;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.FlawsInner {
  position: relative;
  text-align: center;
  z-index: 10;
}

.FlawsInnerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -45px;
}

.Flaws nav {
  position: absolute;
  bottom: 0;
  z-index: 10;
  left: 50%;
  transform: translate(-50%);
}

.Flaws nav span {
  display: inline-block;
  width: 100%;
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "PT Serif", serif;
  margin-bottom: 100px;
  color: #323e48;
}

.FlawsInnerContent .underH1 {
  color: #323e48;
  max-width: 450px;
  font-size: 14px;
}

.FlawsInnerContent .underH1:after {
  content: "";
  width: 45px;
  height: 1px;
  background-color: #323e48;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%);
}
.Flaws nav a figure {
  width: 60px;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

.Flaws nav a figure img {
  width: auto;
  height: 100%;
  max-width: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.Flaws nav a.link-noflaws {
  left: 50%;
  transform: translate(-160%);
  background-color: #f39571;
}

.Flaws nav a.link-glowskin {
  left: 50%;
  transform: translate(-50%);
  background-color: #b58874;
}

.Flaws nav a.link-lift {
  left: 50%;
  transform: translate(58%);
  background-color: #dbb68c;
}

.Flaws nav a h2 {
  font-size: 22px;
  white-space: nowrap;
  color: #fff;
  margin: 0;
  line-height: 40px;
  font-family: "PT Serif", serif;
}

.Flaws nav a {
  position: absolute;
  bottom: 0;
  min-width: 325px;
  line-height: 1;
  font-size: 14px;
  color: #fff;
  text-align: left;
  padding: 2px 10px 14px 85px;
}

/*  NEW NAVIGATION    */
.navigation-block {
  display: grid;
  grid-template-columns: 1fr 333px 333px 333px 1fr;
  grid-template-rows: 20px 20px auto;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  column-gap: 2%;
}

.navigation-block > span {
  text-align: center;
  grid-column: 1 /-1;
  grid-row: 1;
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "PT Serif", serif;
  margin-bottom: 22px;
  color: #323e48;
}

.middle-row-placeholder {
  grid-column: 1 / -1;
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr 333px 333px 333px 1fr;
  column-gap: 2%;
}

.page-indicator {
  width: 100%;
  height: 8px;
  background-color: #bf9001;
  margin-top: 8px;
}

.flaws-page-indicator {
  grid-column: 2;
}

.glow-page-indicator {
  grid-column: 3;
}

.lift-page-indicator {
  grid-column: 4;
}

.navigation-wrapper {
  /* display: flex;
  width: 100%;
  justify-content: center; */
  grid-column: 1 /-1;
  display: grid;
  grid-template-columns: 1fr 333px 333px 333px 1fr;
  column-gap: 2%;
}

.navigation-wrapper a {
  z-index: 20;
  cursor: pointer;
  /* margin: 0 1%; */
  flex: 1;
  max-width: 333px;
}

.navigation-wrapper a:nth-of-type(1) {
  background-color: #f39571;
  grid-column: 2;
}

.navigation-wrapper a:nth-of-type(2) {
  background-color: #b58874;
  grid-column: 3;
}

.navigation-wrapper a:nth-of-type(3) {
  background-color: #dbb68c;
  grid-column: 4;
}

.navigation-wrapper a h2 {
  font-size: 22px;
  white-space: nowrap;
  color: #fff;
  margin: 0;
  font-family: "PT Serif", serif;
  padding-left: 16px;
}

.navigation-wrapper a h2 span {
  line-height: 1.7;
  font-size: 14px;
  color: #fff;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  display: block;
}

.navigation-wrapper .nav-wrapper {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .navigation-block {
    grid-template-columns: 33.33% 33.33% 33.33%;
    column-gap: 0px;
  }
  .middle-row-placeholder {
    grid-column: 1 / -1;
    grid-row: 2;
    grid-template-columns: 33.33% 33.33% 33.33%;
    column-gap: 0px;
  }
  .flaws-page-indicator {
    grid-column: 1;
  }

  .glow-page-indicator {
    grid-column: 2;
  }

  .lift-page-indicator {
    grid-column: 3;
  }
}

@media screen and (max-width: 1100px) {
  .navigation-block {
    grid-template-columns: 33.33% 33.33% 33.33%;
    column-gap: 0px;
  }
  .middle-row-placeholder {
    grid-column: 1 / -1;
    grid-row: 2;
    grid-template-columns: 33.33% 33.33% 33.33%;
    column-gap: 0px;
  }
  .flaws-page-indicator {
    grid-column: 1;
  }

  .glow-page-indicator {
    grid-column: 2;
  }

  .lift-page-indicator {
    grid-column: 3;
  }
  /* .navigation-wrapper a {
    margin: 0 2px;
    height: 70px;
  } */
  .navigation-wrapper {
    grid-template-columns: 33.33% 33.33% 33.33%;
    column-gap: 0px;
  }
  .navigation-wrapper a:nth-of-type(1) {
    grid-column: 1;
  }

  .navigation-wrapper a:nth-of-type(2) {
    grid-column: 2;
  }

  .navigation-wrapper a:nth-of-type(3) {
    grid-column: 3;
  }
  .navigation-wrapper a h2 {
    font-size: 18px;
    white-space: normal;
  }
  .navigation-wrapper a h2 span {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .navigation-wrapper a img {
    display: none;
  }
  .navigation-wrapper .nav-wrapper {
    justify-content: center;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #header-beauty .background-video video,
  #glow .background-video video,
  #lift .background-video video {
    width: auto;
    height: 100%;
  }
  .navigation-wrapper a {
    margin: 0;
  }
  .navigation-wrapper a h2 {
    font-size: 16px;
    line-height: 1;
  }
  .navigation-wrapper a h2 span {
    font-size: 10px;
    line-height: 1.2;
    padding-top: 5px;
  }
  .Flaws {
    height: auto;
    min-height: 525px;
    max-height: none;
    padding: 0;
    position: relative;
  }

  .Flaws h1 {
    /* margin: 233px auto 0!important; */
    margin: 133px auto 0 !important;
  }

  .Flaws h1 svg {
    margin-top: -15px;
    width: 174px;
  }

  .FlawsInnerContent {
    margin-top: 0;
  }

  .Flaws nav {
    position: static;
    transform: translate(0);
    display: inline-block;
    width: 100%;
    font-size: 0;
    padding-bottom: 70px;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
  }

  .Flaws nav span {
    font-size: 14px;
    margin-bottom: 14px;
    text-align: center;
  }

  .Flaws nav a figure {
    width: 50px;
    height: 100%;
    overflow: hidden;
  }

  .Flaws nav a figure img {
    float: right;
    width: auto;
    height: 100%;
    max-width: none;
  }

  .Flaws nav a.link-noflaws {
    left: 50%;
    background-color: #f39571;
    height: 60px;
  }

  .Flaws nav a.link-glowskin {
    left: 50%;
    background-color: #b58874;
  }

  .Flaws nav a h2 {
    font-size: 14px;
    margin-bottom: 4px;
    white-space: normal;
    line-height: 1;
    max-width: 90%;
  }

  .Flaws nav a {
    left: 50%;
    vertical-align: top;
    width: 50%;
    min-width: auto;
    font-size: 8px;
    font-weight: 500;
    padding: 10px 0 10px 56px;
    margin: 0;
  }

  figcaption {
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
    position: relative;
    z-index: 8;
    left: auto;
    right: auto;
    top: auto;
    line-height: 1;
    font-weight: 700;
    font-family: "PT Serif", serif;
  }

  .brand-muse-noflaws figcaption {
    color: #f39571;
  }

  .brand-muse-glowskin figcaption {
    color: #b58874;
  }

  .brand-muse-noflaws {
    float: left;
    width: 50%;
    height: 200px;
    padding-top: 10px;
    margin-bottom: 20px;
    position: relative;
    right: auto;
    left: 0;
    overflow: hidden;
    top: 0;
    margin: 0;
    position: absolute;
  }

  .brand-muse-glowskin {
    float: right;
    width: 50%;
    height: 200px;
    padding-top: 10px;
    margin-bottom: 20px;
    position: relative;
    right: auto;
    right: 0;
    overflow: hidden;
    top: 0;
    margin: 0;
    position: absolute;
  }

  .FlawsInnerContent .underH1 {
    font-size: 11px;
    max-width: 94%;
  }

  .FlawsInnerContent .underH1:after {
    bottom: -13px;
  }
}
