.antifatigue-slide4-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.antifatigue-slide4-content>div {
    flex: 1;   
    position: relative; 
}

.antifatigue-slide4-content-left img {
    height: 360px;
}

#antifatigue .circle-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 1px solid #f39571;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .3s ease;
}

#antifatigue .circle-button svg {
    fill: #f39571;
}

#antifatigue .circle-button:hover svg,
#antifatigue .circle-button.active svg {
    fill: #fff;
}

#antifatigue .circle-button:hover,
#antifatigue .circle-button.active {    
    background-color: #f39571;
}

.antifatigue-slide4-content-right {
    text-align: left;
}

.antifatigue-slide4-content-right .feature .featureDesc {
    display: none;
    opacity: 0;
    width: 77%;
    padding-left: 50px;
}

.antifatigue-slide4-content-right .feature .featureDesc p {
    font-size: 15px;
    line-height: 1.4;
}

.antifatigue-slide4-content-right .feature.active {
    background-color: transparent;
}

.antifatigue-slide4-content-right .feature.active .featureDesc {
    display: block;
    opacity: 1;
}

.antifatigue-slide4-content-right strong {
    color: #f39571;
}


@media screen and (max-width: 768px ) {
    #antifatigue .circle-button {
        width: 40px;
        height: 40px;
    }

    .antifatigue-slide4-content {
        flex-direction: column;
    }

    .antifatigue-slide4-content-left {
        margin-top: 10px;
    }

    .antifatigue-slide4-content-left img {
        padding-right: 30px;
        height: 275px;
    }
    
    .antifatigue-slide4-content-right {
        text-align: center;
        margin-top: 20px;
    }
    
    .antifatigue-slide4-content-right .feature .featureDesc {       
        width: 86%;
        padding-left: 0;
        margin: 0 auto;
        min-height: 58px;
    }
    
    .antifatigue-slide4-content-right .feature .featureDesc p {
        font-size: 12px;
        line-height: 1.2;
    }

    .fatigue-non-slide.fatigue-slide4 .care-btn {
        width: 86%;
        margin: 20px auto;
    }
    
 
}