#antiblemish.FaceContour .slider-slide {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  height: 100% !important;
}

#antiblemish.FaceContour .slider-control-centerright {
  right: -70px !important;
}
#antiblemish.FaceContour .slider-control-centerleft {
  left: -70px !important;
}

#antiblemish.FaceContour {
  text-align: center;
  padding-bottom: 0;
  position: relative;
}
.FaceContour-header {
  width: 100%;
  background-color: #dbb68c;
  color: white;
  height: auto;
  text-align: center;
  font-family: "PT Serif", serif;
}

.FaceContour-header h2 {
  font-size: 45px;
  margin: 0;
}

.FaceContour-header p {
  font-weight: 100;
  margin-top: 10px;
}

.FaceContour-header-inner {
  padding: 15px 0;
}

#lift #antiblemish.FaceContour .clinicallyProven-image-container {
  top: 160px;
}

#lift #antiblemish.FaceContour .carouselWrapper {
  position: relative;
  width: 920px;
  margin: 0 auto;
}

#lift .slider {
  height: auto;
}

.FaceContour-slide {
  width: 920px;
  max-width: 920px;
  background-color: #fff;
  color: #323e48;
}

.FaceContourcover-slide-header {
  height: 90px;
  padding-top: 16px;
  margin: 0 0 25px 0;
}

.clinicallyProven-image-container {
  position: relative;
  z-index: 1;
  left: unset;
  top: 20px;
  text-align: right;
  margin: 0;
  right: 10vw;
}
.clinicallyProven-image-container img {
  width: 110px;
}
.FaceContour-slide h3 {
  font-size: 35px;
  margin: 5px 0 0;
  color: #dbb68c;
  font-family: "PT Serif", serif;
  font-weight: 700;
  text-align: center;
}

.FaceContour-slide1-inner {
  display: flex;
  flex-direction: row;
  margin: 60px 0 0;
}

#lift .buttonContainer {
  display: flex;
  justify-content: center;
}

.FaceContour-image {
  width: 100%;

  object-fit: cover;
}

.FaceContour-image-container {
  float: left;
  width: calc(45% - 20px);
  height: 385px;
  text-align: center;
  overflow: hidden;
}

.FaceContour-image-container.FaceContour-image1 {
  width: calc(55% - 20px);
  height: 400px;
}

#lift figcaption {
  font-family: "Montserrat", serif !important;
}

.FaceContour-mobile-image-container {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

.FaceContour-image1 {
  margin: 0;
}

.FaceContour-image2 {
  margin-top: 20px;
}

.FaceContour-product-image {
  display: inline-block;
  width: auto;
  height: 145px;
  max-height: 100%;
  max-width: none;
}

.FaceContour-image2 figcaption strong {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 55px;
  color: #dbb68c;
}
.FaceContour-image2 figcaption span {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 15px;
  text-align: left;
  padding-left: 8px;
}
.FaceContour-image2 figcaption p {
  font-weight: 500;
  font-size: 15px;
}

.FaceContour-slide-header {
  color: #dbb68c;
  padding-top: 24px;
}
.FaceContour-slide-header p {
  font-weight: 400;
  margin-top: 5px;
  font-size: 14px;
}

.FaceContour-slide2-inner {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  height: 450px;
  margin-top: 30px;
}

.FaceContour-slide2-inner figure {
  height: auto;
  margin: 0;
  overflow: hidden;
  position: relative;
  float: left;
}
.FaceContour-slide2-inner figure img {
  max-width: none !important;
  width: 170%;
  left: -53px;
  top: 0;
  position: relative;
  z-index: 9;
}

.FaceContour-slide2-inner figcaption {
  background-color: #fff;
  padding-top: 25px;
  padding-left: 35px;
  text-align: left;
  position: relative;
  z-index: 10;
  transform: translateY(-58px);
  font-weight: 300;
}

.FaceContour-slide2-inner figcaption strong {
  position: absolute;
  top: 10px;
  left: 2px;
  line-height: 1;
  font-size: 25px;
  color: #dbb68c;
}
.FaceContour-slide2-inner figcaption strong::after {
  content: " /";
  position: relative;
  top: 7.5px;
  font-size: 0.8em;
}

.FaceContour-slide2-inner figcaption span {
  font-size: 14px;
  font-weight: 500;
}

#lift .figure-image-two {
  width: 145% !important;
  left: -46px !important;
}

#lift .figcaption-two {
  transform: translateY(-6px) !important;
}

#lift .figure-image-five {
  width: 145% !important;
  left: -40px !important;
}

#lift .figcaption-five {
  transform: translateY(-6px) !important;
}

#lift #lift.slide2-inner-section {
  width: 50% !important;
  height: auto;
}

.FaceContour-slide2-subtitle {
  text-transform: uppercase;
}

.FaceContour-slide2-subtitle strong {
  margin: 3px 0;
}

#lift .slide2-section2 {
  text-align: left;
  padding: 90px 0 0 50px;
  font-size: 15px;
  font-weight: 100;
}

#lift .slide2-section2 strong {
  color: #dbb68c;
}
#lift .slide2-section2 h4 {
  margin: 0;
  color: #dbb68c;
  text-align: center;
}

#lift .slide3-inner-section {
  width: 100%;
  position: relative;
  height: auto;
  padding-left: 60px;
}

#lift .slide3-image-container {
  width: 250px;
}
#lift .slide3-inner-section figcaption {
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  padding-left: 40%;
  width: 76%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#lift .discover-descripton {
  color: #dbb68c;
  font-size: 15px;
  font-weight: 600;
}

#lift .discover-descripton li {
  color: black;
  margin: 5px 0;
  font-weight: 200;
}
#lift .circle {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: none;
  border-radius: 50%;
  margin: 5px;
  background: #fff;
  border: 1px solid #dbb68c;
}

#lift .circle.current {
  background: #dbb68c;
}

.FaceContour-mobile-slide2-inner div {
  padding: 5px;
}

.FaceContour-mobile-slide2-inner div figcaption {
  width: 100%;
}

.FaceContour-mobile-slide2-inner div figcaption strong {
  line-height: 1;
  font-size: 25px;
  color: #dbb68c;
  margin-right: 10px;
}
.FaceContour-mobile-slide2-inner {
  position: relative;
  height: 620px;
}
.FaceContour-mobile-slide2-inner div figcaption {
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
}
.FaceContour-mobile-slide2-inner div figcaption strong::after {
  content: " /";
  position: relative;
  top: 7.5px;
  font-size: 0.8em;
}

.FaceContour-mobile-slide2-inner div figcaption span {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  color: #323e48;
}

.FaceContour-mobile-slide2-inner div figcaption {
  padding: 28px 23px 0;
}

.FaceContour-mobile-slide2-inner .slider-control-bottomcenter {
  position: relative;
  bottom: 0 !important;
  display: flex;
  justify-content: center;
}
.faceContour-divider {
  margin: 30px 0 12px;
}

.FaceContour-footnote {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

@media screen and (max-width: 1400px) {
  #lift #antiblemish.FaceContour .clinicallyProven-image-container {
    right: 1vw;
  }
}

@media screen and (max-width: 1300px) {
  #lift #antiblemish.FaceContour .clinicallyProven-image-container {
    top: 140px;
    right: 3vw;
  }
  #lift #antiblemish.FaceContour .clinicallyProven-image-container img {
    width: 12vw;
  }
}

@media screen and (max-width: 1100px) {
  #antiblemish.FaceContour .slider-control-centerright {
    right: -45px !important;
  }
  #antiblemish.FaceContour .slider-control-centerleft {
    left: -45px !important;
  }
}

@media screen and (max-width: 1000px) {
  .clinicallyProven-image-container {
    right: 1vw;
  }
}

@media screen and (max-width: 600px) {
  .FaceContour-slide-header {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    padding-top: 0;
  }
  .FaceContour-header-inner {
    padding: 11px 0 9px;
  }
  .FaceContour-header h2 {
    font-size: 35px;
  }
  .FaceContour-slide-header h3 {
    font-size: 28px;
    margin: 0;
    padding: 14px 30px;
    line-height: 1;
    margin-top: 15px;
  }
  .FaceContour-header p {
    margin-top: 2px;
  }
  .FaceContour-slide-header p {
    font-size: 12px;
  }
  #lift #antiblemish.FaceContour .carouselWrapper {
    height: 830px;
    width: 100%;
    margin: -100px auto 60px;
  }

  .FaceContour-product-image {
    height: 80px;
    padding: 10px 0 10px 33px;
    margin-top: 15px;
  }
  .clinically-proven-FaceContour-moblie-one {
    padding-top: 6px;
  }

  .FaceContour-image1 figcaption strong {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-size: 55px;
    color: #dbb68c;
  }
  .FaceContour-image1 figcaption span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-size: 15px;
    text-align: left;
    padding-left: 8px;
  }
  .FaceContour-image1 figcaption p {
    font-weight: 500;
    font-size: 15px;
  }
}

@media screen and (max-width: 980px) {
  #lift .carouselWrapper {
    width: 100%;
  }
  .FaceContour-slide {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #lift #antiblemish.FaceContour {
    padding-bottom: 40px;
  }
  #lift .carouselWrapper {
    width: 100%;
    top: 100px !important;
  }
  #antiblemish.FaceContour .slider-control-centerright {
    right: 15px !important;
  }
  #antiblemish.FaceContour .slider-control-centerleft {
    left: 15px !important;
  }
  #antiblemish.FaceContour .slider-control-centerright .rightArrow {
    width: 18px !important;
  }
  #antiblemish.FaceContour .slider-control-centerleft .leftArrow {
    width: 18px !important;
  }
  
}

@media screen and (min-width: 1600px) {
  #lift #antiblemish.FaceContour .clinicallyProven-image-container {
    right: 10vw;
  }
}

@media screen and (min-width: 1800px) {
  #lift #antiblemish.FaceContour .clinicallyProven-image-container {
    right: 14vw;
  }
}
