#EyeContour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0;
}

#EyeContour.EyeContour .slider-control-centerright {
  right: -70px !important;
}
#EyeContour.EyeContour .slider-control-centerleft {
  left: -70px !important;
}

.EyeContour-header {
  width: 100%;
  background-color: #dbb68c;
  color: white;
  height: auto;
  text-align: center;
}

.EyeContour-header h2 {
  font-size: 45px;
  margin: 0;
}

.EyeContour-header p {
  font-weight: 100;
  margin-top: 10px;
  font-family: "Montserrat", serif;
}

.EyeContour-header-inner {
  padding: 15px 0;
}

#lift .EyeContour .carouselWrapper {
  position: relative;
  width: 920px;
  margin: 20px auto 0;
}

#lift .slider {
  height: auto;
}

.EyeContour-slide {
  width: 920px;
  max-width: 920px;
  background-color: #fff;
  color: #323e48;
}

.EyeContour-slide-header {
  padding-top: 16px;
}

.clinicallyProven-image-container {
  position: relative;
  z-index: 1;
  left: unset;
  top: 20px;
  text-align: right;
  margin: 0;
  right: 10vw;
}
.clinicallyProven-image-container img {
  width: 110px;
}
.EyeContour-slide h3 {
  font-size: 35px;
  margin: 5px 0 0;
  color: #dbb68c;
  font-family: "PT Serif", serif;
  font-weight: 700;
}

.EyeContour-slide1-inner {
  display: flex;
  flex-direction: row;
  margin: 60px 0 0;
}

#lift .buttonContainer-slide1 {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
/* .footnote {
  float: left;
  width: 100%;
  height: 0;
  margin: 0;
  position: relative;
  top: 85px;
  font-size: 12px;
} */

.EyeContour-image-container {
  float: left;
  width: calc(45% - 20px);
  text-align: center;
  overflow: hidden;
  margin: 0;
}

.EyeContour-image-container.EyeContour-image1 {
  width: calc(55% - 20px);
}

.EyeContour-image {
  width: 100%;
  object-fit: cover;
}

.EyeContour-image2 {
  margin-top: 100px;
}

.EyeContour-product-image {
  display: inline-block;
  width: auto;
  height: 145px;
  max-height: 100%;
  max-width: none;
}

.EyeContour-image2 figcaption strong {
  line-height: 1;
  color: #dbb68c;
}
.EyeContour-image2 figcaption span {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 15px;
  text-align: left;
  padding-left: 8px;
}
.EyeContour-image2 figcaption p {
  font-weight: 500;
  font-size: 15px;
}

.EyeContour-slide-header {
  color: #dbb68c;
  padding-top: 24px;
  text-align: center;
}
.EyeContour-slide-header p {
  font-weight: 400;
  margin-top: 5px;
  font-size: 14px;
}

.EyeContour-slide2-inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  height: 425px;
  margin-top: 40px;
}

.EyeContour-slide2-inner figure {
  height: auto;
  margin: 0;
  overflow: hidden;
  position: relative;
  float: left;
}
.EyeContour-slide2-inner figure img {
  max-width: none !important;
  width: 170%;
  left: -78px;
  top: -32px;
  position: relative;
  z-index: 9;
}

.EyeContour-slide2-inner figcaption {
  background-color: #fff;
  padding-top: 25px;
  padding-left: 35px;
  text-align: left;
  position: relative;
  z-index: 10;
  height: 200px;
  transform: translateY(-165px);
  font-weight: 300;
}

.EyeContour-slide2-inner figcaption strong {
  position: absolute;
  top: 10px;
  left: 2px;
  line-height: 1;
  font-size: 25px;
  color: #dbb68c;
}
.EyeContour-slide2-inner figcaption strong::after {
  content: " /";
  position: relative;
  top: 7.5px;
  font-size: 0.8em;
}

.EyeContour-slide2-inner figcaption span {
  font-size: 14px;
  font-weight: 500;
}

.EyeContour-slide2-mobile-inner figure {
  margin: 0 12px !important;
}

.EyeContour-slide2-mobile-inner figcaption {
  display: grid;
  grid-template-columns: 1fr 9fr;
  margin: 0;
  padding: 33px 28px 0;
}

.EyeContour-slide2-mobile-inner figcaption strong {
  color: #dbb68c;
  line-height: 1;
  font-size: 25px;
  margin-right: 10px;
  white-space: nowrap;
}
.EyeContour-slide2-mobile-inner figcaption span {
  font-size: 13px;
  font-weight: 700;
  padding-top: 8px;
}

.EyeContour-slide2-mobile-inner .sliderArrows {
  color: white !important;
}
.EyeContour-slide2-mobile-inner figcaption strong::after {
  content: " /";
  position: relative;
  top: 7.5px;
  font-size: 0.8em;
}

#lift .slide2-figure-image-two {
  width: 112% !important;
  left: -16px !important;
  top: 0px !important;
}

#lift .slide2-figcaption-two {
  transform: translateY(-10px) !important;
}

#lift .slide2-figure-image-three {
  width: 152% !important;
  left: -61px !important;
  top: -24px !important;
}

#lift .slide2-figcaption-three {
  transform: translateY(-116px) !important;
}

#lift .slide2-inner-section {
  width: 50% !important;
  height: auto;
}

.EyeContour-slide2-subtitle {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400 !important;
  margin-top: 5px;
  padding: 0 30px !important;
}

.EyeContour-slide2-subtitle strong {
  margin: 3px 0;
}

#lift .slide2-section2 {
  text-align: left;
  padding: 90px 0 0 50px;
  font-size: 15px;
  font-weight: 100;
}

#lift .slide2-section2 strong {
  color: #dbb68c;
}
#lift .slide2-section2 h4 {
  margin: 0;
  color: #dbb68c;
  text-align: center;
}

#lift .slide3-inner-section {
  width: 100%;
  position: relative;
  height: auto;
  padding-left: 60px;
}

#lift .slide3-image-container {
  width: 250px;
}
#lift .slide3-inner-section figcaption {
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  padding-left: 40%;
  width: 76%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#lift .discover-descripton li {
  color: black;
  margin: 5px 0;
  font-weight: 200;
}
#lift .circle {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: none;
  border-radius: 50%;
  margin: 5px;
  background: #fff;
  border: 1px solid #dbb68c;
}

#lift .circle.current {
  background: #dbb68c;
}

.mobile-header-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EyeContour-slide2-mobile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 12px;
}

.EyeContour-slide2-mobile-header h3 {
  font-size: 28px;
  margin: 0;
  padding: 14px 30px;
  line-height: 1;
  color: #dbb68c;
}
.EyeContour-slide2-mobile-header p {
  font-size: 12px;
  color: #dbb68c;
  font-weight: 100;
  padding: 0 15px;
}

#lift .buttonContainer-slide2-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .EyeContour-slide-header {
    text-align: center;
  }
  #lift .EyeContour .carouselWrapper {
    width: 100%;
    top: 0 !important;
    margin-top: 8px;
    margin-bottom: 36px;
  }
  #lift .carouselWrapper {
    height: 590px;
  }
  .EyeContour-slide {
    width: 100% !important;
    padding-bottom: 22px;
  }
  .EyeContour-header h2 {
    font-size: 35px;
  }
  .EyeContour-header p {
    font-size: 15px;
  }
  .EyeContour-header-inner {
    padding: 10px 0;
  }
  .EyeContour-slide-header {
    height: auto !important;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding-top: 0 !important;
    padding-bottom: 0;
  }

  .EyeContour-slide-header img {
    max-height: 60px;
    width: auto;
    margin-left: 33px;
  }
  .EyeContour-slide-header h3 {
    font-size: 28px;
    margin: -2px 0 0;
    padding: 14px 30px;
    line-height: 1;
  }
  .EyeContour-slide1-inner {
    flex-direction: column;
    margin: 0;
  }
  .EyeContour-slide1-image-container {
    margin: 0%;
    padding: 0;
  }
  .EyeContour-image-container {
    margin: 0 10px;
    width: unset;
    margin-bottom: 10px !important;
  }
  .EyeContour-image2 {
    margin-top: 0;
  }
  .EyeContour-image2 figcaption p {
    font-size: 14px;
    margin-top: 18px;
  }

  .EyeContour-slide2-mobile-inner .slider-control-bottomcenter {
    position: relative;
    bottom: -50px !important;
    display: flex;
    justify-content: center;
  }
  #lift .buttonContainer-slide1 {
    margin-top: 0;
  }
  #lift .footnote {
    font-size: 11px;
    padding: 15px 0 15px 0;
    text-align: center;
  }
  .Eyecontour-slide1-inner {
    text-align: center;
  }
}

@media screen and (max-width: 980px) {
  #lift .carouselWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .clinicallyProven-image-container {
    right: 3vw;
  }
}

@media screen and (max-width: 1100px) {
  #EyeContour.EyeContour .slider-control-centerright {
    right: -45px !important;
  }
  #EyeContour.EyeContour .slider-control-centerleft {
    left: -45px !important;
  }
}

@media screen and (max-width: 1000px) {
  .clinicallyProven-image-container {
    right: 1vw;
  }
  .sliderArrows {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sliderArrows {
    display: block;
  }
  #lift .carouselWrapper {
    width: 100%;
  }
  #EyeContour {
    padding-bottom: 0;
  }
  #EyeContour.EyeContour .slider-control-centerright {
    right: 15px !important;
  }
  #EyeContour.EyeContour .slider-control-centerleft {
    left: 15px !important;
  }
  #EyeContour.EyeContour .slider-control-centerright .rightArrow {
    width: 18px !important;
  }
  #EyeContour.EyeContour .slider-control-centerleft .leftArrow {
    width: 18px !important;
  }
  #EyeContour.EyeContour .slider-control-centerleft {
    top: 41% !important;
    transform: translateY(-41%) !important;
  }
  #EyeContour.EyeContour .slider-control-centerright {
    top: 41% !important;
    transform: translateY(-41%) !important;
  }
}
