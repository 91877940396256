.radiance3 .s1-main-img-desktop {max-width: 440px;}
.radiance3 .radiance-slide1-list li {margin-bottom: 32px;}
.radiance3 .radiance-slide1 li:nth-child(1) svg {width: 42px;height: 34px;margin-right: 15px;}
.radiance3 .radiance-slide1 li:nth-child(2) svg {width: 41px;margin-right: 16px;}
.radiance3 .radiance-slide1 li:nth-child(3) svg {margin-right: 10px;width: 47px;height: 38px;}


.radiance3 .radiance-slide2 {width: 690px;}

.radiance3 .radiance-slide4-content-left img {width: auto; height: 273px;}

#radiance.radiance4 {
    padding-bottom: 40px;
}



@media screen and (max-width: 768px) {
    .radiance3 .radiance-slide1-list {padding: 5px 25px 10px;}
    .radiance3 .radiance-slide1 li:nth-child(1) svg {width: 28px;}
    .radiance3 .radiance-slide1 li:nth-child(2) svg {width: 27px;}
    .radiance3 .radiance-slide1 li:nth-child(3) svg {margin-right: 12px;width: 30px;}

    .radiance3 .radiance-slide2-inner figcaption {padding: 0 40px;}
    .radiance3 .radiance-non-slide.radiance-slide2 .radiance-slide2-inner .slider-slide>div {width: 100%;}
    .radiance3 .radiance-non-slide.radiance-slide2 .radiance-slide2-inner img {width: 100%;display: block;}
}