.internal-link {
  cursor: pointer;
}

#lift .carouselWrapper {
  position: relative;
  width: 920px;
  margin: -100px auto 0;
}

#lift .slider {
  height: auto;
}

#lift .discover-slide {
  width: 920px;
  max-width: 920px;
  background-color: #fff;
  color: #323e48;
}

#lift .discover-slide-header {
  height: 90px;
  padding-top: 24px;
}

#antiblemish.lift-page-discover {
  position: relative;
  padding-top: 6vw;
}

#lift .clinicallyProven-image-container {
  position: absolute;
  z-index: 1;
  left: unset;
  top: 20px;
  text-align: right;
  margin: 0;
  right: 5vw;
}
#lift .clinicallyProven-image-container img {
  width: 16vw;
  max-width: 200px;
}
#lift .discover-slide h3 {
  font-size: 35px;
  margin: 5px 0 0;
  color: #dbb68c;
  font-family: "PT Serif", serif;
  font-weight: 700;
  line-height: 1;
}

#lift .discover-slide1-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.liftComfort-image-container figcaption {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 900;
}

#lift .buttonContainer {
  display: flex;
  justify-content: center;
}

#lift .footnote {
  font-size: 12px;
  font-family: "Montserrat";
  padding: 55px 0 0 0;
}

.liftComfort-image {
  height: auto;
  width: 280px;
}

.liftComfort-image1 {
  margin-right: 18px;
}

.liftComfort-image2 {
  margin-left: 18px;
}

#lift .discover-slide-header {
  color: #dbb68c;
}
#lift .discover-slide-header p {
  font-weight: 100;
  margin-top: 5px;
  font-size: 15px;
}

#lift .discover-slide2-inner {
  display: flex;
  flex-direction: row;
  margin: 35px 0 50px 0;
}

#lift .slide2-inner-section {
  width: 50%;
  height: auto;
}

.lift-slide2-subtitle {
  text-transform: uppercase;
}

#lift .slide2-section2 {
  text-align: left;
  padding: 90px 0 0 50px;
  font-size: 15px;
  font-weight: 500;
}

#lift .slide2-section2 span {
  padding-left: 3px;
}

#lift .slide2-section2 strong {
  color: #dbb68c;
}
#lift .slide2-section2 h4 {
  margin: 0;
  color: #dbb68c;
  text-align: center;
}

#lift .slide3-inner-section {
  width: 100%;
  position: relative;
  height: auto;
  padding-left: 60px;
}

#lift .slide3-image-container {
  width: 250px;
}
#lift .slide3-inner-section figcaption {
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  padding-left: 42%;
  width: 76%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#lift .slide3-inner-section figcaption ul {
  height: 60px;
}

#lift .slide3-inner-section figcaption .more-button {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 38px !important;
  height: 38px !important;
  max-width: 38px !important;
  max-height: 38px !important;
  background-color: #fff;
  color: #dbb68c;
  text-align: center;
  border: 1px solid #dbb68c;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-button::after {
  background-color: #dbb68c;
  transform: rotate(90deg);
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  top: 8px;
  width: 1px;
  height: 20px;
  transform-origin: center;
}

#lift .more-button::before {
  background-color: #dbb68c;
  /* transform: rotate(90deg); */
  content: "";
  position: absolute;
  left: calc(50% - 1px);
  top: 8px;
  width: 1px;
  height: 20px;
  transform-origin: center;
}

#lift .slide3-inner-section figcaption .more-button.true {
  background-color: #dbb68c;
}

#lift .more-button.true::before {
  background-color: white;
}
#lift .more-button.true::after {
  background-color: white;
}

#lift .discover-descripton {
  color: #dbb68c;
  font-size: 15px;
  font-weight: 600;
}

#lift .discover-descripton li:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #dbb68c;
  border-radius: 50%;
  margin-right: 5px;
}

#lift .discover-descripton li {
  color: black;
  margin: 5px 0;
  font-weight: 400;
  font-size: 14px;
}

#lift .disclaimer {
  font-size: 10px;
  margin-top: 0;
  text-align: center;
}
#lift .disclaimer sup {
  color: #dbb68c;
  font-weight: 800;
}

#lift .circle {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: none;
  border-radius: 50%;
  margin: 5px;
  background: #fff;
  border: 1px solid #dbb68c;
}

#lift .circle.current {
  background: #dbb68c;
}
#lift .more-button-1 {
  top: 40px !important;
  left: 30px !important;
}

#lift .more-button-2 {
  top: 100px !important;
  left: 177px !important;
}
#lift .more-button-3 {
  top: 142px !important;
  left: 173px !important;
}
#lift .more-button-4 {
  top: 184px !important;
  left: 171px !important;
}
#lift .more-button-5 {
  top: 226px !important;
  left: 167px !important;
}
#lift .more-button-6 {
  top: 360px !important;
  left: 15px !important;
}
#lift .more-button-7 {
  top: 335px !important;
  left: 310px !important;
}

@media screen and (max-width: 1800px) {
  #antiblemish.lift-page-discover {
    padding-top: 8vw;
  }
}

@media screen and (max-width: 1400px) {
  #antiblemish.lift-page-discover {
    padding-top: 10vw;
  }
}

@media screen and (max-width: 1200px) {
  #lift .clinicallyProven-image-container {
    right: 3vw;
  }
  #lift .clinicallyProven-image-container img {
    width: 13vw;
  }
  #antiblemish.lift-page-discover {
    padding-top: 11vw;
  }
}

@media screen and (max-width: 1100px) {
  #antiblemish.lift-page-discover .slider-control-centerright {
    right: -45px !important;
  }
  #antiblemish.lift-page-discover .slider-control-centerleft {
    left: -45px !important;
  }
}

@media screen and (max-width: 1000px) {
  #lift .clinicallyProven-image-container {
    right: 1vw;
  }
}

@media screen and (max-width: 980px) {
  #lift .carouselWrapper {
    width: 100%;
  }
  #lift .discover-slide {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  #lift .carouselWrapper {
    width: 100%;
  }
  #antiblemish.lift-page-discover {
    padding-top: 3vw;
  }
}

@media screen and (max-width: 600px) {
  #lift .discover-slide {
    width: 100% !important;
  }
  #lift .discover-slide-header {
    height: auto !important;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    padding-top: 5px !important;
  }
  #lift .discover-slide h3 {
    font-size: 28px !important;
    margin: 35px 0 15px;
  }
  #lift .discover-slide-header p {
    font-size: 11px;
    margin-top: -10px;
  }
  .clinicallyProven-image-container-mobile {
    position: relative !important;
    right: 10px !important;
  }
  .clinicallyProven-image-container-mobile img {
    width: 28vw;
    max-width: 145px;
  }
  .liftComfort-image-container {
    margin: 0 !important;
  }
  #lift .discover-slide1-inner {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 10px !important;
    padding: 10px 10px !important;
  }
  #lift .discover-slide1-inner figcaption {
    font-size: 13px !important;
  }
  .buttonContainer {
    margin: 10px 0 20px !important;
  }
  .marginTop100.buttonContainer {
    margin-top: 110px !important;
  }
  #lift .slide2-section2 span {
    font-size: 13px;
    font-weight: 500;
  }

  #lift .discover-slide2-inner {
    flex-direction: column;
    margin: 10px 0 10px 0 !important;
  }

  #lift .slide2-inner-section-mobile {
    width: 100% !important;
    padding: 10px 20px !important;
  }
  .pronounced {
    padding-left: 34px !important;
  }
  #lift .slide3-image-container {
    width: 200px !important;
  }
  #lift .slide3-inner-section {
    display: flex;
    flex-direction: column;
    padding: 30px 0px 0px 70px !important;
    margin: 0 !important;
  }
  #lift .slide3-inner-section figcaption {
    position: relative;
    padding-left: 0;
    width: 100% !important;
  }
  #lift .discover-descripton {
    position: absolute !important;
    bottom: -130px !important;
    width: 100% !important;
    text-align: center !important;
    left: 0 !important;
  }

  #lift .slide3-inner-section figcaption h4 {
    margin-bottom: 10px;
  }

  #lift .slide3-inner-section figcaption ul {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }

  #lift .slide3-inner-section figcaption li {
    text-align: left;
    padding-left: 20px;
    font-size: 13px;
    font-family: "Montserrat", serif;
    font-weight: 100;
    line-height: 1.2em;
  }

  #lift .slide3-button {
    margin: 130px 0 30px !important;
  }
  #lift .more-button-1 {
    top: 47px !important;
    left: 35px !important;
  }
  #lift .more-button-2 {
    top: 100px !important;
    left: 170px !important;
  }
  #lift .more-button-3 {
    top: 140px !important;
    left: 166px !important;
  }
  #lift .more-button-4 {
    top: 180px !important;
    left: 162px !important;
  }
  #lift .more-button-5 {
    top: 220px !important;
    left: 158px !important;
  }
  #lift .more-button-6 {
    top: 330px !important;
    left: 25px !important;
  }
  #lift .more-button-7 {
    top: 315px !important;
    left: 275px !important;
  }
}

@media screen and (min-width: 1600px) {
  #lift .clinicallyProven-image-container {
    right: 13vw;
  }
}

@media screen and (min-width: 1800px) {
  #lift .clinicallyProven-image-container {
    right: 17vw;
  }
}
