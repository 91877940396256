.radiance2 .s1-main-img-desktop {max-width: 450px;}
.radiance2 .radiance-slide1-list li { margin-bottom: 2.6em;display: flex; align-items: center;flex-wrap: wrap; }
.radiance2 .radiance-slide1 li.brush-about-radiance svg {margin-left: -6px;}
.radiance2 .radiance-slide1 li.brush-about-complexion svg {margin-right: 10px;}
.benefits-boosts img {max-width: 50px; margin-left: -5px;margin-right: 13px;}
.benefits-wellbeing img {max-width: 47px;margin-left: -3px;margin-right: 15px;}

.radiance2 .radiance-slide2 {width: 690px;}
.radiance2 .radiance-slide4-content-left img {max-width: 360px;}

@media screen and (max-width: 768px) {
    .radiance2 .radiance-slide1-list li {margin-bottom: 2em;}
    .radiance2 .radiance-slide1-list li:first-child {margin-bottom: 1.65em;}
    .radiance2 .radiance-slide1 li.brush-about-radiance svg {margin-right: 17px; max-width: 36px;}
    .radiance2 .radiance-slide1 li.brush-about-complexion svg {height: 36px;}
    .benefits-boosts img {max-width: 40px;}
    .benefits-wellbeing img {max-width: 36px;}

    .radiance2 .radiance-slide2-inner figcaption {padding: 0 40px;}
    .radiance2 .radiance-non-slide.radiance-slide2 .radiance-slide2-inner .slider-slide>div {width: 100%;}
    .radiance2 .radiance-non-slide.radiance-slide2 .radiance-slide2-inner img {width: 100%;display: block;}
}